import { AbstractControl } from '@angular/forms';

export class ValidatePassword {
  static MatchPassword(abstractControl: AbstractControl) {
    const password = abstractControl.get('userPwd').value;
    const confirmPassword = abstractControl.get('userConfPwd').value;
    if (password !== confirmPassword) {
      abstractControl.get('userConfPwd').setErrors({
        MatchPassword: true,
      });
    } else {
      return null;
    }
  }
}
