import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { NotificationsService } from 'src/app/services/notifications.service';

import { Currency } from 'src/app/models/Currency.model';
import { CustomUser } from 'src/app/models/CustomUser.model';
import { Notification } from 'src/app/models/Notification.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-mailvalidate',
  templateUrl: './mail-validate.component.html',
  styleUrls: ['./mail-validate.component.scss'],
})
export class MailValidateComponent implements OnInit, OnDestroy {
  isLoading = false;
  loadingDeposit = false;

  currentUserID: string;
  currentUserName: string;
  userData: CustomUser;
  userDataSubscription: any;
  emailValidated: boolean;

  assets: Currency[];
  balances: any[];
  quotes: any;
  address: string;
  history: any[];

  sendingEmail = false;

  // Notifications Area
  showNotifications = true;
  notification: Notification;

  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private snackBar: MatSnackBar,
    private notificationsService: NotificationsService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.checkEmailConfStatus();
    if (this.emailValidated) {
      this.router.navigate(['/main/dashboard']);
    }
    this.isLoading = true;

    this.currentUserName = this.authService.getUserDisplayName();
    this.currentUserID = this.authService.getCurrentUserID();

    this.userDataSubscription = this.authService
      .getUserFromDbAsObs(this.currentUserID)
      .subscribe(async (userdata) => {
        if (userdata && userdata.kyc) {
          this.userData = userdata;
        }
        console.log(`kyc status: ${this.userData.kyc.status}`);
      });
  }

  ngOnDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }

  checkEmailConfStatus() {
    this.emailValidated = !!this.authService.getUserEmailConfStatus();
  }

  reloadPage() {
    window.location.reload();
  }

  sendEmailVerification() {
    this.sendingEmail = true;
    this.authService.sendEmailVerification().then(
      () => {
        this.sendingEmail = false;
        this.snackBar.open('E-mail successfully sent!', null, {
          duration: 2000,
        });
      },
      (reject) => {
        this.sendingEmail = false;
        this.snackBar.open(
          'Too many attempt, try again later',
          null,
          {
            duration: 2000,
          },
        );
      },
    );
  }
}
