export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyCm2ztBc_OUAJohoirpOWdOOlN5sSxJGt8',
    authDomain: 'app.soya-coin.com',
    databaseURL: 'https://soya-prod.firebaseio.com',
    projectId: 'soya-prod',
    storageBucket: 'soya-prod.appspot.com',
    messagingSenderId: '398281474992',
    appId: '1:398281474992:web:e9d3c819918008d2ca8c75',
    measurementId: 'G-RN0W4X0T5L',
  },
  baseURL: 'https://us-central1-soya-prod.cloudfunctions.net/default',
  platformURL: 'https://app.soya-coin.com/',
  gatewayURL: 'https://us-central1-soya-prod.cloudfunctions.net',
  network: 'mainnet',
  sentry_dsn:
    'https://b8003da3dc6c470d9b2b37d3abd29f78@o457942.ingest.sentry.io/5613493',
  blockExplorerLink: {
    ETH: 'https://etherscan.io/tx',
    BTC: 'https://www.blockchain.com/btc/tx',
    SOY: 'https://etherscan.io/tx',
  },
  blockExplorerAddressLink: {
    ETH: 'https://etherscan.io/address',
    BTC: 'https://www.blockchain.com/btc/address',
    SOY: 'https://etherscan.io/address',
  },
  recaptchaKey: "6LdOXfIUAAAAAMo7KlqGrbaQdKx2mc4YlvJ6y7-u",
};
