<div class="card money">
  <div class="spinner" *ngIf="isLoading">
    <mat-spinner
      mode="indeterminate"
      class="spinner__symbol"
      color="primary"
    ></mat-spinner>
  </div>
  <div
    [style.visibiliy]="isLoading ? 'hidden' : 'visible'"
    class="wallet-content"
  >
    <div class="wallet-header">
      <div class="left">
        <div class="wallet-header__icon">
          <svg-icon
            src="assets/img/wallet.svg"
            [svgStyle]="{ 'width.px': 40, 'height.px': 40 }"
          >
          </svg-icon>
        </div>
        <h2 class="wallet-header__title">{{ 'overview.title' | translate }}</h2>
      </div>
      <!-- <button
        class="wallet-header__btn"
        mat-icon-button
        aria-label="refresh button"
        (click)="refreshWallet()"
      >
        <mat-icon>refresh</mat-icon>
      </button> -->
    </div>
    <p class="total-soy">
      {{ floor10(soyBalance?.free, -6) }}
      <span class="notranslate">SOYA</span>
    </p>
    <div class="wallet-buttons" *ngIf="userRole !== 'MASTER_MANAGER'">
      <button
        mat-raised-button
        color="accent"
        class="button mr-sm"
        (click)="openDialog('buy')"
      >
      {{ 'overview.button1' | translate }}
      </button>
      <button
        mat-raised-button
        color="accent"
        class="button ml-sm"
        (click)="openDialog('sell')"
      >
      {{ 'overview.button2' | translate }}
      </button>
    </div>

    <div class="money-table">
      <div class="money-table__row credits">
        <p
          class="money-table__label money-table__cell--left"
          [style.color]="'#fff'"
        >
        {{ 'overview.subtitle' | translate }}
        </p>
      </div>
      <div
        class="money-table__row items"
        *ngFor="let item of balances"
      >
        <div class="money-table__cell" class="notranslate">
          <p
            class="money-table__label money-table__cell--left"
            class="notranslate"
          >
            {{ getCurrency(item.id).name }}
          </p>
          <p class="money-table__conv">
            =
            {{
              getCoinExchgRate(item.id, 'USD')
                | currency: 'CAD':'':'1.2-2'
            }}
            USD
          </p>
        </div>
        <div class="money-table__cell money-table__cell--right">
          <div class="money-table__value">
            <p class="money-table__value--number" class="notranslate">
              <!-- {{item.coin}} -->
              <!-- {{((item.free * 10000000) / 1000000).toFixed(6)}} -->
              {{ floor10(item.free, -6) }}
              {{ getCurrency(item.id).symbol }}
            </p>
            <!-- <p
              class="money-table__value--unit"
            >
              &nbsp; {{ getCurrency(item.id).symbol }}
            </p> -->
          </div>
          <p class="money-table__conv">
            =
            {{
              item.free * getCoinExchgRate(item.id, 'USD')
                | currency: 'CAD':'':'1.2-2'
            }}
            USD
          </p>
        </div>
      </div>
    </div>
    <div [ngClass]="{
      wallet_actions: true,
      'wallet_actions--4btns': userRole === 'MASTER_MANAGER' ? false : true,
      'wallet_actions--2btns': userRole === 'MASTER_MANAGER' ? true : false
    }" >
      <div [ngClass]="{
        'action': true,
        'mr-md': userRole === 'MASTER_MANAGER' ? true : false
      }" (click)="openDialog('deposit')">
        <svg-icon
          class="action-icon"
          src="assets/img/dollar-down.svg"
          [svgStyle]="{
            'width.px': 40,
            'height.px': 40,
            'margin-left.px': 7
          }"
        ></svg-icon>
        <p class="action-label">{{ 'overview.button3' | translate }}</p>
      </div>
      <div [ngClass]="{
        'action': true,
        'ml-md': userRole === 'MASTER_MANAGER' ? true : false
      }" (click)="openDialog('withdraw')">
        <svg-icon
          class="action-icon"
          src="assets/img/dollar-up.svg"
          [svgStyle]="{
            'width.px': 40,
            'height.px': 40,
            'margin-left.px': 7
          }"
        >
        </svg-icon>
        <p class="action-label">{{ 'overview.button4' | translate }}</p>
      </div>
      <div class="action" (click)="openDialog('pay')" *ngIf="userRole !== 'MASTER_MANAGER'">
        <svg-icon
          class="action-icon"
          src="assets/img/coin-pay.svg"
          [svgStyle]="{ 'width.px': 40, 'height.px': 40 }"
        ></svg-icon>
        <p class="action-label">{{ 'overview.button5' | translate }}</p>
      </div>
      <div class="action" (click)="openDialog('receive')" *ngIf="userRole !== 'MASTER_MANAGER'">
        <svg-icon
          class="action-icon"
          src="assets/img/receive.svg"
          [svgStyle]="{ 'width.px': 40, 'height.px': 40 }"
        ></svg-icon>
        <p class="action-label">{{ 'overview.button6' | translate }}</p>
      </div>
    </div>
  </div>
</div>
