import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AngularFireAuth } from '@angular/fire/auth';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  public isAuthenticated: boolean;

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
  ) {
    this.isAuthenticated = false;
  }

  canActivate(): Observable<boolean> {
    return this.afAuth.authState.pipe(
      map((auth) => {
        if (!auth) {
          this.router.navigate(['/auth/login']);
          return false;
        } else {
          return true;
        }
      }),
    );
  }
}
