<div class="deposit">
  <div class="deposit__coinSelector">
    <p class="deposit__title">{{ 'two_factor.registerModalTitle' | translate }}</p>
  </div>

  <div class="deposit__main--info">
    <h2>{{'two_factor.description_title' | translate}}</h2>
    <p class="deposit__main--text notranslate">
      {{ 'two_factor.description' | translate }}
    </p>
  </div>

  <div class="deposit__error" *ngIf="failedToGenTOTP">
    <p>{{ 'two_factor.invalidCode' | translate }}</p>
  </div>

  <div class="deposit__main">
    <div class="deposit__visual">
      <div class="qrcode-container">
        <mat-spinner [diameter]="40" *ngIf="!totpSecret"></mat-spinner>
        <img [src]="'https://chart.googleapis.com/chart?cht=qr&chs=150x150&chl=' + totpURL" alt="qrcode"
          class="qrcode" *ngIf="totpSecret" />
      </div>
      <div class="deposit__address--area">
        <p class="deposit__address--number notranslate">
        </p>
        <div class="deposit__address--icons">
          {{ totpSecret }}
          <ion-icon (click)="copyToService()" name="copy-outline" class="deposit__address--icon">
          </ion-icon>
        </div>
      </div>

    </div>

  </div>

  <form [formGroup]="twoFAFormGroup" (submit)="submitCode()">
    <div class="code section">
      <mat-form-field class="code__formfield" color="primary" appearance="fill">
        <input #codeInput name="code" matInput placeholder="Type here" type="number" [formControl]="code" required />
      </mat-form-field>
    </div>
    <div class="section">
      <button type="submit" class="btn btn-primary" mat-flat-button color="accent">Submit</button>
    </div>
  </form>

</div>