import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, ErrorHandler } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';

import { SoyCurrencyPipe } from './custom/currency_types/soyCurrencyPipe';

// Bug tracking
import * as Sentry from '@sentry/browser';
import { RewriteFrames } from '@sentry/integrations';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';

import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// External solutions
import { ClipboardModule } from 'ngx-clipboard';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { AuthGuardService } from './guards/auth-guard.service';
import { DashboardComponent } from './pages/main-page/dashboard/dashboard.component';
import { MailValidateComponent } from './pages/main-page/mail-validate/mail-validate.component';
import { PhoneValidateComponent } from './pages/main-page/phone-validate/phone-validate.component';
import { Enroll2FAComponent } from './pages/main-page/enroll2FA/enroll2fa.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { LoginComponent } from './pages/auth-page/login/login.component';
import { ForgotPasswordComponent } from './pages/auth-page/forgot-password/forgot-password.component';
import { AuthPageComponent } from './pages/auth-page/auth-page.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FileSizePipe } from './shared/file-size.pipe';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { ProfileComponent } from './pages/main-page/profile/profile.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { ModalComponent } from './components/modal/modal.component';
import { SignupComponent } from './pages/auth-page/signup/signup.component';
import { KycComponent } from './components/kyc/kyc.component';
import { WithdrawComponent } from './components/withdraw/withdraw.component';
import { DepositComponent } from './components/deposit/deposit.component';
import { BuyComponent } from './components/buy/buy.component';
import { HistoryComponent } from './components/history/history.component';
import { PayComponent } from './components/pay/pay.component';
import { ReceiveComponent } from './components/receive/receive.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { OverviewComponent } from './components/overview/overview.component';
import { NotificationComponent } from './components/notification/notification.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { CampaignComponent } from './components/campaign/campaign.component';
import { QrScannerComponent } from './pages/qr-scanner/qr-scanner.component';

import { DisableControlDirective } from './shared/disable-control.directive';
import { NgxCurrencyModule } from 'ngx-currency';
import { SellComponent } from './components/sell/sell.component';
import { CoinConverterComponent } from 'src/app/components/coin-converter/coin-converter.component';
import { InvestmentsComponent } from 'src/app/components/investments/investments.component';
import { Link2FAComponent } from 'src/app/components/two-factor/two-factor.component';
import { TwoFactorFormComponent } from 'src/app/pages/auth-page/login/two-factor-form/two-factor-form.component';
import { ButtonLoadingSpinnerComponent } from 'src/app/components/button-loading-spinner/button-loading-spinner.component';

// import ngx-translate and the http loader
import {
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LangSwitchComponent } from './components/lang-switch/lang-switch.component';
import { ChatComponent } from './components/chat/chat.component';
import { NotificationsComponent } from './components/notifications/notifications.component';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


registerLocaleData(localeFr, 'fr-FR');
Sentry.init({
  dsn: environment.sentry_dsn,
  integrations: [new RewriteFrames()],
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    console.log(error);
    Sentry.captureException(error.originalError || error);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    PhoneValidateComponent,
    Enroll2FAComponent,
    MailValidateComponent,
    NotfoundComponent,
    ForgotPasswordComponent,
    AuthPageComponent,
    FileUploadComponent,
    FileSizePipe,
    MainPageComponent,
    ProfileComponent,
    OverlayComponent,
    ModalComponent,
    SignupComponent,
    KycComponent,
    WithdrawComponent,
    DepositComponent,
    BuyComponent,
    HistoryComponent,
    PayComponent,
    ReceiveComponent,
    OverviewComponent,
    NotificationComponent,
    TutorialComponent,
    CampaignComponent,
    SoyCurrencyPipe,
    DisableControlDirective,
    SellComponent,
    LangSwitchComponent,
    CoinConverterComponent,
    InvestmentsComponent,
    Link2FAComponent,
    TwoFactorFormComponent,
    ChatComponent,
    NotificationsComponent,
    QrScannerComponent,
    ButtonLoadingSpinnerComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    ClipboardModule,
    ZXingScannerModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatStepperModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatDividerModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    AngularSvgIconModule.forRoot(),
    NgxCurrencyModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  entryComponents: [ModalComponent],
  providers: [
    StatusBar,
    SplashScreen,
    Camera,
    File,
    QRScanner,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthGuardService,
    MatDatepickerModule,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    FingerprintAIO,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
