<!-- User Status
//   | 'PENDING'
//   | 'ANALYZING'
//   | 'REJECTED'
//   | 'APPROVED'; -->

<div class="kyc-container">
  <div class="form-container">
    <h3 class="heading-secondary form-container__title">
      {{ 'kyc.mainTitle' | translate | uppercase }}
    </h3>

    <!-- Message Bar : PENDING-->
    <div class="noediting mt-lg">
      <div *ngIf="userData.kyc.status === 'PENDING'">
        <h5>{{ 'kyc.msgPendingTitle' | translate }}</h5>
        <p>
          {{ 'kyc.msgPendingDescr' | translate }}
        </p>
      </div>
      <div *ngIf="userData.kyc.status === 'ANALYZING'">
        <h5>{{ 'kyc.msgAnalyzingTitle' | translate }}</h5>
        <p>
          {{ 'kyc.msgAnalyzingDescr' | translate }}
        </p>
      </div>
      <div *ngIf="userData.kyc.status === 'APPROVED'">
        <h5>{{ 'kyc.msgApprovedTitle' | translate }}</h5>
        <p>
          {{ 'kyc.msgApprovedDescr' | translate }}
        </p>
      </div>
      <div *ngIf="userData.kyc.status === 'REJECTED'">
        <h5>{{ 'kyc.msgRejectedTitle' | translate }}</h5>
        <p>
          {{ 'kyc.msgRejectedDescr' | translate }}
        </p>
      </div>
    </div>

    <!-- FORM -->
    <mat-horizontal-stepper
      *ngIf="tempUserData"
      class="stepper-container mt-lg"
      [labelPosition]="labelPos"
    >
      <mat-step
        class="step"
        [label]="getStepLabel(1)"
        state="personal"
      >
        <form
          [formGroup]="formGroup1"
          class="step__form"
          [style.fontSize.px]="16"
        >
          <!-- FULL NAME -->
          <mat-form-field class="step__form-field" color="primary">
            <mat-label>{{ 'kyc.nameFormLabel' | translate }}</mat-label>
            <input
              class="step__form-input"
              matInput
              [formControl]="full_name"
              [disableControl]="editingNotAllowed"
              type="text"
              required
            />
            <mat-error *ngIf="full_name.invalid">
              {{ getNameErrorMsg() }}
            </mat-error>
          </mat-form-field>

          <!-- E-MAIL -->
          <mat-form-field
            [ngClass]="{
              'step__form-field': true,
              'step__form-field--75pct':
                labelPos === 'bottom' ? false : true
            }"
            color="primary"
          >
            <mat-label>{{ 'kyc.emailFormLabel' | translate }}</mat-label>
            <input
              class="step__form-input"
              matInput
              [formControl]="email"
              [disableControl]="editingNotAllowed"
              type="email"
              required
              readonly
            />
            <mat-error *ngIf="email.invalid">
              {{ getEmailErrorMsg() }}
            </mat-error>
          </mat-form-field>

          <div class="upload-area">
            <p class="upload-area__title">{{ 'kyc.photoUpload1Title' | translate }}</p>
            <img
              class="user-photo"
              [src]="
                tempUserData &&
                tempUserData.kyc.personal_info.avatar_url
                  ? tempUserData.kyc.personal_info.avatar_url
                  : getPlaceholder('avatar')
              "
              alt="user photo"
            />
            <app-file-upload
              *ngIf="!editingNotAllowed"
              [path]="currentPath"
              [refName]="'photo'"
              (uploadInProgress)="uploadIsInProgress($event)"
              (uploadComplete)="uploadIsComplete($event)"
            ></app-file-upload>
          </div>

          <!-- DATE OF BIRTH -->
          <mat-form-field
            class="step__form-field step__form-field--50pct"
            [ngClass]="{
              'step__form-field': true,
              'step__form-field--75pct':
                labelPos === 'bottom' ? false : true
            }"
          >
            <mat-label>{{ 'kyc.birthFormLabel' | translate }}</mat-label>
            <input
              class="step__form-input"
              matInput
              [formControl]="dob"
              [disableControl]="editingNotAllowed"
              [matDatepicker]="picker"
              required
            />
            <mat-error *ngIf="dob.invalid">
              {{ getRequiredMsg1('dob') }}
            </mat-error>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <!-- PHONE -->
          <mat-form-field
            [ngClass]="{
              'step__form-field': true,
              'step__form-field--75pct':
                labelPos === 'bottom' ? false : true
            }"
          >
            <mat-label>{{ 'kyc.phoneFormLabel' | translate }}</mat-label>
            <input
              class="step__form-input"
              matInput
              [formControl]="phone"
              [disableControl]="editingNotAllowed"
              required
              readonly
            />
            <mat-error *ngIf="phone.invalid">
              {{ getPhoneErrorMsg() }}
            </mat-error>
          </mat-form-field>

          <!-- DOCUMENT ID -->
          <mat-form-field
            [ngClass]="{
              'step__form-field': true,
              'step__form-field--75pct':
                labelPos === 'bottom' ? false : true
            }"
            color="primary"
          >
            <mat-label>{{ 'kyc.docIDFormLabel' | translate }}</mat-label>
            <input
              class="step__form-input"
              matInput
              [formControl]="doc_id"
              [disableControl]="editingNotAllowed"
              type="text"
              required
            />
            <mat-error *ngIf="doc_id.invalid">
              {{ getRequiredMsg1('doc_id') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form">
            <mat-label>{{ 'kyc.docTypeFormLabel' | translate }}</mat-label>
            <mat-select
              [formControl]="doc_type"
              [disableControl]="editingNotAllowed"
              required
            >
              <mat-option value="ID">Identity</mat-option>
              <mat-option value="PASSPORT">Passport</mat-option>
              <mat-option value="DRIVER_LICENSE"
                >Driver´s License</mat-option
              >
            </mat-select>
          </mat-form-field>

          <!-- ID DOCUMENT (frontside/main) -->
          <div class="upload-area">
            <p class="upload-area__title">
              {{ 'kyc.poi1Upload1Title' | translate }}
            </p>
            <img
              class="user-photo"
              [src]="
                tempUserData &&
                tempUserData.kyc.personal_info.doc_proof
                  ? tempUserData.kyc.personal_info.doc_proof
                  : getPlaceholder('generic')
              "
              alt="user photo"
            />
            <app-file-upload
              *ngIf="!editingNotAllowed"
              [path]="currentPath"
              [refName]="'poi'"
              (uploadInProgress)="uploadIsInProgress($event)"
              (uploadComplete)="uploadIsComplete($event)"
            ></app-file-upload>
          </div>

          <!-- ID DOCUMENT (backside/options) -->
          <div class="upload-area">
            <p class="upload-area__title">
              {{ 'kyc.poi2Upload1Title' | translate }}
            </p>
            <img
              class="user-photo"
              [src]="
                tempUserData.kyc.personal_info.doc_back_proof
                  ? tempUserData.kyc.personal_info.doc_back_proof
                  : getPlaceholder('generic')
              "
              alt="user photo"
            />
            <app-file-upload
              *ngIf="!editingNotAllowed"
              [path]="currentPath"
              [refName]="'poi2'"
              (uploadInProgress)="uploadIsInProgress($event)"
              (uploadComplete)="uploadIsComplete($event)"
            ></app-file-upload>
          </div>

        </form>
        <div class="step__buttons">
          <button
            mat-flat-button
            matStepperNext
            color="primary"
            [disabled]="
              !full_name.valid ||
              !dob.valid ||
              !doc_id.valid ||
              !doc_type.valid ||
              !email.valid ||
              !phone.valid ||
              !tempUserData.kyc.personal_info.avatar_url ||
              !tempUserData.kyc.personal_info.doc_proof ||
              isUploading
            "
          >
          {{ 'kyc.button1' | translate }}
          </button>
        </div>
      </mat-step>
      <mat-step class="step" [label]="getStepLabel(2)" state="home">
        <form
          [formGroup]="formGroup2"
          class="step__form"
          [style.fontSize.px]="16"
        >
          <!--ADDRESS -->
          <mat-form-field class="step__form-field">
            <mat-label>{{ 'kyc.addrLine1FormLabel' | translate }}</mat-label>
            <input
              class="step__form-input"
              matInput
              [formControl]="line1"
              [disableControl]="editingNotAllowed"
              required
            />
            <mat-error *ngIf="line1.invalid">
              {{ getRequiredMsg2('line1') }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="step__form-field">
            <mat-label>{{ 'kyc.addrLine2FormLabel' | translate }}</mat-label>
            <input
              class="step__form-input"
              matInput
              [formControl]="line2"
              [disableControl]="editingNotAllowed"
            />
          </mat-form-field>

          <mat-form-field class="step__form-field">
            <mat-label>{{ 'kyc.cityFormLabel' | translate }}</mat-label>
            <input
              class="step__form-input"
              matInput
              [formControl]="city"
              [disableControl]="editingNotAllowed"
              required
            />
            <mat-error *ngIf="city.invalid">
              {{ getRequiredMsg2('city') }}
            </mat-error>
          </mat-form-field>

          <mat-form-field
            [ngClass]="{
              'step__form-field': true,
              'step__form-field--75pct':
                labelPos === 'bottom' ? false : true
            }"
          >
            <mat-label>{{ 'kyc.stateFormLabel' | translate }}</mat-label>
            <input
              class="step__form-input"
              matInput
              [formControl]="state"
              [disableControl]="editingNotAllowed"
              required
            />
            <mat-error *ngIf="state.invalid">
              {{ getRequiredMsg2('state') }}
            </mat-error>
          </mat-form-field>

          <!-- COUNTRY, from country list -->
          <mat-form-field
            [ngClass]="{
              'step__form-field': true,
              'step__form-field--100pct':
                labelPos === 'bottom' ? false : true
            }"
          >
            <mat-select
              placeholder="{{ 'kyc.countryFormLabel' | translate }}"
              [formControl]="country"
              [disableControl]="editingNotAllowed"
              required
            >
              <mat-option
                *ngFor="let countryName of countryNames"
                [value]="countryName"
              >
                {{ countryName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            [ngClass]="{
              'step__form-field': true,
              'step__form-field--50pct':
                labelPos === 'bottom' ? false : true
            }"
          >
            <mat-label>{{ 'kyc.zipFormLabel' | translate }}</mat-label>
            <input
              class="step__form-input"
              matInput
              placeholder="Postal Code"
              [formControl]="zipcode"
              [disableControl]="editingNotAllowed"
              required
              maxlength="9"
            />
            <mat-error *ngIf="zipcode.invalid">
              {{ getRequiredMsg2('zipcode') }}
            </mat-error>
          </mat-form-field>

          <div class="upload-area">
            <p class="upload-area__title">
              {{ 'kyc.porUpload1Title' | translate }}
            </p>
            <img
              class="user-photo"
              [src]="
                tempUserData && tempUserData.kyc.address.doc_url
                  ? tempUserData.kyc.address.doc_url
                  : getPlaceholder('generic')
              "
              alt="user photo"
            />
            <app-file-upload
              *ngIf="!editingNotAllowed"
              [path]="currentPath"
              [refName]="'por'"
              (uploadInProgress)="uploadIsInProgress($event)"
              (uploadComplete)="uploadIsComplete($event)"
            ></app-file-upload>
          </div>
        </form>
        <div class="step__buttons">
          <button
            mat-stroked-button
            matStepperPrevious
            color="accent"
          >
          {{ 'kyc.button2' | translate }}
          </button>
          <!-- <button mat-stroked-button matStepperNext color="accent">
            Next
          </button> -->
          <button
            mat-raised-button
            color="primary"
            (click)="submit()"
            [disabled]="
              !full_name.valid ||
              !dob.valid ||
              !doc_id.valid ||
              !doc_type.valid ||
              !email.valid ||
              !phone.valid ||
              !line1.valid ||
              !city.valid ||
              !state.valid ||
              !country.valid ||
              !zipcode.valid ||
              !tempUserData.kyc.personal_info.avatar_url ||
              !tempUserData.kyc.personal_info.doc_proof ||
              !tempUserData.kyc.address.doc_url ||
              editingNotAllowed ||
              isUploading
            "
          >
          {{ 'kyc.button3' | translate | uppercase }}
          </button>
        </div>
      </mat-step>

      <!-- Icon overrides. -->
      <ng-template matStepperIcon="personal">
        <mat-icon>phone</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="home">
        <mat-icon>home</mat-icon>
      </ng-template>
    </mat-horizontal-stepper>
  </div>
</div>
