import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  Inject,
} from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { floor10 } from '../../custom/Utils';
import { multiply, sub } from '../../custom/customMath';

import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';

import { DataService } from 'src/app/services/data.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { Currency } from 'src/app/models/Currency.model';
import { AuthService } from 'src/app/services/auth.service';
import { CustomUser } from 'src/app/models/CustomUser.model';
import { WithdrawalService } from 'src/app/services/withdrawal.service';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DialogData } from '@models/DialogData.model';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

declare global {
  interface Window {
    WAValidator: any;
  }
}

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss'],
})
export class WithdrawComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Output() close = new EventEmitter();
  @Input() qrCode?: any;
  currentUserID: string;
  userData: CustomUser;
  userDataSubscription: Subscription;
  isAllowed = false;
  hasLoaded: boolean = false;
  display2FAForm: boolean = false;

  balances: any;
  assets: Currency[];
  quotes: any;
  formGroup1: FormGroup;
  twoFactorFormGroup: FormGroup;

  currentCoinID: string;
  validationCoinID: string;
  addressIsvalid: boolean;
  inputAddress: string;
  convRate: number;
  balance: number;
  amount: number = 0;
  minimum: number;
  stepIncrement = 0.001;
  fixFee: number;
  pctFee: number;
  blockWithdraw: boolean;
  displayTotal: boolean;
  floor10 = floor10;
  multiply = multiply;
  sub = sub;
  nothingTyped: boolean = true;
  isLoading: boolean = false;

  @ViewChild(ZXingScannerComponent, { static: false })
  scanner: ZXingScannerComponent;
  showCam = false;
  isMobile: boolean;

  constructor(
    public dialogRef: MatDialogRef<WithdrawComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
    private dataService: DataService,
    private withdrawalService: WithdrawalService,
    private overlayService: OverlayService,
    private formBuilder: FormBuilder,
    private router: Router,
    private platform: Platform,
  ) {}

  ngOnInit() {
    this.currentUserID = this.authService.getCurrentUserID();
    console.log('QR CODE VALUE', this.qrCode);

    this.userDataSubscription = this.authService
      .getUserFromDbAsObs(this.currentUserID)
      .subscribe((userdata) => {
        this.hasLoaded = true;
        this.userData = userdata;
        this.display2FAForm = this.userData.has_2fa ? true : false;

        if (this.userData.kyc.status === 'APPROVED') {
          this.isAllowed = true;
          this.setWithdrawUIAndData();

          if (this.platform.is('cordova')) {
            this.isMobile = true;
            if (this.qrCode) {
              this.address.setValue(this.qrCode.qrCodeValue);
            }
          }
        } else {
          this.isAllowed = false;
        }
      });
  }

  ngAfterViewInit() {
    // Subscribes in the data.service Subject...
    this.dataService.currentQuotesMsg.subscribe((data) => {
      this.quotes = data;
      this.convRate = this.getCoinExchgRate(
        this.currentCoinID,
        'USD',
      );
      // console.log(`new SOY-USD conv rate: ${this.convRate}`);
    });
  }

  ngOnDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }

  setWithdrawUIAndData() {
    this.assets = this.dataService.getAssets();
    this.quotes = this.dataService.getQuotes();
    this.balances = this.dataService.getBalances();
    this.currentCoinID = 'BTC';
    this.validationCoinID = 'BTC';

    this.setStepIncrement();
    this.updateParameters();

    // console.log(
    //   `ADDRESS TO TEST : SOY/ETH: 0xe3ef23476A2Eb63214BFE25A73f00D710AFC6faB , BTC: 2N7TN6mxHVis5AVhawLXCv5gvAGyywLYKiR`,
    // );

    this.setUpForm();
  }

  setUpForm() {
    this.formGroup1 = this.formBuilder.group({
      address: '',
    });
    this.twoFactorFormGroup = this.formBuilder.group({
      two_factor_code: '',
    });
    this.onChanges();
  }

  get address() {
    return this.formGroup1.get('address');
  }

  get two_factor_code() {
    return this.twoFactorFormGroup.get('two_factor_code');
  }

  onChanges(): void {
    this.formGroup1.valueChanges
      .pipe(debounceTime(200))
      .subscribe((val) => {
        this.inputAddress = val.address;
        this.checkForValidAddress();
      });
  }

  onAmountTyped($event: any) {
    let amountTyped = $event.target.value;
    amountTyped = amountTyped.replace(",", ".")
    this.amount = amountTyped;
    if (
      isNaN(amountTyped) ||
      amountTyped === null ||
      amountTyped === ''
    ) {
      this.nothingTyped = true;
    } else {
      this.nothingTyped = false;
      amountTyped = floor10(parseFloat($event.target.value), -8);
      this.amount = amountTyped;
    }

    if (this.amount > this.balance) {
      this.blockWithdraw = true;
    } else {
      this.blockWithdraw = false;
    }
  }

  updateParameters() {
    this.updateFees().then((res) => {
      this.minimum = this.getCoin(this.currentCoinID).min_withdrawal;
      this.amount = this.minimum;
      this.nothingTyped = false;
      this.blockWithdraw = false;
      this.balance = this.getBalance(this.currentCoinID);

      // USD RATE
      this.convRate = this.getCoinExchgRate(
        this.currentCoinID,
        'USD',
      );

      // Checks if user balance is less than minimum withdraw!
      this.blockWithdraw = this.balance < this.minimum;
    });
  }

  async updateFees() {
    await this.dataService
      .getUserSpecificFees(this.currentCoinID, this.currentUserID)
      .then((userFees) => {
        // VARIABLE FEE
        if (
          userFees &&
          userFees.variable_withdrawal_fee !== undefined
        ) {
          this.pctFee = userFees.variable_withdrawal_fee;
        } else {
          this.pctFee = this.getCoin(
            this.currentCoinID,
          ).variable_withdrawal_fee;
        }

        // FIXED FEE
        if (userFees && userFees.fixed_withdrawal_fee !== undefined) {
          this.fixFee = userFees.fixed_withdrawal_fee;
        } else {
          this.fixFee = this.getCoin(
            this.currentCoinID,
          ).fixed_withdrawal_fee;
        }
        this.displayTotal = true;
      });
  }

  getTotalValue(
    convRate: number,
    amount: number,
    fixFee: number,
    pctFee,
  ) {
    return floor10(
      multiply(convRate, amount - fixFee - multiply(pctFee, amount)),
      -2,
    );
  }

  coinWasSelected(evt: MatSelectChange) {
    // SOYA coin should be validated as it was ETH
    evt.value === 'BTC'
      ? (this.validationCoinID = 'BTC')
      : (this.validationCoinID = 'ETH');
    if (this.inputAddress) {
      this.checkForValidAddress();
    }
    this.setStepIncrement();
    this.updateParameters();
  }

  setStepIncrement() {
    if (this.currentCoinID === 'SOY') {
      this.stepIncrement = 0.01;
    } else if (this.currentCoinID === 'BTC') {
      this.stepIncrement = 0.001;
    } else if (this.currentCoinID === 'ETH') {
      this.stepIncrement = 0.01;
    } else {
      this.stepIncrement = 0.1;
    }
  }

  inputPreset(pct: number) {
    this.amount = +multiply(this.balance, pct);
    this.amount = floor10(this.amount, -8);
    this.nothingTyped = false;
    this.blockWithdraw = false;
  }

  getCoin(coinID: string) {
    const foundCoin = this.assets.find(
      (coin) => coin.symbol === coinID,
    );
    return foundCoin;
  }

  getCoinExchgRate(baseCoin: string, targetCoin: string) {
    if (baseCoin === targetCoin) {
      return 1;
    } else {
      if (this.quotes.length) {
        const quote = this.quotes.find((el) => el.base === baseCoin);
        if (quote && quote.rates) {
          const rate = quote.rates[targetCoin];
          console.log(rate);
          return +floor10(rate, -12);
        }
      }
    }
  }

  getBalance(currencyID: string) {
    const curBalance = this.balances.find((balance) => {
      return balance.id === currencyID;
    });
    // console.log(`getBalance|curBalance:${curBalance.free}`);
    return curBalance.free;
  }

  checkForValidAddress() {
    const network = this.dataService.getNetwork();
    console.log(network);
    // console.log(
    //   `checkForValidAddress| inputAddress:${this.inputAddress},validationCoinID:${this.validationCoinID}`,
    // );
    const valid = window.WAValidator.validate(
      this.inputAddress,
      this.validationCoinID,
      network,
    );
    if (valid) {
      this.addressIsvalid = true;
    } else {
      this.addressIsvalid = false;
    }
  }

  async submit() {
    this.isLoading = true;
    this.setProgressBar('show');
    const two_factor_code = this.two_factor_code.value;
    (
      await this.withdrawalService.saveWithdrawViaAPI(
        this.currentCoinID,
        Number(this.amount),
        this.inputAddress,
        two_factor_code,
      )
    )
      .toPromise()
      .then(
        // success path
        (res) => {
          this.isLoading = false;
          this.setProgressBar('hide');
          this.showSnackBar(
            'Withdrawal request successfully submited',
          );
          this.close.emit();
        },
        (reject) => {
          this.isLoading = false;
          this.setProgressBar('hide');
          for (const e of reject.error.error) {
            this.showSnackBar(e);
          }
        },
      ); // error path);
  }

  // METHODS FOR USER fixFeeDBACK
  setProgressBar(status: string) {
    const action = {
      type: 'setProgressBar',
      msg: status,
    };
    this.overlayService.setAction(action);
  }

  showSnackBar(message: string) {
    const action = {
      type: 'openSnackBar',
      msg: message,
    };
    this.overlayService.setAction(action);
  }

  // WEBCAM stuff / Start =====

  startScan() {
    this.inputAddress = '';
    this.showCam = true;
  }

  scanSuccessHandler(evt: EventEmitter<void>) {
    this.inputAddress = evt.toString();
    this.address.setValue(this.inputAddress);
    this.checkForValidAddress();
    // console.log(this.inputAddress);
    this.scanner.enable = false;
    this.showCam = false;
  }

  scanErrorHandler(evt: EventEmitter<void>) {
    console.log(evt);
  }

  camerasFoundHandler(evt: EventEmitter<MediaDeviceInfo>) {
    this.scanner.tryHarder = true;
    console.log(evt);
  }

  camerasNotFoundHandler(evt: EventEmitter<void>) {
    console.log(evt);
  }

  openQrScanner() {
    this.dialogRef.close();
    this.router.navigate(['qr-scanner/withdraw']);
    setTimeout(() => {
      (<HTMLMetaElement>(
        document.querySelector('meta[name="color-scheme"]')
      )).content = 'light';
    }, 50);
  }
}
