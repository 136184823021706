import { Platform } from '@ionic/angular';
import {
  Component,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import firebase from 'firebase/app';

import { MatSnackBar } from '@angular/material/snack-bar';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { LoadingController } from '@ionic/angular';

import { AuthService } from '@services/auth.service';
import { OverlayService } from '@services/overlay.service';

import { environment } from '@env';
import { CustomUser } from '@models/CustomUser.model';

import { TranslateService } from '@ngx-translate/core';
import 'firebase/auth';

declare global {
  interface Window {
    recaptchaVerifier: any;
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnChanges {
  @Output()
  emitInvalid2FACode: EventEmitter<any> = new EventEmitter<any>();
  showUI: boolean;
  loginFormGroup: FormGroup;
  secondFactorFormGroup: FormGroup;
  showErrMsg = false;
  userData: CustomUser;
  displayUserBlockedMessage: boolean = false;
  gRecaptchaKey: string = environment.recaptchaKey;
  display2FAForm = false;
  verificationId: string;
  twoFactorCode: string;
  invalid2FACode: boolean = false;
  hidePwd: boolean = true;
  loading: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private overlayService: OverlayService,
    private snackBar: MatSnackBar,
    private faio: FingerprintAIO,
    private translate: TranslateService,
    public platform: Platform,
    public cdRef: ChangeDetectorRef,
    public loadingController: LoadingController,
  ) {}

  ngOnInit() {
    this.showUI = false;
    this.setUpForms();
    // this.showUI = true;

    if (this.platform.is('mobile')) {
      setTimeout(() => {
        this.showFingerPrint();
      }, 500);
    }

    // avoids "flashing" both forms (login and 2FA) in the screen
    setTimeout(() => this.renderLoginRecaptcha(), 300);
  }

  ngOnChanges(changes) {
    this.cdRef.detectChanges();
  }

  renderLoginRecaptcha() {
    let self = this;
    self.showUI = true;

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'login-button',
      {
        size: 'invisible',
        theme: 'dark',
        callback: async function (response) {
          // self.setProgressBar('show');
          self.loading = true;
          self.cdRef.detectChanges();
          if (response.error) {
            // self.setProgressBar('hide');
            this.loading = false;
            self.snackBar.open(response.message, null, {
              duration: 3000,
            });
          } else {
            await self.login();
            // self.setProgressBar('hide');
            self.loading = false;
            self.cdRef.detectChanges();
          }
        },
        // 'error-callback': (response) => {
        //   this.setProgressBar('hide');
        //   this.showErrMsg = true;
        // },
      },
    );
    window.recaptchaVerifier.render();
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Please wait...',
      showBackdrop: true,
      // duration: 5000
    });
    await loading.present();
  }

  // Firebase login
  login() {
    let self = this;
    self.loading = true;
    this.cdRef.detectChanges();
    // Remove two line below if recatpcha is activated
    this.showErrMsg = false;

    this.displayUserBlockedMessage = false;
    this.invalid2FACode = false;

    return new Promise((resolvePromise, rejectPromise) => {
      return this.authService
        .login(
          this.userEmail.value,
          this.userPwd.value,
          this.twoFactorCode,
        )
        .then(
          (res) => {
            self.showSnackBar();
            self.checkIfAdminAndNav();

            // If is mobile save user_info in storage to use with Fingerprint
            if (this.platform.is('mobile')) {
              const user_info = {
                userEmail: this.userEmail.value,
                userPwd: this.userPwd.value,
              };
              localStorage.setItem(
                'user_info',
                JSON.stringify(user_info),
              );
            }

            // resolvePromise(res);
          },
          (reject) => {
            if (reject.code === 'TWO_FACTOR_REQUIRED') {
              // self.setProgressBar('hide');
              this.loading = false;
              self.display2FAForm = true;
            } else if (reject.code === 'INVALID_CODE') {
              // self.setProgressBar('hide');
              this.loading = false;
              this.invalid2FACode = true;
              // this.emitInvalid2FACode.emit(true);
            } else if (reject.code === 'auth/user-disabled') {
              window.recaptchaVerifier.reset();
              // self.setProgressBar('hide');
              this.loading = false;
              this.displayUserBlockedMessage = true;
            } else {
              this.showErrMsg = true;
              // this.setProgressBar('hide');
              this.loading = false;
              window.recaptchaVerifier.reset();
            }
            self.cdRef.detectChanges();
            return resolvePromise(reject);
          },
        );
    });
  }

  // FORM STUFF
  setUpForms() {
    this.loginFormGroup = new FormGroup({
      userEmail: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      userPwd: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
    this.secondFactorFormGroup = new FormGroup({
      validationCode: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }

  get userPwd() {
    return this.loginFormGroup.get('userPwd');
  }

  get userEmail() {
    return this.loginFormGroup.get('userEmail');
  }

  get validationCode() {
    return this.secondFactorFormGroup.get('validationCode');
  }

  onSubmit() {
    try {
      this.showErrMsg = false;
      // this.setProgressBar('show');
      this.loading = true;
      window.recaptchaVerifier.verify();
    } catch (error) {
      // this.setProgressBar('hide');
      this.loading = false;
    }
  }

  showFingerPrint() {
    let self = this;
    const storage = localStorage.getItem('user_info');

    if (storage) {
      this.faio
        .show({
          title: 'Biometric Authentication',
          subtitle:
            'Place your finger on the sensor to validate your identity.',
          fallbackButtonTitle: 'Use Backup',
          disableBackup: true,
        })
        .then((result: any) => {
          if (result === 'biometric_success') {
            this.presentLoading();
            const user = JSON.parse(storage);
            this.authService
              .login(user.userEmail, user.userPwd)
              .then((res) => {
                self.showSnackBar();
                self.checkIfAdminAndNav();
                this.loadingController.dismiss();
              });
          }
        })
        .catch((error: any) => console.log(error));
    }
  }

  get2FACode(code) {
    // this.setProgressBar('show');
    this.loading = true;
    this.twoFactorCode = code;
    this.login();
  }

  signup() {
    this.router.navigate(['auth/signup']);
  }

  setProgressBar(status: string) {
    const action = {
      type: 'setProgressBar',
      msg: status,
    };
    this.overlayService.setAction(action);
    this.cdRef.detectChanges();
  }

  showSnackBar(message: string = 'You are now logged in') {
    const action = {
      type: 'openSnackBar',
      msg: message,
    };
    this.overlayService.setAction(action);
  }

  checkIfAdminAndNav() {
    // this.setProgressBar('show');
    this.loading = true;
    if (this.authService.userIsAdmin() === true) {
      // Since now this app no longer has the admin stuff, just show some error
      this.showSnackBar(
        'your user type is ADMIN, please log in the admin app',
      );
      // const adminRole = this.authService.getAdminRole();
      // switch (adminRole) {
      //   case 'MASTER_MANAGER':
      //     this.router.navigate(['/admin/financial']);
      //     break;
      //   case 'MANAGER':
      //     this.router.navigate(['/admin/userslist']);
      //     break;
      //   case 'FINANCIAL_MANAGER':
      //     this.router.navigate(['/admin/withdrawals']);
      //     break;
      //   case 'PAYMENT_OPERATOR':
      //     this.router.navigate(['/admin/withdrawals']);
      //     break;
      //   case 'KYC':
      //     this.router.navigate(['/admin/userslist']);
      //     break;
      //   default:
      //     this.router.navigate(['/admin/userslist']);
      //     break;
      // }
    } else if (this.authService.userIsAdmin() === false) {
      this.router.navigate(['/main/dashboard']);
    } else {
      console.log('Error getting isAdmin after login');
    }
    // this.setProgressBar('hide');
    this.loading = false;
  }

  // METHODS THAT RETURN FORM ERROR MSGS
  getEmailErrorMsg() {
    return this.userEmail.hasError('required')
      ? this.translate.instant('formErrors.emailErrorMsg1')
      : this.userEmail.hasError('email')
      ? this.translate.instant('formErrors.emailErrorMsg2')
      : '';
  }

  getPwdErrorMsg() {
    return this.userPwd.hasError('required')
      ? this.translate.instant('formErrors.pwdErrorMsg1')
      : this.userPwd.hasError('minlength')
      ? this.translate.instant('formErrors.pwdErrorMsg2')
      : '';
  }

  getValidationCodeErrorMsg() {
    return this.validationCode.hasError('required')
      ? this.translate.instant('formErrors.fieldRequired')
      : this.validationCode.hasError('minlength')
      ? this.translate.instant('formErrors.validCodeLength')
      : '';
  }

  async doLogin() {
    this.loading=true;
    this.cdRef.detectChanges();
    await this.login();
    this.loading = false;
    this.cdRef.detectChanges();
  }
}
