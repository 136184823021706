import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';

import { environment } from '../../environments/environment';

import { WalletAddress } from '../models/WalletAddress.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AddressesService {
  baseURL = environment.baseURL;

  constructor(
    private afs: AngularFirestore,
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  async createAddress(assetID: string): Promise<Observable<any>> {
    const createURL = `${this.baseURL}/Gateway/attachAddressAccount`;
    const createData = {
      coin_id: assetID,
    };
    const httpOptions = await this.authService.getAuthHeaders();
    return this.http.put<any>(
      createURL,
      JSON.stringify(createData),
      httpOptions,
    );
  }

  getAddressesByAsset(asset: string): Observable<WalletAddress[]> {
    const assetsCollection = this.afs.collection(
      `/manager/${asset}/availableAddresses`,
    );
    const addresses = assetsCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as WalletAddress;
          data.id = action.payload.doc.id;
          return data;
        });
      }),
    );
    return addresses;
  }

  async uploadWalletAddressesFile(file: File, asset: string) {
    const URL = `${this.baseURL}/Gateway/uploadAddresses`;
    const b64File = await this.toBase64(file);
    const data = {
      origin_file: file.name,
      addresses: b64File,
      asset,
    };
    const httpOptions = await this.authService.getAuthHeaders();
    return this.http.post<any>(URL, data, httpOptions);
  }

  toBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  getAvailableAddressesCount(assetID: string): Promise<number> {
    const countersCollection = this.afs.collection(
      '/statistics/collections/counters',
    );
    return new Promise((resolve, reject) => {
      countersCollection
        .doc(`manager-${assetID}-availableAddresses`)
        .get()
        .subscribe(
          (counterDoc) => {
            const data = counterDoc.data() as any;
            // console.log(`${assetID} total docs: ${data.documents}`);
            resolve(data.documents);
          },
          (err) => reject(err),
        );
    });
  }

  getAttachedAddressesCount(assetID: string): Promise<number> {
    const countersCollection = this.afs.collection(
      '/statistics/collections/counters',
    );
    return new Promise((resolve, reject) => {
      countersCollection
        .doc(`manager-${assetID}-attached_address`)
        .get()
        .subscribe(
          (counterDoc) => {
            const data = counterDoc.data() as any;
            // console.log(`${assetID} total docs: ${data.documents}`);
            resolve(data.documents);
          },
          (err) => reject(err),
        );
    });
  }
}
