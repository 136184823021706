import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { floor10 } from '../../../custom/Utils';

import { AuthService } from '@services/auth.service';
import { DataService } from '@services/data.service';
import { NotificationsService } from '@services/notifications.service';
import { Currency } from 'src/app/models/Currency.model';
import { CustomUser } from 'src/app/models/CustomUser.model';
import { Notification } from 'src/app/models/Notification.model';
import { TranslateService } from '@ngx-translate/core';
import { AngularFirestore } from '@angular/fire/firestore';


function getCookie(cname) {
  let name = cname + "="
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

let refUser = getCookie("refid")

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  isLoading = false;
  loadingDeposit = false;
  floor10 = floor10;

  currentUserID: string;
  currentUserName: string;
  userData: CustomUser;
  userDataSubscription: any;
  assetSubscription: any;
  emailValidated: boolean;
  displayUI: boolean = false;

  assets: Currency[];
  balances: any[];
  balancesSubscription: any;
  quotes: any;
  quotesSubscription: any;
  address: string;
  history: any[];

  sendingEmail = false;

  // Notifications Area
  showNotifications = true;
  notification: Notification;

  constructor(
    private authService: AuthService,
    private afs: AngularFirestore,
    private dataService: DataService,
    private snackBar: MatSnackBar,
    private notificationsService: NotificationsService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.checkEmailConfStatus();
    this.isLoading = true;
    this.currentUserName = this.authService.getUserDisplayName();
    this.currentUserID = this.authService.getCurrentUserID();

    // when language is changed, must update notification content...
    this.translate.onDefaultLangChange.subscribe(res => {
      this.setNotification();
    });

    this.userDataSubscription = this.authService
      .getUserFromDbAsObs(this.currentUserID)
      .subscribe(async (userdata) => {
        this.userData = userdata

        if (this.userData.referral_user=='' && refUser!='') {
          this.afs.doc('users/'+this.currentUserID).update({ referral_user: refUser })
        }

        this.checkValidations()
        if (
          this.emailValidated &&
          this.userData &&
          this.userData.kyc &&
          this.userData.kyc.status !== 'BLOCKED'
        ) {
          // Gets currency definition from DB and other stuff
          await this.getAssetsFromDB();
          this.getQuotesAsObs();
          this.getBalances();
          this.isLoading = false;
          this.setNotification();
        }
      });
  }

  ngOnDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
    if (this.quotesSubscription) {
      this.quotesSubscription.unsubscribe();
    }
    if (this.balancesSubscription) {
      this.balancesSubscription.unsubscribe();
    }
    if (this.assetSubscription) {
      this.assetSubscription.unsubscribe();
    }
  }

  checkValidations() {
    if (!this.emailValidated) {
      this.router.navigate(['/validations/email']);
    }
    this.displayUI = true;
  }

  checkEmailConfStatus() {
    this.emailValidated = !!this.authService.getUserEmailConfStatus();
  }

  reloadPage() {
    window.location.reload();
  }

  sendEmailVerification() {
    this.sendingEmail = true;
    this.authService.sendEmailVerification().then(
      () => {
        this.sendingEmail = false;
        this.snackBar.open('E-mail successfully sent!', null, {
          duration: 2000,
        });
      },
      (reject) => {
        this.sendingEmail = false;
        this.snackBar.open(
          'Too many attempt, try again later',
          null,
          {
            duration: 2000,
          },
        );
      },
    );
  }

  setNotification() {
    this.notificationsService.intializeNotifStatus();
    this.notification = this.notificationsService.getNotification(
      this.userData.kyc.status,
    );
    if (this.userData.kyc.status === 'REJECTED') {
      this.notification.msgAdditionalInfo = `${this.translate.instant('kyc.rejecteReasonTitle')}: ${this.userData.kyc.reject_reason}`;
    }

    const notifStatus = this.notificationsService.getNotifStatus(
      this.userData.kyc.status,
    );
    this.showNotifications = notifStatus;
  }

  getBalances() {
    this.balancesSubscription = this.dataService
      .getBalancesFromDB(this.currentUserID)
      .subscribe((balances) => {
        this.dataService.saveBalances(balances);
        this.balances = [...balances];
      });
  }

  getBalance(currencyID: string) {
    return this.balances.find((balance) => {
      return balance.id === currencyID;
    });
  }

  // async getQuotesFromDB() {
  //   await this.dataService.getQuotesFromDB().then((quotes) => {
  //     // console.log('getQuotes result:', quotes);
  //     this.quotes = quotes;
  //     this.dataService.saveQuotes(quotes);
  //     this.setBuyUI();
  //   });
  // }

  getQuotesAsObs() {
    this.quotesSubscription = this.dataService
      .getQuotesAsObs()
      .subscribe((quotes) => {
        // console.log('getQuotesAsObs result:', quotes);
        this.quotes = quotes;
        this.dataService.saveQuotes(quotes);
        this.dataService.broadcastQuotes(quotes);
        this.setBuyUI();
      });
  }

  async getAssetsFromDB() {
    this.assetSubscription = await this.dataService.getAssetsFromDB().subscribe((assets) => {
      // console.log('getAssets result:', assets);
      this.assets = assets;
      this.dataService.saveAssets(assets);
      this.dataService.saveAssetsInLocalStorage(assets);
    });
  }

  setBuyUI() {
    this.getCoinExchgRate('BTC', 'USD');
  }

  getCoinExchgRate(baseCoin: string, targetCoin: string) {
    if (baseCoin === targetCoin) {
      return 1;
    } else {
      const quote = this.quotes.find((el) => el.base === baseCoin);
      const rate = quote.rates[targetCoin];
      return +floor10(rate, -6);
    }
  }

  closeNotification() {
    this.showNotifications = false;
    this.notificationsService.updateNotifStatus(
      this.userData.kyc.status,
      false,
    );
  }
}
