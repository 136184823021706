<div class="tutorial">
  <div class="backgroundColor"></div>
  <div class="backgroundImage"></div>

  <div class="content">
    <div class="content__text">
      <!-- <h2>Get ready to trade</h2> -->
      <!-- <p>
        Watch this video for a quick guide to buying and selling Soya
        Coin and making payments through our platform. You'll be all
        set. .
      </p> -->
      <h2>{{ 'tutorial.title' | translate }}</h2>
      <p>
        {{ 'tutorial.text1' | translate }}
      </p>
      <p>
        {{ 'tutorial.text2' | translate }}
      </p>
      <p>
        {{ 'tutorial.text3' | translate }}
      </p>
    </div>

    <svg-icon
      class="play-icon"
      src="../../../assets/img/play.svg"
      [svgStyle]="{ 'width.px': 55, 'height.px': 55 }"
      (click)="openDialog()"
    >
    </svg-icon>
  </div>
</div>
