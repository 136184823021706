<div class="receive" *ngIf="!codeWasGenerated">
  <div class="receive__main">
    <div class="receive__step receive__howmuch">
      <p class="receive__title" *ngIf="!publicInvoice">
        {{ 'receive.requestTitle' | translate }}
      </p>
      <form [formGroup]="receiveFormGroup" *ngIf="!publicInvoice">
        <mat-form-field class="receive__ff" appearance="fill">
          <mat-label>{{ 'receive.descrPlaceholder' | translate }}</mat-label>
          <input
            class="step__form-input"
            matInput
            #fieldInvoiceName
            name="invoiceName"
            [value]="invoiceName"
            [formControl]="name"
            type="text"
            maxlength="50"
            required
          />
        </mat-form-field>
        <mat-form-field class="receive__ff" appearance="fill">
          <mat-label>{{ 'receive.amountPlaceholder' | translate }}</mat-label>
          <input
            class="text-right-align"
            matInput
            #spendInput
            [value]="receiveAmount"
            [formControl]="collect"
            inputmode="decimal"
            [min]="minAmount"
            [step]="stepIncrement"
            (keyup.enter)="evaluateInput(spendInput.value)"
            (blur)="evaluateInput(spendInput.value)"
          />
        </mat-form-field>
      </form>
    </div>
    <!-- <div>
      <mat-checkbox class="example-margin" [(ngModel)]="publicInvoice"
        >Public?</mat-checkbox
      >
    </div> -->
  </div>

  <div class="receive__usd">
    <p>{{ 'receive.valueText' | translate }} &nbsp;</p>
    <p class="receive__usd--value">
      {{ usdAmount | currency: 'CAD':'':'1.2-2' }}
      USD
    </p>
  </div>

  <div class="receive__final">
    <button
      class="btn"
      mat-flat-button
      color="primary"
      (click)="generateInvoice()"
      [disabled]="receiveAmount < minAmount || !invoiceName"
    >
    {{ 'receive.button1' | translate | uppercase }}
    </button>
  </div>
</div>

<!-- CODE, QRCODE and INSTRUCTIONS -->
<div class="invoice" *ngIf="codeWasGenerated">
  <div class="invoice__header">
    <div class="invoice__nutshell mr-lg">
      <p class="receive__title">
        {{ 'receive.invoiceValueTitle' | translate }}
      </p>
      <div class="invoice__nutshell--total">
        <p class="invoice__nutshell--value" *ngIf="!publicInvoice">
          {{ receiveAmount | currency: 'USD':'':'1.2-2' }}
        </p>
        <p class="invoice__nutshell--coin">
          SOY
        </p>
      </div>
      <p class="invoice__nutshell--usd" *ngIf="!publicInvoice">
        {{ usdAmount | currency: 'USD':'':'1.2-2' }} USD
        (approx.)
      </p>
    </div>

    <!-- <div class="invoice__nutshell">
      <p class="receive__title">
        Invoice Total:
      </p>
      <div class="invoice__nutshell--total">
        <p class="invoice__nutshell--value" *ngIf="!publicInvoice">
          {{
            +receiveAmount + pctFee * receiveAmount
              | currency: 'USD':'':'1.2-2'
          }}
        </p>
        <p class="invoice__nutshell--coin">
          SOY
        </p>
      </div>
      <p class="invoice__nutshell--fee" *ngIf="!publicInvoice">
        The payer will be charged a {{ pctFee * 100 }}% fee
      </p>
    </div> -->
  </div>

  <div class="invoice__code">
    <p class="receive__title">
      {{ 'receive.invoiceCodeTitle' | translate }}
    </p>
    <div class="invoice__code--area">
      <p class="invoice__code--number">
        {{ invoiceCode.invoice_channel }}
      </p>
      <div class="icons">
        <ion-icon
          name="copy-outline"
          (click)="copyToService(invoiceCode.invoice_channel)"
        ></ion-icon>
        <ion-icon
          name="logo-whatsapp"
          (click)="openWhatsAppLink(whatsAppLink)"
        ></ion-icon>
      </div>
    </div>
  </div>
  <div class="invoice__main">
    <div class="invoice__visual">
      <div class="qrcode-container">
        <img
          [src]="getQRCode()"
          alt="qrcode"
          class="qrcode"
          *ngIf="invoiceCode != 'Generating code ...'"
        />
        <mat-spinner
          [diameter]="50"
          *ngIf="invoiceCode === 'Generating code ...'"
        ></mat-spinner>
      </div>
      <p class="invoice__visual--warning">
        {{ 'receive.invoiceWarning' | translate }}
      </p>
    </div>
  </div>
  <!-- <button
    class="btn"
    mat-flat-button
    color="accent"
    (click)="resetPayment()"
    *ngIf="codeWasGenerated"
  >
    New Payment Request
  </button> -->
</div>
