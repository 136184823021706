<div class="notAllowed" *ngIf="!isAllowed && hasLoaded">
  <div class="notAllowed__main">
    <h4 class="notAllowed__title mb-md">{{ 'withdraw.notAllowedTitle' | translate }}</h4>
    <p class="notAllowed__text">
      {{ 'withdraw.notAllowedText1' | translate }}
    </p>
    <p class="notAllowed__text mb-md">
      {{ 'withdraw.notAllowedText2' | translate }}
    </p>
    <button
      class="notAllowed__btn mb-md"
      mat-flat-button
      color="primary"
      routerLink="/main/profile"
      mat-dialog-close
    >
    {{ 'withdraw.notAllowedButton' | translate }}
    </button>
  </div>
</div>

<div class="container" *ngIf="isAllowed && hasLoaded">
  <figure class="webcam">
    <div class="webcam__icon-qrcode" *ngIf="!showCam">
      <ion-icon
        *ngIf="!isMobile"
        (click)="startScan()"
        name="camera-outline"
      ></ion-icon>

      <ion-icon
          *ngIf="isMobile"
          (click)="openQrScanner()"
          name="camera-outline"
        ></ion-icon>
      <h2>QR Code</h2>
      <p>webcam scanning</p>
    </div>

    <zxing-scanner
      *ngIf="showCam && !isMobile"
      #scanner
      (camerasFound)="camerasFoundHandler($event)"
      (camerasNotFound)="camerasNotFoundHandler($event)"
      (scanSuccess)="scanSuccessHandler($event)"
      (scanError)="scanErrorHandler($event)"
    ></zxing-scanner>
  </figure>

  <div class="coin section">
    <mat-form-field class="form" appearance="fill">
      <mat-label>{{ 'withdraw.selectCoinPlaceholder' | translate }}</mat-label>
      <mat-select
        (selectionChange)="coinWasSelected($event)"
        [(value)]="currentCoinID"
      >
        <mat-option value="SOY" class="notranslate">SOYA</mat-option>
        <mat-option value="BTC" class="notranslate"
          >BITCOIN</mat-option
        >
        <mat-option value="ETH" class="notranslate">ETHER</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="address section">
    <form [formGroup]="formGroup1">
      <mat-form-field
        class="address__formfield"
        color="primary"
        appearance="fill"
      >
        <mat-label>{{ 'withdraw.addressPlaceholder' | translate }}</mat-label>
        <input
          #addressInput
          matInput
          placeholder="Type here"
          type="text"
          [formControl]="address"
          required
        />
        <mat-hint
          *ngIf="addressInput.value && !addressIsvalid"
          class="address__message--error"
          >{{ 'withdraw.addresMsgIncorrect' | translate }}</mat-hint
        >
        <mat-hint
          *ngIf="addressInput.value && addressIsvalid"
          class="address__message--ok"
          >{{ 'withdraw.addresMsgCorrect' | translate }}</mat-hint
        >
      </mat-form-field>
    </form>
  </div>

  <div class="amount section">
    <form>
      <mat-form-field
        class="amount__formfield"
        color="primary"
        appearance="fill"
      >
        <mat-label>{{ 'withdraw.amountPlaceholder' | translate }}</mat-label>
        <input
          matInput
          [ngModel]="amount"
          (change)="onAmountTyped($event)"
          (click)="onAmountTyped($event)"
          [disabled]="!addressIsvalid"
          name="amount"
          inputmode="decimal"
          placeholder="Type value here"
          [step]="stepIncrement"
          [min]="minimum"
          [max]="balance"
          class="text-right-align"
        />
        <!-- <span matPrefix>SC$&nbsp;</span>
      <span matSuffix>.00</span> -->
        <mat-hint
          >{{ 'withdraw.button1' | translate }} {{ minimum }}
          {{ currentCoinID }}</mat-hint
        >
      </mat-form-field>
    </form>
    <mat-icon class="amount__icon">swap_horiz</mat-icon>
    <div class="total">
      <div class="total__field">
        <p class="total__title">{{ 'withdraw.resultPlaceholder' | translate }}</p>
        <p
          *ngIf="!nothingTyped"
          class="total__number"
          [hidden]="!displayTotal && (amount || amount > 0)"
        >
          {{
            floor10(
              sub(sub(amount, fixFee), multiply(pctFee, amount)),
              -8
            )
          }}
        </p>
        <p *ngIf="nothingTyped">&nbsp;</p>
      </div>
      <mat-hint
        class="total__usd"
        [hidden]="
          !displayTotal ||
          getTotalValue(convRate, amount, fixFee, pctFee) <= 0
        "
        >Aprox.
        {{ getTotalValue(convRate, amount, fixFee, pctFee) }}
        USD</mat-hint
      >
      <mat-hint
        class="total__usd"
        [hidden]="
          !displayTotal ||
          getTotalValue(convRate, amount, fixFee, pctFee) > 0
        "
        >0</mat-hint
      >
    </div>
  </div>

  <div class="overview section">
    <div class="balance">
      <div class="balance__buttons">
        <div class="balance__btn" (click)="inputPreset(0.25)">
          25%
        </div>
        <div class="balance__btn" (click)="inputPreset(0.5)">50%</div>
        <div class="balance__btn" (click)="inputPreset(0.75)">
          75%
        </div>
        <div class="balance__btn" (click)="inputPreset(1)">100%</div>
      </div>
      <div class="balance__info">
        <p class="balance__info--label">{{ 'withdraw.balanceTxt' | translate }}</p>
        <p class="balance__info--value">
          {{ floor10(balance, -8) }}
          {{ currentCoinID }}
        </p>
      </div>
    </div>
    <div class="observ">
      <div *ngIf="fixFee">
        <div class="observ__item">
          <p class="observ__label">{{ 'withdraw.FixedFeeTxt' | translate }}</p>
          <div class="observ__value">
            {{ fixFee }} {{ currentCoinID }}
          </div>
        </div>
      </div>
      <div *ngIf="pctFee">
        <div class="observ__item">
          <p class="observ__label">{{ 'withdraw.PctFeeTxt' | translate }}</p>
          <div class="observ__value">
            {{ multiply(pctFee, 100) }} %
          </div>
        </div>
      </div>
      <div
        class="observ__item"
        [style.opacity]="blockWithdraw ? 0.5 : 1"
      ></div>
    </div>
  </div>

  <div class="two-factor section" *ngIf="display2FAForm">
    <form [formGroup]="twoFactorFormGroup">
      <mat-form-field
        class="two-factor__formfield"
        color="primary"
        appearance="fill"
      >
        <mat-label>{{ 'withdraw.twoFactorCodePlaceholder' | translate }}</mat-label>
        <input
          #twoFAInput
          matInput
          name="two_factor_code"
          inputmode="decimal"
          placeholder="Type here"
          type="text"
          [formControl]="two_factor_code"
          required
        />
        <mat-hint
          *ngIf="twoFAInput.invalid"
          class="two_factor_code__message--error"
          >{{ 'withdraw.invalidTwoFactor' | translate }}</mat-hint
        >
      </mat-form-field>
    </form>
  </div>

  <div class="final section">
    <button
      class="final__btn"
      mat-flat-button
      color="primary"
      (click)="submit()"
      [disabled]="
        !addressIsvalid ||
        blockWithdraw ||
        nothingTyped ||
        this.amount === 0 ||
        amount < minimum ||
        isLoading
      "
    >
    {{ 'withdraw.button1' | translate | uppercase }}
    </button>
  </div>

  <div class="blocked">
    <p class="blocked__msg" *ngIf="blockWithdraw">
      {{ 'withdraw.balanceError' | translate | uppercase }}
    </p>
    <p class="blocked__msg" *ngIf="amount < minimum && !nothingTyped">
      {{ 'withdraw.minimumError' | translate | uppercase }}
    </p>
  </div>
</div>
