import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';

import { environment } from '../../environments/environment';
import { Bucket } from '../models/Bucket.model';
import { BucketTrade } from '../models/BucketTrade.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class BucketService {
  baseURL = environment.baseURL;

  constructor(
    private afs: AngularFirestore,
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  async consumeBucket(
    bucketCoinId: string,
    assetId: string,
    amount: number,
  ): Promise<Observable<any>> {
    const consumeBucketURL = `${this.baseURL}/Bucket/consume`;
    const consumeBucketData = {
      bucket_coin_id: bucketCoinId,
      asset: assetId,
      amount: Number(amount),
    };
    const httpOptions = await this.authService.getAuthHeaders();
    return this.http.post<any>(
      consumeBucketURL,
      JSON.stringify(consumeBucketData),
      httpOptions,
    );
  }

  getBucketTrades(userID: string): Observable<BucketTrade[]> {
    const bucketTradesRef = this.afs.collection(
      `/users/${userID}/bucket_trades`,
    );
    const trades = bucketTradesRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as BucketTrade;
          data.id = action.payload.doc.id;
          if (
            data.created_at &&
            typeof data.created_at !== 'string'
          ) {
            data.created_at = (data.created_at as any).toDate();
          }
          return data;
        });
      }),
    );
    return trades;
  }

  getAllBucketTrades(bucketID: string): Observable<BucketTrade[]> {
    const bucketTradesRef = this.afs.collection(
      `/buckets/${bucketID}/trades`,
    );
    const trades = bucketTradesRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as BucketTrade;
          data.id = action.payload.doc.id;
          if (
            data.created_at &&
            typeof data.created_at !== 'string'
          ) {
            data.created_at = (data.created_at as any).toDate();
          }
          return data;
        });
      }),
    );
    return trades;
  }
}
