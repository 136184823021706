import { OverlayService } from './../../services/overlay.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { MatSelectChange } from '@angular/material/select';

import { environment } from '../../../environments/environment';

import { Deposit } from 'src/app/models/Deposit.model';
import { Withdrawal } from 'src/app/models/Withdrawal.model';
import { MarketOrder } from 'src/app/models/MarketOrder.model';

import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { SoyCurrencyPipe } from '../../custom/currency_types/soyCurrencyPipe';
import { InvoiceChannel } from 'src/app/models/InvoiceChannel.model';
import { PaidInvoice } from 'src/app/models/PaidInvoice.model';
import { floor10 } from '../../custom/Utils';
import { BucketService } from 'src/app/services/bucket.service';
import { WithdrawalService } from 'src/app/services/withdrawal.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { TranslateService } from '@ngx-translate/core';

interface History {
  amount: number;
  created_at: Date;
  status: string;
  coin: string;
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})

export class HistoryComponent implements OnInit, OnDestroy {
  currentUserID: string;

  historyData: History[] = [];
  startQtd = 10;
  historyQtd: number = this.startQtd;
  loadingData: boolean;
  selectedAction: string;
  selectOptions: any[];
  selectedCoin: string;
  blockExplorerLink: any;
  floor10 = floor10;

  // Subscriptions
  depositSubscription;
  invoiceRevenueSubscription;
  invoicePaySubscription;
  invoicesSubscription;
  askPurchaseSubscription;
  bidPurchaseSubscription;
  withdrawalRequestSubscription;
  withdrawalSubscription;
  bucketTradesSubscription;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private overlayService: OverlayService,
    private bucketService: BucketService,
    private withdrawalService: WithdrawalService,
    private invoiceService: InvoiceService,
    private translate: TranslateService
  ) {}

  get userRole() {
    return this.authService.userRole;
  }

  ngOnInit() {
    this.blockExplorerLink = environment.blockExplorerLink;
    this.currentUserID = this.authService.getCurrentUserID();

    this.selectedCoin = 'SOY';

    // This has do with translating the options in the actions mat-select
    this.setSelectOptions();
    // when language is changed, update again the select options
    this.translate.onDefaultLangChange.subscribe(res => {
      this.setSelectOptions();
    });

    this.loadingData = false;

    if (this.userRole === 'MASTER_MANAGER') {
      this.selectedAction = 'deposit';
      this.actionWasSelected({ value: 'deposit' } as MatSelectChange);
    } else {
      this.selectedAction = 'trades';
      this.actionWasSelected({ value: 'trades' } as MatSelectChange);
    }


  }

  ngOnDestroy() {
    this.unsubscribeAllHistory();
  }

  unsubscribeAllHistory() {
    if (this.depositSubscription) {
      this.depositSubscription.unsubscribe();
    }
    if (this.invoiceRevenueSubscription) {
      this.invoiceRevenueSubscription.unsubscribe();
    }
    if (this.invoicePaySubscription) {
      this.invoicePaySubscription.unsubscribe();
    }
    if (this.invoicesSubscription) {
      this.invoicesSubscription.unsubscribe();
    }
    if (this.bidPurchaseSubscription) {
      this.bidPurchaseSubscription.unsubscribe();
    }
    if (this.withdrawalRequestSubscription) {
      this.withdrawalRequestSubscription.unsubscribe();
    }
    if (this.withdrawalSubscription) {
      this.withdrawalSubscription.unsubscribe();
    }
    if (this.bucketTradesSubscription) {
      this.bucketTradesSubscription.unsubscribe();
    }
  }

  sortByCreatedAt(a, b) {
    return b.created_at - a.created_at;
  }

  actionWasSelected({ value }: MatSelectChange) {
    this.selectedAction = value;
//    this.selectedCoin =
//      this.selectedCoin === 'SOY' &&
//      this.selectedAction === 'withdraw'
//        ? 'BTC'
//        : this.selectedCoin;
    this.loadNewData(value);
  }

  // Had to do this, since ngx-translate doesn´t work in mat-selected options...
  setSelectOptions() {
    if (this.userRole !== 'MASTER_MANAGER') {
      // all options available
      this.selectOptions = [
        {value: 'trades', label: this.translate.instant('history.option1')},
        {value: 'deposit', label: this.translate.instant('history.option2')},
        {value: 'withdraw', label: this.translate.instant('history.option3')},
        {value: 'invoices', label: this.translate.instant('history.option4')},

      ];
    } else {
      this.selectOptions = [
        {value: 'deposit', label: this.translate.instant('history.option2')},
        {value: 'withdraw', label: this.translate.instant('history.option3')}
      ];
    }
  }

  coinWasSelected(evt: MatSelectChange) {
    this.unsubscribeAllHistory();
    this.selectedCoin = evt.value;
    this.dataService.saveSelectedCoin(this.selectedCoin);
    this.loadNewData(this.selectedAction);
  }

  loadNewData(value: string) {
    this.historyData = [];
    this.historyQtd = this.startQtd;
    switch (value) {
      case 'invoices':
        this.unsubscribeAllHistory();
        return this.getInvoices();
      case 'deposit':
        this.unsubscribeAllHistory();
        return this.getDeposits();
      case 'trades':
        this.unsubscribeAllHistory();
        return this.getBucketTrades();
      case 'withdraw':
        this.unsubscribeAllHistory();
        return this.getWithdrawals();
      default:
        break;
    }
  }

  getInvoices() {
    let invoiceRevenue: any[];
    let paidInvoices: any[];
    let userInvoices: any[];
    this.loadingData = true;
    this.invoiceRevenueSubscription = this.invoiceService
      .getInvoicesRevenue(this.currentUserID)
      .subscribe((invoices: any) => {
        invoiceRevenue = invoices;
        this.invoicePaySubscription = this.invoiceService
          .getInvoicesPayments(this.currentUserID)
          .subscribe((pInvoices) => {
            paidInvoices = pInvoices;
            this.invoicesSubscription = this.invoiceService
              .getInvoices(this.currentUserID)
              .subscribe((uInvoices) => {
                userInvoices = uInvoices;

                if (this.selectedAction === 'invoices') {
                  this.historyData = invoiceRevenue
                    .concat(paidInvoices)
                    .concat(userInvoices)
                    .sort(this.sortByCreatedAt)
                    .map(
                      ({
                        id,
                        payee,
                        payer,
                        side,
                        created_at,
                        coin_id,
                        amount,
                        amount_request,
                        value_received,
                        is_invoice,
                      }) => ({
                        id,
                        status:
                          side === 'SPENT'
                            ? `Paid ${payee}`
                            : side === 'RECEIVED'
                            ? `Received from ${payer}`
                            : 'Created an invoice',
                        side,
                        amount: amount
                          ? amount
                          : value_received
                          ? value_received
                          : amount_request,
                        created_at,
                        is_invoice,
                        coin: coin_id,
                      }),
                    );
                }
                this.loadingData = false;
              });
          });
      });
  }

  getDeposits() {
    this.loadingData = true;
    this.depositSubscription = this.dataService
      .getDeposits(this.currentUserID, this.selectedCoin)
      .subscribe((deposits) => {
        if (this.selectedAction === 'deposit') {
          this.historyData = deposits
            .sort(this.sortByCreatedAt)
            .filter((e) => {
              return e.asset === this.selectedCoin;
            })
            .map(({ id, created_at, value: amount, status }) => ({
              txid: id,
              amount,
              status,
              created_at,
              coin: this.selectedCoin,
            }));
        }
        this.loadingData = false;
      });
  }

  getBucketTrades() {
    this.loadingData = true;
    this.bucketTradesSubscription = this.bucketService
      .getBucketTrades(this.currentUserID)
      .subscribe((trades) => {
        this.historyData = trades
          .sort(this.sortByCreatedAt)
          .map(({ id, created_at, coin_id, base_amount, bucket_coin_id, quote_amount }) => ({
            id: id,
            amount: base_amount,
            status: 'COMPLETED',
            coin: bucket_coin_id,
            sell_coin_id: coin_id,
            quote_amount,
            created_at,
          }));
        this.loadingData = false;
      });
  }

  getWithdrawals() {
//    this.selectedCoin =
//      this.selectedCoin === 'SOY' ? 'BTC' : this.selectedCoin;
    let approvedWDs: any[];
    let toBeApprovedWDs: any[];
    this.loadingData = true;
    this.withdrawalSubscription = this.withdrawalService
      .getWithdrawals(this.currentUserID, this.selectedCoin)
      .subscribe((data1) => {
        approvedWDs = data1;
        this.withdrawalRequestSubscription = this.withdrawalService
          .getToBeApprovedWithdrawals(
            this.currentUserID,
            this.selectedCoin,
          )
          .subscribe((data2) => {
            toBeApprovedWDs = data2;
            if (this.selectedAction === 'withdraw') {
              this.historyData = approvedWDs
                .concat(toBeApprovedWDs)
                .sort(this.sortByCreatedAt)
                .map(
                  ({ amount, asset, created_at, status, txid, value }) => ({
                    amount,
                    coin: asset,
                    created_at,
                    status,
                    txid,
                    value,
                  }),
                );
            }
            this.loadingData = false;
          });
      });
  }

  openInvoice(item: any) {
    const action = {
      type: 'openDialog',
      msg: 'receive',
      props: item,
    };
    this.overlayService.setAction(action);
  }

  getNameOfIcon(status: string) {
    const lcStatus = status.toLowerCase();
    // == IMP: Im using .startsWith instead of '===' because Invoice status actually brings a full text....
    if (
      lcStatus.startsWith('canceled') ||
      lcStatus.startsWith('rejected')
    ) {
      return 'close-circle';
    } else if (
      lcStatus.startsWith('ok') ||
      lcStatus.startsWith('approved') ||
      lcStatus.startsWith('completed')
    ) {
      return 'checkmark-circle';
    } else if (
      lcStatus.startsWith('open') ||
      lcStatus.startsWith('pending')
    ) {
      return 'time';
    } else if (lcStatus.startsWith('paid')) {
      return 'arrow-down-circle';
    } else if (lcStatus.startsWith('received')) {
      return 'arrow-up-circle';
    } else if (lcStatus.startsWith('created')) {
      return 'add-circle';
    } else {
      return 'help-circle';
    }
  }

  getColorOfIcon(status: string) {
    const lcStatus = status.toLowerCase();
    // == IMP: Im using .startsWith instead of '===' because Invoice status actually brings a full text....
    if (
      lcStatus.startsWith('canceled') ||
      lcStatus.startsWith('rejected')
    ) {
      return '#f0544d';
    } else if (
      lcStatus.startsWith('ok') ||
      lcStatus.startsWith('approved') ||
      lcStatus.startsWith('completed')
    ) {
      return '#599900';
    } else if (
      lcStatus.startsWith('open') ||
      lcStatus.startsWith('pending')
    ) {
      return '#c89c2a';
    } else if (lcStatus.startsWith('paid')) {
      return '#f0544d';
    } else if (lcStatus.startsWith('received')) {
      return '#599900';
    } else if (lcStatus.startsWith('created')) {
      return '#c89c2a';
    } else {
      return '#c89c2a';
    }
  }

  loadMore() {
    this.historyQtd += 5;
  }
}
