import { ActivatedRoute, Router } from '@angular/router';
import { OverlayService } from 'src/app/services/overlay.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  QRScanner,
  QRScannerStatus,
} from '@ionic-native/qr-scanner/ngx';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss'],
})
export class QrScannerComponent implements OnInit, OnDestroy {
  scanSubscription: Subscription;
  backButtonSubscription: Subscription;
  redirectTo: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private qrScanner: QRScanner,
    private overlayService: OverlayService,
    private platform: Platform,
  ) {}

  ngOnInit() {
    this.redirectTo = this.route.snapshot.paramMap.get('action');
    this.scan();
    if (this.backButtonSubscription) {
      this.backButtonSubscription.unsubscribe();
    }
    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(10, () => {
      this.stopScanning();
      this.router.navigateByUrl('main');
      this.openDialog(this.redirectTo);
    });
  }

  ngOnDestroy() {
    this.backButtonSubscription.unsubscribe();
  }


  scan() {
    (window.document.querySelector(
      'ion-app',
    ) as HTMLElement).classList.add('cameraView');
    this.qrScanner
      .prepare()
      .then((status: QRScannerStatus) => {
        if (status.authorized) {
          this.qrScanner.show();
          this.scanSubscription = this.qrScanner
            .scan()
            .subscribe((text: string) => {
              (<HTMLMetaElement>(
                document.querySelector('meta[name="color-scheme"]')
              )).content = 'light dark';
              this.stopScanning();
              this.router.navigateByUrl(`main?qrValue=${text}`, {
                state: { qrCode: text },
              });
              this.openDialog(this.redirectTo, text);
            });
        } else {
          console.error('Permission Denied', status);
        }
      })
      .catch((e: any) => {
        console.error('Error', e);
      });
  }

  stopScanning() {
    this.scanSubscription
      ? this.scanSubscription.unsubscribe()
      : null;
    this.scanSubscription = null;
    (window.document.querySelector(
      'ion-app',
    ) as HTMLElement).classList.remove('cameraView');
    this.qrScanner.hide();
    this.qrScanner.destroy();
  }

  ionViewWillEnter() {
    this.scan();
  }

  ionViewWillLeave() {
    this.stopScanning();
  }

  openDialog(content: string, qrCodeValue?: string) {
    const action = {
      type: 'openDialog',
      msg: content,
      props: {
        qrCodeValue,
      },
    };
    this.overlayService.setAction(action);
  }
}
