import { Component, OnInit, Input } from '@angular/core';

import { MatSelectChange } from '@angular/material/select';
import { ClipboardService } from 'ngx-clipboard';

import { Currency } from 'src/app/models/Currency.model';
import { Deposit } from 'src/app/models/Deposit.model';

import { OverlayService } from 'src/app/services/overlay.service';
import { DataService } from 'src/app/services/data.service';
import { AuthService } from 'src/app/services/auth.service';
import { AddressesService } from 'src/app/services/addresses.service';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss'],
})
export class DepositComponent implements OnInit {
  assets: Currency[];
  currentUserID: string;
  selectedCoin: string;
  address: string;
  deposits: Deposit[];
  whatsAppLink: string;
  displayWppLink: boolean;

  isLoading = false;
  failedToGenAddress = false;
  hasAddress = false;
  loadingDeposit = false;
  coinIsSelected = false;

  constructor(
    private authService: AuthService,
    private clipboardService: ClipboardService,
    private overlayService: OverlayService,
    private dataService: DataService,
    private addressesService: AddressesService,
    private translate: TranslateService,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.currentUserID = this.authService.getCurrentUserID();
    this.assets = this.dataService.getAssets();
  }

  coinWasSelected(evt: MatSelectChange) {
    this.coinIsSelected = true;
    this.displayWppLink = false;
    this.dataService.saveSelectedCoin(this.selectedCoin);
    this.getDeposits();

    // TODO: create a Behavior Subject for this, in the Data.service (other components "listen" to it)
    this.getAddress();
  }

  getDeposits() {
    this.loadingDeposit = true;
    this.deposits = [];
    this.dataService
      .getDeposits(this.currentUserID, this.selectedCoin)
      .subscribe((deposits) => {
        this.deposits = deposits.sort(
          (a, b) => b.created_at - a.created_at,
        );
        this.loadingDeposit = false;
      });
  }

  getAddress() {
    this.address = this.translate.instant('deposit.waitCreating');
    this.hasAddress = false;
    this.dataService
      .getAddress(this.currentUserID, this.selectedCoin)
      .then((data) => {
        if (data) {
          this.address = data.default;
          this.hasAddress = true;
          this.generateWhatsAppLink();
        } else {
          this.createAddress();
        }
      });
  }

  async createAddress() {
    this.address = this.translate.instant('deposit.waitCreating');
    this.hasAddress = false;
    (
      await this.addressesService.createAddress(this.selectedCoin)
    ).subscribe(
      (res) => {
        if (res.body) {
          this.address = res.body.address;
          this.hasAddress = true;
          this.failedToGenAddress = false;
          this.generateWhatsAppLink();
          console.log(`Address created: ${res.body.address}`);
        } else {
          this.failedToGenAddress = true;
          this.hasAddress = false;
        }
      },
      (_error) => {
        this.failedToGenAddress = true;
        this.hasAddress = false;
      },
    );
  }

  getCoinName(coinID: string) {
    const foundCoin = this.assets.find(
      (coin) => coin.symbol === coinID,
    );
    const coinName = foundCoin.name.toUpperCase();
    return coinName;
  }

  copyToService(coinID: string) {
    this.clipboardService.copyFromContent(this.address);
    this.showSnackBar('Copied to Clipboard!');
  }

  showSnackBar(message: string) {
    const action = {
      type: 'openSnackBar',
      msg: message,
    };
    this.overlayService.setAction(action);
  }

  getQRCode() {
    const baseURL =
      'https://chart.googleapis.com/chart?cht=qr&chs=150x150&chl=';
    return baseURL + this.address;
  }

  generateWhatsAppLink() {
    const whatsAppText = encodeURIComponent(
      `Olá! Você  uma *solicitação de depósito de $https://app.soya-coin.com/* na plataforma Soya.

      Você pode efetuar o depósito para o seguinte endereço: 
      
      ${this.address}`,
    );
    if(this.platform.is('ios')) {
      this.whatsAppLink = `https://wa.me/?text=${whatsAppText}`;
    } else {
      this.whatsAppLink = `https://api.whatsapp.com/send?phone=&text=${whatsAppText}&source=&data=`;
    }
    
    this.displayWppLink = true;
  }

  openWhatsAppLink(url: string) {
    window.open(url, '_blank');
  }
}
