import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { OverlayMessage } from '../models/OverlayMessage.model';

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  private msgSource = new Subject<OverlayMessage>();
  currentMsg = this.msgSource.asObservable();

  constructor() {}

  setAction(data: any) {
//    console.log(`overlay services|setAction|data.type: ${data.type}`)
    this.msgSource.next(data)
//    console.log(this.msgSource.next(data))
  }
}
