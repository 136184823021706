import { DialogData } from './../../models/DialogData.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { ZXingScannerComponent } from '@zxing/ngx-scanner';

import { Currency } from 'src/app/models/Currency.model';
import { DataService } from 'src/app/services/data.service';
import { CustomUser } from 'src/app/models/CustomUser.model';
import { OverlayService } from 'src/app/services/overlay.service';
import { AuthService } from 'src/app/services/auth.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Platform } from '@ionic/angular';
import { Route } from '@angular/compiler/src/core';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss'],
})
export class PayComponent implements OnInit, OnDestroy {
  @Input() qrCode?: any;
  @Output() close = new EventEmitter();
  assets: Currency[];
  quotes: any;
  currentUserID: string;
  invoiceCode: string;
  paymentStep: 'enterCode' | 'confirmPayment' | 'receipt';
  curRate: number;
  payFormGroup: FormGroup;
  isAllowed = true;
  userData: CustomUser;

  // TODO: must get this from DB, after code input...
  invoicePayee: string = '';
  invoiceName: string = '';
  minAmount: number = 0.00001;
  pctFee: number;
  payCoin: string;
  remainingAmount: number;
  paymentAmount: number;
  usdAmount: number;
  payDate = new Date();
  paySubscription: any;
  invoiceSubscription: any;
  isPublicInvoice: boolean;
  amountToPay = 0;
  receiptMsg: string;
  whatsAppLink: string;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  @ViewChild(ZXingScannerComponent, { static: false })
  scanner: ZXingScannerComponent;
  showCam = false;

  isMobile: boolean;

  constructor(
    public dialogRef: MatDialogRef<PayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
    private dataService: DataService,
    private invoiceService: InvoiceService,
    private overlayService: OverlayService,
    private translate: TranslateService,
    private clipboardService: ClipboardService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private platform: Platform,
  ) {}

  ngOnInit() {
    console.log("Passou 0")
    this.setUpForms();
    this.currentUserID = this.authService.getCurrentUserID();
    this.assets = this.dataService.getAssets();
    this.quotes = this.dataService.getQuotes();
    console.log("Passou 1")
    this.reset();
    console.log("Passou 2")

    if (this.platform.is('cordova')) {
      this.isMobile = true;
      if (this.qrCode) {
        this.invoiceCode = this.qrCode.qrCodeValue;
      }
    }
    console.log("Passou 3")

    console.log('Approved!', this.userData.kyc.status)
    console.log('userDataNew:', this.userData)

    if (this.userData.kyc.status === 'APPROVED') {
      this.isAllowed = true;
      console.log('Approved!', this.userData.kyc.status)
    } else {
      this.isAllowed = false;
      console.log('NOT Approved!', this.userData.kyc.status)
    }

  }

  ngOnDestroy(): void {
    if (this.paySubscription) {
      this.paySubscription.unsubscribe();
    }
    if (this.invoiceSubscription) {
      this.invoiceSubscription.unsubscribe();
    }
  }

  // FORM STUFF
  setUpForms() {
    this.payFormGroup = new FormGroup({
      collect: new FormControl(''),
    });

    this.onReceiveChanges();
  }

  // Getters for the form fields
  get collect() {
    return this.payFormGroup.get('collect');
  }

  onReceiveChanges(): void {
    this.payFormGroup.valueChanges.subscribe((data) => {
      console.log(`collect amount typed: }`, data.collect);
      this.paymentAmount = data.collect;
      this.updateUSDAmount();
    });
  }

  evaluateInput(value: number) {
    if (value <= 0) {
      this.paymentAmount = this.minAmount;
      this.updateUSDAmount();
      // console.log('value is less than minimum');
    }
  }

  // WEBCAM stuff / Start =====

  startScan() {
    this.invoiceCode = '';
    this.showCam = true;
  }

  scanSuccessHandler(evt: EventEmitter<void>) {
    this.invoiceCode = evt.toString();
    // console.log(this.invoiceCode);
    this.scanner.enable = false;
    this.showCam = false;
  }

  scanErrorHandler(evt: EventEmitter<void>) {
    console.log(evt);
  }

  camerasFoundHandler(evt: MediaDeviceInfo[]) {
    this.availableDevices = evt;
    if (this.availableDevices && this.availableDevices.length > 0) {
      this.currentDevice = this.availableDevices[0];
    }
    this.scanner.tryHarder = true;
    console.log(evt);
  }

  camerasNotFoundHandler(evt: EventEmitter<void>) {
    console.log(evt);
  }
  // WEBCAM stuff / End =====

  getInvoiceInfo() {
    this.setProgressBar('show');
    this.invoiceSubscription = this.invoiceService
      .getInvoice(this.invoiceCode)
      .subscribe(
        (invoice) => {
          if (!invoice.open) {
            this.setProgressBar('hide');
            this.showSnackBar('This invoice was already paid');
            return;
          }
          this.payCoin = invoice.coin_id;
          this.invoiceName = invoice.name;
          this.isPublicInvoice = invoice.public;
          this.invoicePayee = invoice.payee;

          this.getFees().then((res) => {
            // console.log(res);
          });

          this.remainingAmount = invoice.public
            ? 100000
            : +invoice.remaning_amount;

          this.amountToPay = invoice.remaning_amount;
          this.calcRate(this.payCoin);
          this.updateUSDAmount();
          this.paymentStep = 'confirmPayment';
          this.setProgressBar('hide');
        },
        (error) => {
          this.setProgressBar('hide');
          this.showSnackBar(error);
        },
      );
  }

  async payInvoice() {
    this.setProgressBar('show');
    this.invoiceSubscription.unsubscribe();

    this.paySubscription = (
      await this.invoiceService.payInvoice(
        this.invoiceCode,
        this.paymentAmount,
      )
    ).subscribe(
      (res) => {
        // console.log(res);
        this.receiptMsg = this.getReceiptMsg();
        this.generateWhatsAppLink(this.receiptMsg);

        this.paymentStep = 'receipt';
        this.setProgressBar('hide');
        this.showSnackBar(
          this.translate.instant('pay.msgPaymentSuccess'),
        );
      },
      (reject) => {
        console.log(reject);
        this.setProgressBar('hide');
        for (const e of reject.error.error) {
          this.showSnackBar(e);
        }
      },
    );
  }

  reset() {
    this.paymentStep = 'enterCode';
    this.invoiceCode = '';
  }

  // METHODS FOR USER FEEDBACK
  setProgressBar(status: string) {
    const action = {
      type: 'setProgressBar',
      msg: status,
    };
    this.overlayService.setAction(action);
  }

  showSnackBar(message: string) {
    const action = {
      type: 'openSnackBar',
      msg: message,
    };
    this.overlayService.setAction(action);
  }

  getReceiptMsg(): string {
    const activeLang = this.translate.getDefaultLang();
    const payAmount = this.remainingAmount;
    if (activeLang === 'en') {
      return `Soyacoin receipt message: Your payment of
        ${payAmount}
        SOY to ${this.invoicePayee} in
        ${this.payDate} has been confirmed.`;
    } else {
      return `Recibo Soyacoin: Seu pagamento de
        ${payAmount}
        SOY para ${this.invoicePayee} na data de
        ${this.payDate.getDay()}/${this.payDate.getMonth()}/${this.payDate.getFullYear()} foi confirmado.`;
    }
  }

  async generateWhatsAppLink(msg: string) {
    const platformURL = await this.dataService.getPlatformURL();
    const whatsAppText = encodeURIComponent(this.getReceiptMsg());
    this.whatsAppLink = `https://api.whatsapp.com/send?phone=&text=${whatsAppText}&source=&data=`;
  }

  openWhatsAppLink(url: string) {
    window.open(url, '_blank');
  }

  copyToService(text: string) {
    this.clipboardService.copyFromContent(text);
    this.showSnackBar(this.translate.instant('pay.msgClipboard'));
  }

  calcRate(coin: string) {
    if (coin === 'SOY') {
      this.curRate = 1;
    } else {
      const rates = this.getQuote(coin).rates;
      this.curRate = rates.USD;
    }
    // console.log(`calcRate|curRate: ${this.curRate}`);
  }

  getQuote(baseCoin: string) {
    const quote = this.quotes.find((el) => el.base === baseCoin);
    return quote;
  }

  getCoin(coinID: string) {
    const foundCoin = this.assets.find(
      (coin) => coin.symbol === coinID,
    );
    return foundCoin;
  }

  async getFees() {
    await this.dataService
      .getUserSpecificFees('SOY', this.currentUserID)
      .then((userFees) => {
        if (userFees && userFees.invoice_fee !== undefined) {
          this.pctFee = userFees.invoice_fee;
          console.log(`user specific invoice fee: ${this.pctFee}`);
        } else {
          this.pctFee = this.getCoin('SOY').invoice_fee;
          console.log(
            `user specific invoice fee NOT FOUND, use standard fee: ${this.pctFee}`,
          );
        }
      });
  }

  updateUSDAmount() {
    this.usdAmount = this.remainingAmount * this.curRate;
  }

  getCoinName(coinID: string) {
    const foundCoin = this.assets.find(
      (coin) => coin.symbol === coinID,
    );
    return foundCoin.name;
  }

  openQrScanner() {
    this.dialogRef.close();
    this.router.navigate(['qr-scanner/pay']);
    setTimeout(() => {
      (<HTMLMetaElement>(
        document.querySelector('meta[name="color-scheme"]')
      )).content = 'light';
    }, 50);
  }
}
