<div class="container">
  <main class="main-area">
    <div class="card card-info">
      <div class="infoblock">
        <a mat-button color="primary" routerLink="/main/dashboard">{{
          'kyc.leftSideLink' | translate | uppercase
        }}</a>
      </div>

      <div class="infoblock">
        <h3 class="heading-secondary card-title">
          {{ 'kyc.leftSideGreeting' | translate | uppercase }}
        </h3>
        <p>
          {{ 'kyc.leftSideText' | translate }}
        </p>
      </div>

      <!-- Area informing 2FA -->
      <div class="download infoblock">
        <h3 class="heading-secondary card-title">
          {{ 'two_factor.TwoFAMessageTitle' | translate }}
        </h3>
        <p class="download__text mb-md">
          {{ 'two_factor.TwoFAMessageText' | translate }}
        </p>
        <p *ngIf="userData">STATUS: {{ userData.has_2fa ? 'ACTIVE' : 'INACTIVE'}}</p>
        <button
          mat-flat-button
          color="accent"
          (click)="openDialog('2fa')"
          [disabled]="isLoading"
        >
          {{ 'two_factor.TwoFAMessageButton' | translate }}
        </button>
      </div>

      <!-- Area to download userdata on pdf -->
      <div *ngIf="!isMobile" class="download infoblock">
        <h3 class="heading-secondary card-title">
          {{ 'kyc.downPDFTitle' | translate }}
        </h3>
        <p class="download__text mb-md">
          {{ 'kyc.downPDFText' | translate }}
        </p>
        <button
          mat-flat-button
          color="accent"
          (click)="downloadAsPDF()"
          [disabled]="isLoading"
        >
          {{ 'kyc.downPDFButton' | translate }}
        </button>
        <p *ngIf="isLoading">Gathering all data...</p>
      </div>

      <!-- Area to download userdata on pdf -->
      <div class="infoblock">
        <h3 class="heading-secondary card-title">
          {{ 'kyc.excludeTitle' | translate }}
        </h3>
        <p class="download__text mb-md">
          {{ 'kyc.excludeText' | translate }}
        </p>
        <button
          mat-flat-button
          color="warn"
          (click)="openDialog('dataexclusion')"
        >
          {{ 'kyc.excludeButton' | translate }}
        </button>
      </div>
    </div>

    <!-- KYC FORM -->
    <div class="card card-form">
      <app-kyc *ngIf="userData" [(userData)]="userData"></app-kyc>
    </div>
  </main>
</div>
