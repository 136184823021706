import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { Subject } from 'rxjs';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-two-factor-form',
  templateUrl: './two-factor-form.component.html',
  styleUrls: ['./two-factor-form.component.scss'],
})
export class TwoFactorFormComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Output() onCodeSubmitted: EventEmitter<any> = new EventEmitter<
    any
  >();
  @Input() invalid2FACode: boolean;
  @Input() loading: boolean;
  currentUserID: string;
  code: number;
  // invalid2FACode: boolean = false;

  constructor(private authService: AuthService, public platform: Platform) {}

  ngOnChanges() {
    console.log(this.invalid2FACode);
  }

  ngOnInit() {
    this.currentUserID = this.authService.getCurrentUserID();
  }

  async ngAfterViewInit() {}

  emitCode() {
    this.onCodeSubmitted.emit(String(this.code));
  }

  keyPressed(event: any) {
    if(event.target.value.length === 6) {
      this.emitCode();
    }
  }

  // async submitCode() {
  //   const code = String(this.code.value);
  //   const secret = this.totpSecret;
  //   this.setProgressBar('show');
  //   (await this.authService.link2FA(secret, code)).toPromise().then(
  //     (reponse) => {
  //       this.setProgressBar('hide');
  //       this.showSnackBar('Two Factor Auth Linked!');
  //       this.close.emit();
  //     },
  //     (reject) => {
  //       this.setProgressBar('hide');
  //       console.log(reject);
  //       this.showSnackBar(reject.error.error[0]);
  //     },
  //   );
  // }

  ngOnDestroy() {}
}
