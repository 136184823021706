<div class="container" [style.visibility]="showUI ? 'visible' : 'hidden'">
  <div class="spacer">
    <app-lang-switch class="lang-switcher"></app-lang-switch>
  </div>


  <app-two-factor-form [ngClass]="{
      'form-container': true,
      twoFAForm: true,
      visible: display2FAForm,
      hidden: !display2FAForm
    }" [invalid2FACode]="invalid2FACode" [loading]="loading" (onCodeSubmitted)="get2FACode($event)">
  </app-two-factor-form>

  <div [ngClass]="{
      'form-container': true,
      visible: !display2FAForm,
      hidden: display2FAForm
    }">
    <h2 class="heading-secondary form-title">{{ 'login.title' | translate | uppercase }}</h2>
    <form class="form" [style.fontSize.px]="16" [formGroup]="loginFormGroup">
      <mat-form-field class="form__field" color="primary">
        <mat-label>{{ 'login.emailFormTitle' | translate }}</mat-label>
        <input class="form__input" matInput placeholder="" [formControl]="userEmail" type="email" required />
        <mat-error *ngIf="userEmail.invalid">
          {{ getEmailErrorMsg() }}
        </mat-error>
      </mat-form-field>


      <mat-form-field class="form__field" color="primary">
        <mat-label>{{ 'login.pwdFormTitle' | translate }}</mat-label>
        <input class="form__input" matInput placeholder="Type your password" [formControl]="userPwd"
          [type]="hidePwd ? 'password' : 'text'" required />
        <button class="showpwd-btn" mat-icon-button matSuffix type="button" (click)="hidePwd = !hidePwd"
          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePwd">
          <mat-icon>{{ hidePwd ? 'visibility' : 'visibility_off' }}</mat-icon>
        </button>
        <mat-error *ngIf="userPwd.invalid">
          {{ getPwdErrorMsg() }}
        </mat-error>
      </mat-form-field>

      <!-- <div id="login-recaptcha-container"></div> -->

      <!--Click was onSubmit-->
      <div class="confirm">
        <app-button-loading-spinner [loading]="loading" [buttonContainer]="true"
          [ngClass]="{ mobile: platform.is('mobile') }">
          <button mat-flat-button id="login-button" class="confirm confirm__btn" type="submit"
            (click)="doLogin();"
            [disabled]="!userPwd.valid || !userEmail.valid" color="primary">
            {{ 'login.button1' | translate | uppercase }}
          </button>
        </app-button-loading-spinner>
      </div>
      <div class="g-recaptcha" *ngIf="gRecaptchaKey" [attr.data-sitekey]="gRecaptchaKey"></div>
    </form>
    <div class="pwd-forgot">
      <div class="pwd-forgot__wrapper">
        <a routerLink="/auth/forgot-password" class="confirm confirm__forgot--link">{{ 'login.link1' | translate }}</a>
      </div>
    </div>


    <mat-card class="example-card" *ngIf="displayUserBlockedMessage">
      <mat-card-content>
        <p>
          This user has been blocked from the platform.
        </p>
      </mat-card-content>
    </mat-card>

    <p class="error-msg" *ngIf="showErrMsg" [style.visibility]="showErrMsg ? 'visible' : 'hidden'">
      {{ 'login.mainError' | translate }}
    </p>
  </div>

  <div class="signup">
    <p class="signup__text">
      {{ 'login.question' | translate }}
    </p>
    <button mat-stroked-button class="mh-sm" (click)="signup()" color="primary">
      {{ 'login.button2' | translate | uppercase }}
    </button>
  </div>
</div>
