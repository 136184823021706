<div class="form-container twoFAForm">
  <h2 class="heading-secondary form-title">
    Two Factor Authentication
  </h2>

  <mat-form-field class="form__field" color="primary">
    <mat-label>Validation Code</mat-label>
    <input matInput name="code" class="validation__input" placeholder="Insert the code received on your phone"
      [(ngModel)]="code" type="number" (keyup)="keyPressed($event)" />
  </mat-form-field>
  <p class="error" *ngIf="invalid2FACode">
    {{ 'two_factor.invalid_code' | translate }}
  </p>

  <div class="confirm">
    <app-button-loading-spinner [loading]="loading" [buttonContainer]="true"
      [ngClass]="{ mobile: platform.is('android') }">
      <button mat-flat-button type="button" color="primary" id="secondFA-button" class="confirm__btn mb-lg" (click)="emitCode()">
        VALIDATE
      </button>
    </app-button-loading-spinner>
  </div>
</div>