import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { MatSelectChange } from '@angular/material/select';
import { ClipboardService } from 'ngx-clipboard';

import { Currency } from 'src/app/models/Currency.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { InvoiceService } from 'src/app/services/invoice.service';

@Component({
  selector: 'app-receive',
  templateUrl: './receive.component.html',
  styleUrls: ['./receive.component.scss'],
})
export class ReceiveComponent implements OnInit, OnDestroy {
  @Input() invoice?: any;

  assets: Currency[];
  quotes: any;
  currentUserID: string;

  receiveFormGroup: FormGroup;

  receiveCoin = 'SOY';
  curRate: number;
  minAmount = 0.2;
  pctFee: number;
  stepIncrement = 0.01;
  receiveAmount: number;
  totalAmount: number;
  invoiceName: string = '';
  usdAmount: number;
  publicInvoice: boolean = false;

  invoiceCode;
  codeWasGenerated = false;
  whatsAppLink: string;

  invoiceSubscription: any;

  constructor(
    private authService: AuthService,
    private overlayService: OverlayService,
    private dataService: DataService,
    private invoiceService: InvoiceService,
    private clipboardService: ClipboardService,
  ) {}

  ngOnInit() {
    this.currentUserID = this.authService.getCurrentUserID();
    this.assets = this.dataService.getAssets();
    this.quotes = this.dataService.getQuotes();
    this.setUpForms();

    // TODO: get min_amount from DB ....
    if (this.invoice) {
      this.getFees().then((res) => {
        this.codeWasGenerated = true;
        this.receiveCoin = this.invoice.coin;
        this.invoiceCode = { invoice_channel: this.invoice.id };
        this.receiveAmount = this.invoice.amount;
        this.updateUSDAmount(); // show in UI, since the usdAmount by default is already 1
      });
    } else {
      this.receiveAmount = 100;
    }
    this.calcReceiveRate(this.receiveCoin);
    this.updateUSDAmount(); // show in UI, since the usdAmount by default is already 1
  }

  ngOnDestroy(): void {
    if (this.invoiceSubscription) {
      this.invoiceSubscription.unsubscribe();
    }
  }

  // FORM STUFF
  setUpForms() {
    this.receiveFormGroup = new FormGroup({
      collect: new FormControl(''),
      name: new FormControl(''),
    });

    this.onReceiveChanges();
  }

  // Getters for the form fields
  get collect() {
    return this.receiveFormGroup.get('collect');
  }

  get name() {
    return this.receiveFormGroup.get('name');
  }

  onReceiveChanges(): void {
    this.receiveFormGroup.valueChanges.subscribe((data) => {
      // console.log(`collect amount typed: }`, data.collect);
      this.invoiceName = data.name;
      if (data.collect) {
        this.receiveAmount = data.collect;
      }
      this.updateUSDAmount();
    });
  }

  receiveCoinWasSelected(evt: MatSelectChange) {
    this.minAmount = 0.3;
    this.receiveAmount = this.minAmount;
    this.calcReceiveRate(evt.value);
    this.updateUSDAmount();
  }

  getMktSettings(source: string, target: string = 'SOY'): any {
    return new Promise((resolve, reject) => {
      this.dataService.getMarketSettings(source, target).subscribe(
        (data) => {
          resolve(data[0]);
        },
        (err) => reject(err),
      );
    });
  }

  evaluateInput(value: number) {
    if (value < this.minAmount) {
      this.receiveAmount = this.minAmount;
      this.updateUSDAmount();
      // console.log('value is less than minimum');
    }
  }

  async generateInvoice() {
    this.setProgressBar('show');
    this.invoiceCode = 'Generating code ...';
    this.invoiceSubscription = (
      await this.invoiceService.createInvoice(
        this.invoiceName,
        this.receiveCoin,
        Number(this.receiveAmount),
        this.publicInvoice,
      )
    ).subscribe(
      (res) => {
        console.log('generateInvoice(), result: ', res);
        this.invoiceCode = res.body;
        this.codeWasGenerated = true;
        this.getFees();
        this.generateWhatsAppLink();
        this.setProgressBar('hide');
        this.showSnackBar('Invoice created successfully');
      }, // success path
      (reject) => {
        console.log(reject);
        this.setProgressBar('hide');
        for (const e of reject.error.error) {
          this.showSnackBar(e);
        }
        console.log('generateInvoice(), error: ', reject);
      },
    ); // error path);
  }

  async generateWhatsAppLink() {
    const platformURL = await this.dataService.getPlatformURL();
    const whatsAppText = encodeURIComponent(
      `Olá!
      Você  uma solicitação de pagamento *SoyaCoin (SOY)* no valor de *${this.receiveAmount} SOY*.
      Para realizar esse pagamento acesse *${platformURL}* e utilize o *código de pagamento ${this.invoiceCode.invoice_channel}*
      `,
    );
    this.whatsAppLink = `https://api.whatsapp.com/send?phone=&text=${whatsAppText}&source=&data=`;
  }

  openWhatsAppLink(url: string) {
    window.open(url, '_blank');
  }

  resetPayment() {
    this.codeWasGenerated = false;
  }

  updateUSDAmount() {
    this.usdAmount = this.receiveAmount * this.curRate;
  }

  calcReceiveRate(coin: string) {
    if (coin === 'SOY') {
      this.curRate = 1;
    } else {
      const rates = this.getQuote(coin).rates;
      this.curRate = rates.USD;
    }
    // console.log(`calcReceiveRate|curRate: ${this.curRate}`);
  }

  getQuote(baseCoin: string) {
    const quote = this.quotes.find((el) => el.base === baseCoin);
    return quote;
  }

  getCoin(coinID: string) {
    const foundCoin = this.assets.find(
      (coin) => coin.symbol === coinID,
    );
    return foundCoin;
  }

  async getFees() {
    await this.dataService
      .getUserSpecificFees('SOY', this.currentUserID)
      .then((userFees) => {
        if (userFees && userFees.invoice_fee !== undefined) {
          this.pctFee = userFees.invoice_fee;
          console.log(`user specific invoice fee: ${this.pctFee}`);
        } else {
          this.pctFee = this.getCoin('SOY').invoice_fee;
          console.log(
            `user specific invoice fee NOT FOUND, use standard fee: ${this.pctFee}`,
          );
        }
      });
  }

  getCoinName(coinID: string) {
    const foundCoin = this.assets.find(
      (coin) => coin.symbol === coinID,
    );
    return foundCoin.name;
  }

  copyToService(coinID: string) {
    this.clipboardService.copyFromContent(
      this.invoiceCode.invoice_channel,
    );
    this.showSnackBar('Copied to Clipboard!');
  }

  getQRCode() {
    const baseURL =
      'https://chart.googleapis.com/chart?cht=qr&chs=150x150&chl=';
    return baseURL + this.invoiceCode.invoice_channel;
  }

  // METHODS FOR USER FEEDBACK
  setProgressBar(status: string) {
    const action = {
      type: 'setProgressBar',
      msg: status,
    };
    this.overlayService.setAction(action);
  }

  showSnackBar(message: string) {
    const action = {
      type: 'openSnackBar',
      msg: message,
    };
    this.overlayService.setAction(action);
  }
}
