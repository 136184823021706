import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, first } from 'rxjs/operators';

import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';

import { CustomUser } from '../models/CustomUser.model';
import { AuthGuardService } from '../guards/auth-guard.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  usersCollection: AngularFirestoreCollection<CustomUser>;
  userDoc: AngularFirestoreDocument<CustomUser>;
  currentUserObs: Observable<CustomUser>;
  currentUserDisplayName: string;
  currentUserID: string;
  currentUser: CustomUser;
  currentAdminRole = 'USER';

  baseURL = environment.baseURL;

  constructor(
    private afAuth: AngularFireAuth,
    private authGuardService: AuthGuardService,
    private afs: AngularFirestore,
    private http: HttpClient,
  ) {
    this.usersCollection = this.afs.collection('/users');
  }

  getAuth() {
    return this.afAuth.authState.pipe(map((auth) => auth));
  }

  async getUserToken() {
    return await (await this.afAuth.currentUser).getIdToken();
  }

  getUser() {
    return this.afAuth.currentUser;
  }

  async getAuthHeaders(): Promise<Object> {
    const token = await this.getUserToken();
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'Application/Json',
        Authorization: token,
      }),
    };
    // headers.headers.append('Authorization', token);
    return headers;
  }

  setAdminRole(status: string) {
    this.currentAdminRole = status;
  }

  getAdminRole(): string {
    return this.currentAdminRole;
  }

  applyUserCredentials(userData) {
    this.saveUserID(userData.user.uid);
    return new Promise((resolve, reject) => {
      this.getUserFromDB(userData.user.uid).then(
        (user) => {
          // console.log(`getUserFromDB successfull:`, user);
          this.saveUserName(user.kyc.personal_info.full_name);
          this.setAdminRole(user.role);
          this.saveCurrentUser(user);
          this.saveAuthStatus(true);
          resolve(userData);
        },
        (err) => reject(err),
      );
    });
  }

  async validateTokenOnBackend(token: string, two_factor_code?: string) {
    const URL = `${this.baseURL}/Auth/validate_token/`;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'Application/Json',
      }),
    };

    return this.http.post(URL, JSON.stringify({ token, two_factor_code }), headers);
  }

  // start MAIN AUTH METHODS -------------------
  async login(email: string, pwd: string, two_factor_code?: string) {
    return new Promise(async (resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(email, pwd).then(
        async (userData) => {
          // console.log(userData);
          const token = await (await this.afAuth.currentUser).getIdTokenResult();
          (await this.validateTokenOnBackend(token.token, two_factor_code))
            .toPromise()
            .then(
              (success) => {
                this.applyUserCredentials(userData).then(() => {
                  resolve(userData);
                }),
                  (error) => reject(error);
              },
              async (error) => {
                const errorResponse = await(error);
                reject({ code: errorResponse.error.error[0] });
              },
            );
          // after login, lets save the user data (var and localStorage)
        },
        (err) => reject(err),
      ),
        (err) => reject(err);
    });
  }

  // API (Firebase Cloud Functions) stuff
  async saveLoginDataViaAPI(
    user: CustomUser,
  ): Promise<Observable<any>> {
    const registerURL = `${this.baseURL}/Profile/signup/`;
    const userInfo = {
      email: user.kyc.personal_info.email,
      full_name: user.kyc.personal_info.full_name,
    };
    const httpOptions = await this.getAuthHeaders();
    return this.http.post<CustomUser>(
      registerURL,
      JSON.stringify(userInfo),
      httpOptions,
    );
  }

  async getMultiFactorSession() {
    const user = this.afAuth.currentUser;
    return (await user).multiFactor.getSession().then(
      (multiFactorSession) => {
        return multiFactorSession;
      },
      (error) => {
        console.log(error);
        return error;
      },
    );
  }

  register(user: CustomUser, pwd: string) {
    // console.log('register/signup this user:', user);
    return new Promise((resolve, reject) => {
      this.afAuth
        .createUserWithEmailAndPassword(
          user.kyc.personal_info.email,
          pwd,
        )
        .then(
          async (fbUser) => {
            // gets UID after registration in Firebase, saves id, name, etc.
            const registeredUser = this.afAuth.currentUser;
            this.saveUserID((await registeredUser).uid);

            this.saveUserName(user.kyc.personal_info.full_name);
            this.saveCurrentUser(user);

            // since the auth token is needed
            this.afAuth
              .signInWithEmailAndPassword(
                user.kyc.personal_info.email,
                pwd,
              )
              .then(
                async () => {
                  (await this.saveLoginDataViaAPI(user)).subscribe(
                    (res) => {
                      // console.log('saveLoginDataViaAPI completed');
                      localStorage.setItem(
                        'currentUser',
                        JSON.stringify(user),
                      );

                      this.saveAuthStatus(true);

                      // Now, send the e-mail verification
                      this.sendEmailVerification().then(
                        () => {
                          console.log('Confirmation e-mail was sent');
                          resolve(fbUser);
                        },
                        (err) => {
                          console.log(err);
                          reject(err);
                        },
                      );
                    },
                    (err) => {
                      console.log(err);
                      reject(err);
                    },
                  );
                },
                (err) => {
                  console.log(err);
                  reject(err);
                },
              );
          },
          (err) => {
            console.log(err);
            reject(err);
          },
        );
    });
  }

  async sendEmailVerification() {
    const user = this.afAuth.currentUser;
    await (await user).sendEmailVerification();
  }

  async sendResetPwdEmail(email: string) {
    await this.afAuth
      .sendPasswordResetEmail(email)
      .then((res) => {
        // console.log('sendResetPwdEmail, OK result: ', res);
      })
      .catch((error) => {
        return error;
      });
  }

  async getUserEmailConfStatus() {
    const user = this.afAuth.currentUser;
    return user ? (await user).emailVerified : null;
  }

  async logOut() {
    await this.afAuth.signOut().then(() => {
      this.authGuardService.isAuthenticated = false;
      localStorage.removeItem('currentUserDisplayName');
      localStorage.removeItem('currentUserID');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('assetsData');
      // window.location.reload();
    });
  }
  // end MAIN AUTH METHODS -------------------

  saveCurrentUser(user: CustomUser) {
    this.currentUser = user;
    localStorage.setItem(
      'currentUser',
      JSON.stringify(this.currentUser),
    );
  }

  saveUserName(name: string) {
    // console.log(`saveUserName: ${name}`);
    this.currentUserDisplayName = name;
    localStorage.setItem(
      'currentUserDisplayName',
      this.currentUserDisplayName,
    );
  }

  saveUserID(id: string) {
    // console.log(`saveUserID: ${id}`);
    this.currentUserID = id;
    localStorage.setItem('currentUserID', this.currentUserID);
  }

  saveAuthStatus(status: boolean) {
    let statusStr: string;
    status === true ? (statusStr = 'true') : (statusStr = 'false');
    this.authGuardService.isAuthenticated = status;
  }

  userIsAdmin() {
    // TO-DO: must verify if this is working when user reloads the dashboard page
    const curUser = JSON.parse(localStorage.getItem('currentUser'));
    return curUser ? curUser.is_admin : false;
  }

  setCurrentUser() {
    if (localStorage.getItem('currentUser') != null) {
      this.currentUser = JSON.parse(
        localStorage.getItem('currentUser'),
      );
      // console.log('localStorage User', this.currentUser);
    } else {
      this.getUserFromDB(this.currentUserID).then(
        (user) => (this.currentUser = user),
      );
      // console.log('DB User', this.currentUser);
    }
  }

  getUserDisplayName() {
    if (localStorage.getItem('currentUserDisplayName') != null) {
      this.currentUserDisplayName = localStorage.getItem(
        'currentUserDisplayName',
      );
    }
    return this.currentUserDisplayName;
  }

  getCurrentUserID() {
    if (localStorage.getItem('currentUserID') != null) {
      this.currentUserID = localStorage.getItem('currentUserID');
    }
    return this.currentUserID;
  }

  get userRole() {
    const currentUser = localStorage.getItem('currentUser');
    return currentUser ? JSON.parse(currentUser).role : 'USER';
  }

  // ------ Firebase Stuff -------
  saveUserDatainDB(user: CustomUser, uid: string) {
    // console.log('auth.service|saveUserDatainDB, user', user);
    this.usersCollection
      .doc(uid) // this way it creates a DB entry with the same UI of the auth user
      .set(user)
      .then((docRef) => {
        console.log('user data saved in DB');
      })
      .catch((error) => {
        console.log('ERROR saving user data in DB');
      });
  }

  getUserFromDB(uid: string): Promise<CustomUser> {
    return new Promise((resolve, reject) => {
      this.usersCollection
        .doc(uid)
        .get()
        .subscribe(
          (userDoc) => {
            const data = userDoc.data() as any;
            data.totp_secret = null;
            // if (data.kyc.personal_info.dob) {
            //   data.kyc.personal_info.dob = data.kyc.personal_info.dob.toDate(); // Firebase returns a Timestamp...
            // }
            resolve(data);
          },
          (err) => reject(err),
        );
    });
  }

  // this gets the user as an Observable
  getUserFromDbAsObs(id: string): Observable<CustomUser> {
    this.userDoc = this.afs.doc<CustomUser>(`users/${id}`);
    this.currentUserObs = this.userDoc.snapshotChanges().pipe(
      map((action) => {
        if (action.payload.exists === false) {
          return null;
        } else {
          const data = action.payload.data() as CustomUser;
          data.uid = action.payload.id;
          // if (data.kyc.personal_info.dob) {
          //   data.kyc.personal_info.dob = data.kyc.personal_info.dob.toDate(); // Firebase returns a Timestamp...
          // }
          return data;
        }
      }),
    );
    return this.currentUserObs
  }

  getUsers(): Observable<any[]> {
    const usersCollection = this.afs.collection('/users', (ref) =>
      ref.orderBy('kyc.status'),
    );
    const users = usersCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as any;
          data.uid = action.payload.doc.id;
          return data;
        });
      }),
    );
    return users;
  }

  getUsersCount(): Promise<number> {
    const countersCollection = this.afs.collection(
      '/statistics/collections/counters',
    );
    return new Promise((resolve, reject) => {
      countersCollection
        .doc('users')
        .get()
        .subscribe(
          (userDoc) => {
            const data = userDoc.data() as any;
            resolve(data.documents);
          },
          (err) => reject(err),
        );
    });
  }

  async getTOTPSecret(): Promise<any> {
    const totpURL = `${this.baseURL}/Auth/2fa/generate_secret/`;
    const httpOptions = await this.getAuthHeaders();

    return this.http.post(totpURL, JSON.stringify({}), httpOptions);
  }

  async link2FA(secret: string, code: string): Promise<any> {
    const URL = `${this.baseURL}/Auth/2fa/validate_secret/`;
    const httpOptions = await this.getAuthHeaders();

    return this.http.post(
      URL,
      JSON.stringify({ secret, code }),
      httpOptions,
    );
  }
}
