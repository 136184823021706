import { Component, OnInit } from '@angular/core';

import { Logger } from 'src/app/models/Logger.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  userID: string;
  log: Logger[];
  loading: boolean;

  constructor(
    private authService: AuthService,
    private dataService: DataService,
  ) {}

  ngOnInit() {
    this.userID = this.authService.getCurrentUserID();
    this.loading = false;
    this.getLog();
  }

  getLog() {
    this.loading = true;
    this.dataService.getUserLog(this.userID).subscribe((data) => {
      this.loading = false;
      this.log = data;
      this.log.forEach((item) => {
        if (item.created_at) {
          item.created_at = item.created_at.toDate();
        }
      });
      this.log.sort(this.sortByCreatedAt);
      // console.log(this.log);
    });
  }

  sortByCreatedAt(a, b) {
    return b.created_at - a.created_at;
  }
}
