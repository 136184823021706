import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, first, take } from 'rxjs/operators';

import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';

import firebase from 'firebase/app';
import { CustomUser } from '../models/CustomUser.model';
import { Deposit } from '../models/Deposit.model';
import { MarketOrder } from 'src/app/models/MarketOrder.model';
import { Withdrawal } from '../models/Withdrawal.model';
import { Currency } from '../models/Currency.model';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { Logger } from '../models/Logger.model';
import { LoggerParser } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  assets: Currency[];
  balances: any[];
  quotes: any[];
  selectedCoin: string;

  // User data
  usersCollection: AngularFirestoreCollection<firebase.User>;
  userDoc: AngularFirestoreDocument<firebase.User>;
  currentUser: CustomUser;
  baseURL = environment.baseURL;
  platformURL = environment.platformURL;
  gatewayURL = environment.gatewayURL;
  network = environment.network;

  // --- QUOTES IN REALTIME STUFF
  private quotesSource = new Subject<any>();
  currentQuotesMsg = this.quotesSource.asObservable();

  constructor(
    private afs: AngularFirestore,
    private http: HttpClient,
    private authService: AuthService,
  ) {
    this.usersCollection = this.afs.collection('/users');
  }

  getNetwork() {
    return this.network;
  }

  async getPlatformURL() {
    return this.platformURL;
  }

  async updateUserData(id: string, data: any) {
    console.log('data.service|updateUserData|data:', data);
    // TO-DO: must update fullName in the user profile (Firebase Authentication data)
    await this.usersCollection
      .doc(id)
      .update(data)
      .then(() => {
        // console.log('user updated');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async saveKYCDataViaAPI(
    userData: CustomUser,
  ): Promise<Observable<any>> {
    const kycURL = `${this.baseURL}/KYC/submit`;
    const kycInfo = {
      address: userData.kyc.address,
      personal_info: userData.kyc.personal_info,
    };
    const httpOptions = await this.authService.getAuthHeaders();

    return this.http.post<any>(
      kycURL,
      JSON.stringify(kycInfo),
      httpOptions,
    );
  }

  async updateUserPhoneNumber(): Promise<Observable<any>> {
    const kycURL = `${this.baseURL}/KYC/savePhoneNumber`;
    const httpOptions = await this.authService.getAuthHeaders();

    return this.http.put<any>(
      kycURL,
      JSON.stringify({}),
      httpOptions,
    );
  }

  async changeUserStatusViaAPI(
    targetUserId: string,
    status: string,
    rejectReason?: string,
  ): Promise<Observable<any>> {
    const statusURL = `${this.baseURL}/KYC/${status}`;
    const statusInfo = {
      target_user_id: targetUserId,
      reject_reason: rejectReason,
    };
    const httpOptions = await this.authService.getAuthHeaders();

    return this.http.post<any>(
      statusURL,
      JSON.stringify(statusInfo),
      httpOptions,
    );
  }

  async setUserAdminStatusViaAPI(
    userID: string,
    status: string,
  ): Promise<Observable<any>> {
    const changeAdminDataURL = `${this.baseURL}/Manager/roles/change`;
    const changeAdminData = {
      target_user_id: userID,
      role: status,
    };
    const httpOptions = await this.authService.getAuthHeaders();
    return this.http.post<any>(
      changeAdminDataURL,
      JSON.stringify(changeAdminData),
      httpOptions,
    );
  }

  async saveBuySOY(
    coinID: string,
    buyAmount: number,
  ): Promise<Observable<any>> {
    const buyURL = `${this.baseURL}/Market/Order/Market/${coinID}/SOY`;
    console.log(buyURL);
    const buyData = {
      amount: buyAmount,
      side: 'ASK',
    };
    const httpOptions = await this.authService.getAuthHeaders();
    return this.http.post<any>(
      buyURL,
      JSON.stringify(buyData),
      httpOptions,
    );
  }

  getBalancesFromDB(userID: string): Observable<any[]> {
    const balancesCollection = this.afs.collection(
      `/users/${userID}/balances`,
    );
    const userBalances = balancesCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action: any) => {
          const data = action.payload.doc.data() as any;
          data.id = action.payload.doc.id;
          return data;
        });
      }),
    );
    return userBalances;
  }

  async getWalletAddressFromDB(
    userID: string,
    asset: string,
  ): Promise<any> {
    const transactionsCollection = this.afs
      .collection(`/transactions/${userID}/${asset}`)
      .ref.limit(1);
    const addressData = await transactionsCollection.get();
    if (!addressData.empty) {
      const data = addressData.docs[0].data() as any;
      return { address: data.default ? data.default : 'N/A' };
    }
    return { address: 'N/A' };
  }

  getBalances() {
    return this.balances;
  }

  saveBalances(balances: any[]) {
    this.balances = balances;
  }

  getDeposits(
    userID: string,
    assetID: string,
  ): Observable<Deposit[]> {
    const depositsRef = this.afs.collection(
      `/transactions/${userID}/${assetID}/Operations/Deposits`,
    );
    const deposits = depositsRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action: any) => {
          const data = action.payload.doc.data() as any;
          data.id = action.payload.doc.id;
          data.asset = assetID;
          if (data.created_at) {
            data.created_at = data.created_at.toDate();
          }
          return data;
        });
      }),
    );
    return deposits;
  }

  // ADMIN Method, gets all users deposits (for the select asset)
  getUsersDeposits(assetID: string): Observable<Deposit[]> {
    const depositsRef = this.afs.collection(
      `/manager/${assetID}/Deposits`,
    );
    const deposits = depositsRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action: any) => {
          const data = action.payload.doc.data() as any;
          data.id = action.payload.doc.id;
          data.asset = assetID;
          if (
            data.created_at &&
            typeof data.created_at !== 'string'
          ) {
            data.created_at = data.created_at.toDate();
          }
          return data;
        });
      }),
    );
    return deposits;
  }

  getConfirmedDeposits(assetID: string): Promise<any> {
    const depositsRef = this.afs.collection(
      `/manager/${assetID}/Deposits`,
      (ref) => ref.where('status', '==', 'OK'),
    );
    const deposits = depositsRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action: any) => {
          const data = action.payload.doc.data() as any;
          data.id = action.payload.doc.id;
          data.asset = assetID;
          if (
            data.created_at &&
            typeof data.created_at !== 'string'
          ) {
            data.created_at = data.created_at.toDate();
          }
          return data;
        });
      }),
    );
    return deposits.pipe(first()).toPromise();
  }

  getASKPurchases(userID: string): Observable<MarketOrder[]> {
    const purchasesASKRef = this.afs.collection(
      `/market/BTC_SOY/ASK`,
      (ref) => ref.where('user_id', '==', userID),
    );
    const purchasesASK = purchasesASKRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as MarketOrder;
          data.id = action.payload.doc.id;
          if (
            data.created_at &&
            typeof data.created_at !== 'string'
          ) {
            data.created_at = data.created_at.toDate();
          }
          // console.log('getASKPurchases|data: ', data);
          return data;
        });
      }),
    );

    return purchasesASK;
  }

  getBIDPurchases(userID: string): Observable<MarketOrder[]> {
    const purchasesBIDRef = this.afs.collection(
      `/market/BTC_SOY/BID`,
      (ref) => ref.where('user_id', '==', userID),
    );
    const purchasesBID = purchasesBIDRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action: any) => {
          const data = action.payload.doc.data() as MarketOrder;
          data.id = action.payload.doc.id;
          if (
            data.created_at &&
            typeof data.created_at !== 'string'
          ) {
            data.created_at = data.created_at.toDate();
          }
          // console.log('getBIDPurchases|data: ', data);
          return data;
        });
      }),
    );

    return purchasesBID;
  }

  async blockUser(
    targetUserID: string,
    blockReason: string,
  ): Promise<Observable<any>> {
    const url = `${this.baseURL}/Manager/blockuser`;
    const data = {
      target_user_id: targetUserID,
      block_reason: blockReason,
    };
    const httpOptions = await this.authService.getAuthHeaders();

    return this.http.put<any>(url, JSON.stringify(data), httpOptions);
  }

  async unblockUser(targetUserID: string): Promise<Observable<any>> {
    const url = `${this.baseURL}/Manager/unblockuser`;
    const data = {
      target_user_id: targetUserID,
    };
    const httpOptions = await this.authService.getAuthHeaders();

    return this.http.put<any>(url, JSON.stringify(data), httpOptions);
  }

  getMarketSettings(
    baseCurrency: string,
    quoteCurrency: string,
  ): Observable<any> {
    const settingsRef = this.afs.collection(
      `/market/${baseCurrency}_${quoteCurrency}/settings`,
    );
    const mktSettings = settingsRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action: any) => {
          const data = action.payload.doc.data() as Withdrawal;
          data.id = action.payload.doc.id;
          return data;
        });
      }),
    );
    return mktSettings;
  }

  async saveMktSettings(data: any): Promise<Observable<any>> {
    const saveURL = `${this.baseURL}/Manager/trading_fees`;
    const httpOptions = await this.authService.getAuthHeaders();
    return this.http.put<any>(
      saveURL,
      JSON.stringify(data),
      httpOptions,
    );
  }

  async isPhoneNumberAlreadyRegistered(
    phone_number: string,
  ): Promise<Observable<any>> {
    const URL = `${this.baseURL}/Profile/checkPhoneNumber`;
    const httpOptions = await this.authService.getAuthHeaders();
    return this.http.put<any>(
      URL,
      JSON.stringify({ phone_number }),
      httpOptions,
    );
  }

  async saveAllFees(data: any): Promise<Observable<any>> {
    // asset: string,
    // bucket_fee: number,
    // invoice_fee: number,
    // fixed_withdrawal_fee: number,
    // variable_withdrawal_fee: number
    const saveURL = `${this.baseURL}/Manager/allfees`;
    const httpOptions = await this.authService.getAuthHeaders();
    return this.http.put<any>(
      saveURL,
      JSON.stringify(data),
      httpOptions,
    );
  }

  getUserSpecificFees(assetID: string, userID: string): Promise<any> {
    const userFeesCollection = this.afs.collection(
      `/assets/${assetID}/users_fees`,
    );
    return new Promise((resolve, reject) => {
      userFeesCollection
        .doc(userID)
        .get()
        .subscribe(
          (userDoc) => {
            resolve(userDoc.data() as any);
          },
          (err) => reject(err),
        );
    });
  }

  async saveUserSpecificFees(data): Promise<Observable<any>> {
    const saveURL = `${this.baseURL}/Manager/user/fees`;
    const httpOptions = await this.authService.getAuthHeaders();
    return this.http.put<any>(
      saveURL,
      JSON.stringify(data),
      httpOptions,
    );
  }

  async saveInvoiceBucketFees(data: any): Promise<Observable<any>> {
    const saveURL = `${this.baseURL}/Manager/invoicebucketfees`;
    const httpOptions = await this.authService.getAuthHeaders();
    return this.http.put<any>(
      saveURL,
      JSON.stringify(data),
      httpOptions,
    );
  }

  getAddress(userID: string, assetID: string): Promise<any> {
    const assetRef = this.afs.collection(
      `/transactions/${userID}/${assetID}`,
    );
    return new Promise((resolve, reject) => {
      assetRef
        .doc('Address')
        .get()
        .subscribe(
          (addressDoc) => {
            const data = addressDoc.data() as any;
            resolve(data);
          },
          (err) => reject(err),
        );
    });
  }

  async refreshWallet(): Promise<Observable<any>> {
    const URL = `${this.gatewayURL}/RescanAddress`;
    const data = {};
    const dataJSON = JSON.stringify(data);
    const httpOptions = await this.authService.getAuthHeaders();
    return this.http.post<any>(URL, dataJSON, httpOptions);
  }

  getUserLog(userID: string): Observable<any[]> {
    const logCollection = this.afs.collection(
      `/users/${userID}/logger`,
    );
    const userLog = logCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action: any) => {
          const data = action.payload.doc.data() as Logger;
          data.id = action.payload.doc.id;
          if (data.Message) {
            data.Message = LoggerParser(data.Message);
          }
          return data;
        });
      }),
    );
    return userLog;
  }

  getAccFees(): Observable<any[]> {
    const accFeesCollection = this.afs.collection(
      `/accumulated_fees/`,
    );
    const accFees = accFeesCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action: any) => {
          const data = action.payload.doc.data() as any;
          data.id = action.payload.doc.id;
          return data;
        });
      }),
    );

    return accFees;
  }

  getAccFeesByPeriod(
    startDate: Date,
    endDate: Date,
  ): Observable<any[]> {
    const accFeesCollection = this.afs.collection(
      `/accumulated_fees/`,
      (ref) =>
        ref
          .where('created_at', '>=', startDate)
          .where('created_at', '<=', endDate),
    );
    const accFees = accFeesCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action: any) => {
          const data = action.payload.doc.data() as any;
          data.id = action.payload.doc.id;
          return data;
        });
      }),
    );
    return accFees;
  }

  getFeesDocsCount(assetID: string): Promise<number> {
    const countersCollection = this.afs.collection(
      '/statistics/collections/counters',
    );
    return new Promise((resolve, reject) => {
      countersCollection
        .doc(`accumulated_fees-${assetID}`)
        .get()
        .subscribe(
          (counterDoc) => {
            const data = counterDoc.data() as any;
            resolve(data.documents);
          },
          (err) => reject(err),
        );
    });
  }

  async getAssetsAsPromise(): Promise<any> {
    const assetsRef = this.afs.collection('assets', (ref) =>
      ref.where('symbol', 'in', ['BTC', 'ETH', 'SOY']),
    );
    return await assetsRef.get().toPromise();
  }

  getCryptoAssetsFromDB(): Observable<Currency[]> {
    const assetsCollection = this.afs.collection(`/assets/`, (ref) =>
      ref.where('is_crypto', '==', true),
    );
    const assets = assetsCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action: any) => {
          const data = action.payload.doc.data() as Currency;
          data.id = action.payload.doc.id;
          return data;
        });
      }),
    );
    return assets;
  }

  getAssetsFromDB(): Observable<Currency[]> {
    const assetsCollection = this.afs.collection(`/assets/`);
    const assets = assetsCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action: any) => {
          const data = action.payload.doc.data() as Currency;
          data.id = action.payload.doc.id;
          return data;
        });
      }),
    );
    return assets;
  }

  saveAssetsInLocalStorage(assets: Currency[]) {
    const localAssets = localStorage.getItem('assetsData');
    if (localAssets) {
      // do nothing
    } else {
      localStorage.setItem('assetsData', JSON.stringify(assets));
    }
  }

  getAssetsFromLocalStorage() {
    const localAssets = localStorage.getItem('assetsData');
    if (localAssets) {
      return JSON.parse(localAssets);
    } else {
      return null;
    }
  }

  getAssets() {
    return this.assets;
  }

  saveAssets(assets: Currency[]) {
    this.assets = assets;
  }

  getQuotesFromDB() {
    const quotesCollection = this.afs.collection(`/quotes/`);
    return new Promise((resolve, reject) => {
      quotesCollection
        .snapshotChanges()
        .pipe(
          map((actions) => {
            return actions.map((action: any) => {
              const data = action.payload.doc.data() as any;
              data.uid = action.payload.doc.id;
              return data;
            });
          }),
        )
        .subscribe(
          (quotes) => {
            resolve(quotes);
          },
          (err) => reject(err),
        );
    });
  }

  getQuotesAsObs(): Observable<any[]> {
    const quotesCollection = this.afs.collection(`/quotes/`);
    const quotes = quotesCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((action: any) => {
          const data = action.payload.doc.data() as any;
          data.uid = action.payload.doc.id;
          return data;
        });
      }),
    );
    return quotes;
  }

  getQuotesAsPromise(): Promise<any[]> {
    const quotesCollection = this.afs.collection(`/quotes/`);
    const quotes = quotesCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((action: any) => {
          const data = action.payload.doc.data() as any;
          data.uid = action.payload.doc.id;
          return data;
        });
      }),
    );
    return quotes.pipe(first()).toPromise();
  }

  getQuotes() {
    return this.quotes;
  }

  broadcastQuotes(data: any) {
    this.quotesSource.next(data);
  }

  saveQuotes(quotes: any) {
    this.quotes = quotes;
  }

  getSelectedCoin() {
    return this.selectedCoin;
  }

  saveSelectedCoin(asset: any) {
    this.selectedCoin = asset;
  }

  getUsersBalances(): Promise<any[]> {
    const balancesCollection = this.afs.collectionGroup('balances');
    const balances = balancesCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((action: any) => {
          const data = action.payload.doc.data() as any;
          data.uid = action.payload.doc.id;
          return data;
        });
      }),
    );
    return balances.pipe(first()).toPromise();
  }

  getUsersBalancesAsObs(): Observable<any[]> {
    const balancesCollection = this.afs.collectionGroup('balances');
    const balances = balancesCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((action: any) => {
          const data = action.payload.doc.data() as any;
          data.uid = action.payload.doc.id;
          return data;
        });
      }),
    );
    return balances;
  }
}
