import { Component, OnInit, Input, OnChanges, OnDestroy, SimpleChange } from '@angular/core';
import { Currency } from 'src/app/models/Currency.model';
import { OverlayService } from 'src/app/services/overlay.service';
import { floor10 } from '../../custom/Utils';
import { DataService } from 'src/app/services/data.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-overview',
  templateUrl: 'overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent
  implements OnInit, OnChanges, OnDestroy {
  soyBalance: any;
  isLoading: boolean;
  floor10 = floor10;

  @Input() assets: [Currency];
  @Input() balances: [any];
  @Input() quotes: [any];

  refreshSubscription: any;

  constructor(
    private authService: AuthService,
    private overlayService: OverlayService,
    private dataService: DataService,
  ) {}

  get userRole() {
    return this.authService.userRole;
  }

  ngOnInit() {}

  ngOnChanges({ balances }: { balances: SimpleChange }) {
    if (balances && balances.currentValue) {
      if (this.balances.length) {
        this.soyBalance = this.balances.find(
          ({ id }) => id === 'SOY',
        )
      }
    }
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe()
    }
  }

  async refreshWallet() {
    this.setProgressBar('show');
    this.refreshSubscription = (
      await this.dataService.refreshWallet()
    ).subscribe(
      (res) => {
        this.setProgressBar('hide');
        this.showSnackBar('Wallet refresh successfull')
      },
      (reject) => {
        console.log('reject:', reject);
        this.setProgressBar('hide');
        // this.showSnackBar(reject.error.error.message);
        for (const e of reject.error.error) {
          this.showSnackBar(e);
        }
      },
    );
  }

  getCurrency(id: string): Currency {
    const currency = this.assets.find((cur) => cur.symbol === id);
    return currency;
  }

  getCoinExchgRate(baseCoin: string, targetCoin: string) {
    if (baseCoin === targetCoin) {
      return 1;
    } else {
      if (this.quotes.length > 0) {
        const quote = this.quotes.find((el) => el.base === baseCoin);
        if (quote.rates) {
          const rate = quote.rates[targetCoin];
          return +floor10(rate, -3);
        }
      }
    }
  }

  openDialog(content: string) {
    const action = {
      type: 'openDialog',
      msg: content,
    };
    this.overlayService.setAction(action);
  }

  // METHODS FOR USER FEEDBACK
  setProgressBar(status: string) {
    const action = {
      type: 'setProgressBar',
      msg: status,
    };
    this.overlayService.setAction(action);
  }

  showSnackBar(message: string) {
    const action = {
      type: 'openSnackBar',
      msg: message,
    };
    this.overlayService.setAction(action);
  }
}
