<div class="notifs">
  <div *ngIf="!log" class="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="notifs__nolist" *ngIf="log && log?.length === 0">
    <p>{{ 'notifs.noItemsFoundMsg' | translate }}</p>
  </div>
  <div class="notifs-table" *ngIf="log && log?.length > 0">
    <div class="notif" *ngFor="let item of log">
      <div class="notif__item">
        <p class="notif__label">Date:</p>
        <p class="notif__data">
          {{ item.created_at | date: 'medium' }}
        </p>
      </div>
      <div class="notif__item">
        <p class="notif__label">Type:</p>
        <p *ngIf="item.Manager" class="notif__data">
          {{ item.Action }}
        </p>
        <p *ngIf="!item.Manager" class="notif__data">
          {{ 'logger.notAvailableMsg' | translate }}
        </p>
      </div>
      <div class="notif__item">
        <p class="notif__label">Message:</p>
        <p class="notif__data">
          {{ item.Message }}
        </p>
      </div>
    </div>
  </div>
</div>
