<div class="notifications">
  <h2 class="notifications__title">
    {{ 'notifications.title' | translate }}
  </h2>
  <button
    mat-icon-button
    aria-label="close icon - button"
    (click)="onClose()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <div class="notifications__content">
    <svg-icon
      class="bell-icon"
      src="../../../assets/img/bell.svg"
      [svgStyle]="{ 'width.px': 40, 'height.px': 40 }"
    >
    </svg-icon>
    <div class="notifications__message" *ngIf="notification">
      <p class="notifications__message--title">
        {{
          notification.msgTitle
            ? notification.msgTitle
            : 'No New Messages'
        }}
      </p>
      <p class="notifications__message--description">
        {{ notification.msgDescr ? notification.msgDescr : '...'
        }}<a
          *ngIf="notification.msgLink"
          [routerLink]="notification.msgLink"
          >LINK</a
        >
      </p>
      <p *ngIf="notification.msgAdditionalInfo">
        <strong>{{ notification.msgAdditionalInfo }}</strong>
      </p>
    </div>
  </div>
  <div class="notifications__content" *ngIf="!notification">
    <p>{{ 'system.isLoadingTxt' | translate }}</p>
  </div>
</div>
