import { NgModule } from '@angular/core';
import {
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';

import { AuthGuardService } from './guards/auth-guard.service';

import { LoginComponent } from './pages/auth-page/login/login.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { DashboardComponent } from './pages/main-page/dashboard/dashboard.component';
import { AuthPageComponent } from './pages/auth-page/auth-page.component';
import { ForgotPasswordComponent } from './pages/auth-page/forgot-password/forgot-password.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { ProfileComponent } from './pages/main-page/profile/profile.component';
import { SignupComponent } from './pages/auth-page/signup/signup.component';
import { MailValidateComponent } from './pages/main-page/mail-validate/mail-validate.component';
import { PhoneValidateComponent } from './pages/main-page/phone-validate/phone-validate.component';
import { QrScannerComponent } from './pages/qr-scanner/qr-scanner.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthPageComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        component: LoginComponent,
        data: { returnUrl: window.location.pathname },
      },
      {
        path: 'signup',
        component: SignupComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
    ],
  },
  {
    path: 'main',
    component: MainPageComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },
    ],
  },
  {
    path: 'validations',
    component: MainPageComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'phone',
        component: PhoneValidateComponent,
      },
      {
        path: 'email',
        component: MailValidateComponent,
      },
    ],
  },
  {
    path: 'qr-scanner/:action',
    component: QrScannerComponent
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  { path: '**', component: NotfoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
