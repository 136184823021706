import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
} from '@angular/core';
import { map, first } from 'rxjs/operators';

import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ClipboardService } from 'ngx-clipboard';

import { AuthService } from 'src/app/services/auth.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  @Input() showChat: boolean;
  @Output() toggleChat: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  userID: string;
  userName: string;
  roomInput: string;
  UImode = 'standby';
  currentRoom: string;
  currentRoomData: any;
  userRooms: any[];
  userRoomsSubscr: any;

  messages: any;
  inputMsg: string;
  showError: boolean;
  errorMsg: string;
  sendingMessage = false;
  messageSubscr: any;

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private clipboardService: ClipboardService,
    private overlayService: OverlayService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.userID = this.authService.getCurrentUserID();
    this.userName = this.authService.getUserDisplayName();

    this.userRoomsSubscr = this.getMyRooms().subscribe((data) => {
      if (data) {
        this.userRooms = data.rooms;
      } else {
        this.userRooms = [];
      }
    });
  }

  ngOnChanges(changes: any) {
    if (this.showChat) {
      // chat has been opened
      if (this.currentRoom) {
        this.roomWasSeleted(this.currentRoom);
      }
    } else {
      // chat has been closed
      this.unsubscribeAll();
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this.messageSubscr) {
      this.messageSubscr.unsubscribe();
    }
    if (this.userRoomsSubscr) {
      this.userRoomsSubscr.unsubscribe();
    }
  }

  setUI(mode: string) {
    this.UImode = mode;
  }

  closeChat() {
    this.resetChat();
    this.toggleChat.emit(false);
  }

  createRoom() {
    this.resetChat();
    const chatCollection = this.afs.collection(`/chat`);
    const chat = {
      name: this.roomInput,
      owner_id: this.userID,
      participants: [this.userID],
    };
    chatCollection
      .add(chat)
      .then((docRef) => {
        this.currentRoom = docRef.id;

        // sets the first message, to create the Collection in Firebase
        this.sendMessage(
          `${new Date().toDateString()} | Chat room created, welcome!`,
          true,
        );
        this.UImode = 'standby';

        // add created room to user chat rooms (ones he created or participated)
        this.addToMyRoomsOnDB({
          name: chat.name,
          id: docRef.id,
          owner_id: this.userID,
        });

        if (this.messageSubscr) {
          this.messageSubscr.unsubscribe();
        }
        this.messageSubscr = this.getChatMessages().subscribe(
          (msgs) => {
            this.messages = msgs;
          },
        );
      })
      .catch(() => {
        console.log('error creating chat');
        this.errorMsg = 'Error! Try again later';
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
          this.errorMsg = '';
        }, 2000);
        this.currentRoom = null;
      });
  }

  joinRoom() {
    this.getRoomDoc().subscribe((res) => {
      if (res) {
        this.resetChat();
        this.currentRoom = this.roomInput;
        this.inputMsg = null;

        // add entered room to user chat rooms
        this.addToMyRoomsOnDB({
          name: res.name,
          id: res.uid,
          owner_id: res.owner_id,
        });

        this.messageSubscr = this.getChatMessages().subscribe(
          (msgs) => {
            this.UImode = 'standby';
            this.messages = msgs;
          },
        );
      } else {
        this.errorMsg = 'Room not found';
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
          this.errorMsg = '';
        }, 2000);
      }
    });
  }

  getMyRooms() {
    const chatPartDocRef = this.afs.doc<any>(
      `chat_participation/${this.userID}`,
    );
    const chatPartDoc = chatPartDocRef.snapshotChanges().pipe(
      map((action) => {
        if (action.payload.exists === false) {
          return null;
        } else {
          const data = action.payload.data() as any;
          return data;
        }
      }),
    );
    return chatPartDoc;
  }

  addToMyRoomsOnDB(roomData: any) {
    const foundRoom = this.userRooms.filter(
      (room) => room.id === roomData.id,
    );
    if (foundRoom.length === 0) {
      // console.log('room is not on my list, include it now!!');
      const updatedRooms = [...this.userRooms];
      updatedRooms.push(roomData);
      const data = {
        rooms: updatedRooms,
      };

      const chatPartCollection = this.afs.collection(
        `/chat_participation`,
      );
      chatPartCollection
        .doc(this.userID)
        .set(data)
        .then(() => {})
        .catch(() => {});
    }
  }

  removeFromMyRoomsOnDB() {
    const updatedRooms = [...this.userRooms].filter(
      (room) => room.id !== this.currentRoom,
    );
    const data = {
      rooms: updatedRooms,
    };
    const chatPartCollection = this.afs.collection(
      `/chat_participation`,
    );
    chatPartCollection
      .doc(this.userID)
      .set(data)
      .then(() => {
        this.currentRoom = null;
        this.currentRoomData = null;
      })
      .catch(() => {});
  }

  resetChat() {
    if (this.messageSubscr) {
      this.messageSubscr.unsubscribe();
    }
    this.messages = [];
    this.UImode = 'standby';
  }

  roomWasSeleted(roomID: string) {
    this.currentRoom = roomID;
    if (this.messageSubscr) {
      this.messageSubscr.unsubscribe();
    }
    this.messageSubscr = this.getChatMessages().subscribe((msgs) => {
      this.messages = msgs;
    });
  }

  getRoomDoc(): Observable<any> {
    const chatDocRef = this.afs.doc<any>(`chat/${this.roomInput}`);
    const chatDoc = chatDocRef.snapshotChanges().pipe(
      map((action) => {
        if (action.payload.exists === false) {
          return null;
        } else {
          const data = action.payload.data() as any;
          data.uid = action.payload.id;
          this.currentRoomData = data;
          return data;
        }
      }),
    );
    return chatDoc;
  }

  sendMessage(msgContent: string, fromSystem: boolean = false) {
    if (!this.sendingMessage) {
      this.sendingMessage = true;
      // firebase stuff here
      const messagesCollection = this.afs.collection(
        `/chat/${this.currentRoom}/messages`,
      );
      const message = {
        created_at: new Date(),
        content: msgContent,
        user_id: fromSystem ? '_fromsystem' : this.userID,
        system: fromSystem,
        user_name: fromSystem ? 'SYSTEM' : this.userName,
        owner: false,
      };
      messagesCollection
        .add(message)
        .then(() => {
          this.sendingMessage = false;
          this.inputMsg = null;
        })
        .catch(() => {
          console.log('error sending message!');
          this.sendingMessage = false;
          this.inputMsg = null;
        });
    }
  }

  delChatMessages() {
    const messagesCollection = this.afs.collection(
      `/chat/${this.currentRoom}/messages`,
    );
    this.sendMessage(
      `${new Date().toDateString()} | Messages history has been cleared`,
      true,
    );
    this.messages.forEach((msg) => {
      if (!msg.system) {
        messagesCollection
          .doc(msg.id)
          .delete()
          .then(() => {})
          .catch(() => {});
      }
    });
  }

  getChatMessages(): Observable<any> {
    const messagesCollection = this.afs.collection(
      `/chat/${this.currentRoom}/messages`,
      (ref) => ref.orderBy('created_at', 'desc'),
    );
    const messages = messagesCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as any;
          data.id = action.payload.doc.id;
          if (
            data.created_at &&
            typeof data.created_at !== 'string'
          ) {
            data.created_at = data.created_at.toDate();
          }
          return data;
        });
      }),
    );
    return messages;
  }

  copyChatIDToClipboard() {
    // console.log(this.currentRoom);
    this.clipboardService.copyFromContent(
      `${this.translate.instant('chat.shareMsg')} ${
        this.currentRoom
      }`,
    );
    this.showSnackBar(this.translate.instant('chat.clipboardMsg'));
  }

  showSnackBar(message: string) {
    const action = {
      type: 'openSnackBar',
      msg: message,
    };
    this.overlayService.setAction(action);
  }
}
