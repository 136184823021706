<span [ngClass]="{'button-container': buttonContainer}">

    <div class="spinner-container" *ngIf="loading">

        <mat-spinner [strokeWidth]="buttonContainer ? 3 : strokeWidth" [diameter]="buttonContainer ? 20 : diameter">
        </mat-spinner>

    </div>

    <ng-content></ng-content>

</span>