import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
} from '@angular/core';

import { Notification } from 'src/app/models/Notification.model';

@Component({
  selector: 'app-notification',
  templateUrl: 'notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  constructor() {}
  @Input() notification: Notification;
  @Output() closeNotif = new EventEmitter();

  ngOnInit() {}

  onClose() {
    this.closeNotif.emit();
  }
}
