import { Component, OnInit } from '@angular/core';
import { OverlayService } from 'src/app/services/overlay.service';
import { ModalComponent } from '../modal/modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnInit {
  isProcessing: boolean = false;
  overlaySubscription: any;
  dialogRef: any;
  isMobile: boolean;

  constructor(
    private overlayService: OverlayService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    if (window.innerWidth <= 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    // faz a "inscrição" do Subject (rxjs), comunicação entre componentes!
    this.overlayService.currentMsg.subscribe((data) => {
      switch (data.type) {
        case 'openSnackBar':
          this.openSnackBar(data.msg, data.config || {});
          break;
        case 'setProgressBar':
          this.setProgressBar(data.msg);
          break;
        case 'openDialog':
          // console.log(data);
          this.openDialog(data.msg, data.userID, data.props);
          break;
        case 'closeDialog':
          this.closeDialog();
          break;
        default:
        // console.log(
        //   'overlay.component|Error in using overlay.services, action not known!',
        // );
      }
    });
  }

  openSnackBar(
    message: string,
    { type = 'success', duration = 4000 }: any,
  ) {
    this.snackBar.open(message, 'X', {
      duration: duration,
      panelClass: ['snackbar-custom', type],
    });
  }

  setProgressBar(status: string) {
    this.isProcessing = status === 'show' ? true : false;
  }

  openDialog(modalContentType: string, uid: string, props?: any) {
    let dialogW: string;
    let dialogH: string;

    switch (modalContentType) {
      case 'userSheet':
        dialogW = '800px';
        dialogH = '95vh';
        break;
      case 'userLog':
      case 'bucketTrades':
        dialogW = '600px';
        dialogH = '95vh';
        break;
      case 'welcome':
        dialogW = this.getVideoModalSize().w;
        dialogH = this.getVideoModalSize().h;
        break;
      case 'dataexclusion':
        dialogW = '320px';
        dialogH = 'auto';
        break;
      default:
        this.isMobile ? (dialogW = '96vw') : (dialogW = 'auto');
        this.isMobile ? (dialogH = '96vh') : (dialogH = 'auto');
    }
    this.dialogRef = this.dialog.open(ModalComponent, {
      width: dialogW,
      height: dialogH,
      maxWidth: '96vw !important',
      maxHeight: '96vh !important',
      data: {
        type: modalContentType,
        userID: uid,
        props: props,
      },
      panelClass: 'no-padding-dialog',
    });
  }

  getVideoModalSize() {
    let relativeWidth = (window.innerWidth * 80) / 100; // take up to 80% of the screen size
    if (window.innerWidth > 1500) {
      relativeWidth = (1500 * 80) / 100;
    } else {
      relativeWidth = (window.innerWidth * 80) / 100;
    }

    const relativeHeight = (relativeWidth * 9) / 16 + 64; // 16:9 more header height
    const size = {
      w: `${relativeWidth}px`,
      h: `${relativeHeight}px`,
    };
    return size;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
