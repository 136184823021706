export enum MimeTypes {
    JPEG = 'image/jpeg',
    PNG = 'image/png',
    GIF = 'image/gif',
    WEBP = 'image/webp',
    MP4 = 'video/mp4',
    AVI = 'video/x-msvideo',
    MPEG = 'video/mpeg',
    VIDEO_3GPP = 'video/3gpp',
    VIDEO_WEBM = 'video/webm',
    WAV = 'audio/wav',
    MP3 = 'audio/mpeg',
    AUDIO_WEBM = 'audio/webm',
    AUDIO_3GPP = 'audio/3gpp',
    PDF = 'application/pdf',
    JSON = 'application/json',
    GZIP = 'application/gzip',
    ZIP = 'application/zip',
    RAR = 'application/vnd.rar',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    XLS = 'application/vnd.ms-excel',
    PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    PPT = 'application/vnd.ms-powerpoint',
    DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    DOC = 'application/msword',
    TEXT = 'text/plain',
    CSV = 'text/csv',
  }
  
  export const MimeTypesValues = Object.values(MimeTypes);
  