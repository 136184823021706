<main class="history" *ngIf="selectedAction && selectedCoin">
  <div class="history-header">
    <div class="history-header__container">
      <h3 class="history-header__subtitle">
        {{ 'history.title' | translate }}
      </h3>
      <mat-form-field
        class="history-header__actions"
        appearance="outline"
      >
        <mat-label></mat-label>
        <mat-select
          [(value)]="selectedAction"
          (selectionChange)="actionWasSelected($event)"
        >
          <mat-option
            *ngFor="let option of selectOptions"
            [value]="option.value"
            >{{ option.label }}</mat-option
          >
          <!-- <mat-option value="deposit" translate>Deposit</mat-option>
          <mat-option value="trades" *ngIf="userRole !== 'MASTER_MANAGER'">Trades</mat-option>
          <mat-option value="withdraw">Withdrawal</mat-option>
          <mat-option value="invoices" *ngIf="userRole !== 'MASTER_MANAGER'">Invoices</mat-option> -->
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="buy content time-line">
    <div
      class="history-choosecoin"
      *ngIf="
        selectedAction === 'deposit' || selectedAction === 'withdraw'
      "
    >
      <p class="mr-sm">{{ 'history.coinInstruct' | translate }}</p>
      <mat-form-field appearance="outline">
        <mat-label></mat-label>
        <mat-select
          [(value)]="selectedCoin"
          (selectionChange)="coinWasSelected($event)"
        >
          <mat-option value="BTC">Bitcoin</mat-option>
          <mat-option value="ETH">Ether</mat-option>
          <mat-option value="SOY">Soya Coin</mat-option>
            
        </mat-select>
      </mat-form-field>
    </div>
    <div class="history-loading mt-lg" *ngIf="loadingData">
      <p>{{ 'history.loaderTxt' | translate }}</p>
    </div>
    <div
      class="history-noData mt-lg"
      *ngIf="!loadingData && historyData?.length === 0"
    >
      <p>{{ 'history.noItemsMessage' | translate }}</p>
    </div>
    <div
      class="history-table mt-md"
      *ngIf="!loadingData && historyData?.length > 0"
    >
      <div
        class="history-row"
        *ngFor="let item of historyData.slice(0, historyQtd)"
      >
        <div class="history-cell history-cell__visual">
          <svg-icon
            class="status-icon"
            src="../../../assets/img/{{
              getNameOfIcon(item.status)
            }}.svg"
            [style.fill]="getColorOfIcon(item.status)"
            [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
          ></svg-icon>
          <div class="history-cell__visual--line"></div>
        </div>
        <div class="history-cell__main">
          <p class="history-cell history-cell__status">
            {{ item.status }}
            <a
              *ngIf="
                selectedAction === 'deposit' ||
                (selectedAction === 'withdraw' && item.txid)
              "
              href="{{ blockExplorerLink[selectedCoin] }}/{{
                item.txid
              }}"
              target="_blank"
            >
              <ion-icon
                class="icon-sm"
                name="search-outline"
              ></ion-icon
            ></a>
          </p>
          <p *ngIf="selectedAction === 'invoices' && item.is_invoice">
            Invoice ID:
            <span class="is-link" (click)="openInvoice(item)">{{
              item.id
            }}</span>
          </p>
          <p
            class="history-cell history-cell__value"
            *ngIf="selectedAction === 'invoices'"
          >
            {{ floor10(item.amount, -6) }}
          </p>
          <p
            class="history-cell history-cell__value"
            *ngIf="selectedAction === 'trades'"
          >
            {{ 'history.logReceived' | translate }}
            {{ floor10(item.amount, -6) }} {{ item.coin }}
            {{ 'history.logFor' | translate }}
            {{ floor10(item.quote_amount, -6) }}
            {{ item.sell_coin_id }}
          </p>
          <p
            class="history-cell history-cell__value"
            *ngIf="
              selectedAction !== 'trades' &&
              selectedAction !== 'invoices' &&
              item.amount
            "
          >
            {{ floor10(item.amount, -6) }}
          </p>
          <p
            class="history-cell history-cell__value"
            *ngIf="
              selectedAction !== 'trades' &&
              selectedAction !== 'invoices' &&
              item.value
            "
          >
            {{ floor10(item.value, -6) }}
          </p>
        </div>
        <p class="history-cell history-cell__date">
          {{ item.created_at | date: 'mediumDate' }}
          {{ item.created_at | date: 'shortTime' }}
        </p>
      </div>

      <button
        mat-raised-button
        color="primary"
        class="load-more"
        (click)="loadMore()"
        *ngIf="historyData.length > historyQtd"
      >
        {{ 'history.loadMoreBtn' | translate }}
      </button>
    </div>
  </div>
</main>
