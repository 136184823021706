import BigNumber from 'bignumber.js';

export const sub = (x: number, y: number) => {
  const n1 = new BigNumber(x);
  const n2 = new BigNumber(y);
  return (n1.minus(n2)).toNumber();
};

export const add = (x: number, y: number) => {
  const n1 = new BigNumber(x);
  const n2 = new BigNumber(y);
  return (n1.plus(n2)).toNumber();
};

export const divide = (x: number, y: number) => {
  const n1 = new BigNumber(x);
  const n2 = new BigNumber(y);
  return (n1.dividedBy(n2)).toNumber();
};

export const multiply = (x: number, y: number) => {
  const n1 = new BigNumber(x);
  const n2 = new BigNumber(y);
  return (n1.multipliedBy(n2)).toNumber();
};

export const min = (x: number, y: number) => {
  const n1 = new BigNumber(x);
  const n2 = new BigNumber(y);
  return BigNumber.minimum(n1, n2).toNumber();
};

export const neg = (x: number) => {
  const n1 = new BigNumber(x);
  return n1.negated().toNumber();
};
