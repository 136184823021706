import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs';
import { finalize, map, catchError } from 'rxjs/operators';

import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';

import { MimeTypesValues } from '../../constants/mime-types';

// import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
// import { File } from '@ionic-native/file/ngx';
// import { ActionSheetController, LoadingController, Platform } from '@ionic/angular';

export interface UploadItem {
  ref?: string;
  url?: string;
  name?: string;
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  // Main task
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  fileName: string;
  fileURL: string;

  // Progress monitoring
  uploadPercent: Observable<number>;
  snapshot: Observable<any>;
  uploadState: Observable<string>;
  downloadURL: Observable<string>;
  isUploading: boolean;
  showProgressArea: boolean;
  showFeedback: boolean;
//  fileSizeLimit: number = 50000; // in Kbytes

  @Input() path: string;
  @Input() refName: string; // this is to differentiate this file uploader from others in the form
  @Input() validTypes: string[] = MimeTypesValues;
  @Input() fileSizeLimit: number = 50000; // in Kbytes
  @Output() uploadComplete: EventEmitter<UploadItem> = new EventEmitter<UploadItem>();
  @Output() uploadInProgress: EventEmitter<boolean> = new EventEmitter<boolean>();

//  @Input() clickAction: any;


  constructor(private afStorage: AngularFireStorage) {}

  ngOnInit() {
    // console.log(`file-upload, path: ${this.path}`);
  }

  fileIsValid(type: string, size: number) {
    const sizeKb: number = parseInt((size / 1024).toFixed());
    if (this.validTypes.includes(type)) {
      return this.sizeIsvalid(sizeKb, this.fileSizeLimit);
    } else {
      return false;
    }
  }

  sizeIsvalid(size: number, limit: number) {
    return size <= limit;
  }

  startUpload(event: FileList) {
    // The File object
    const file = event.item(0);

    if (this.fileIsValid(file.type, file.size)) {
      this.fileName = file.name;
      this.isUploading = true;
      this.uploadInProgress.emit(true);
      this.showFeedback = false;
      this.showProgressArea = true;

      this.ref = this.afStorage.ref(`${this.path}/${new Date().getTime()}_${file.name}`,);
//      this.ref.putString
      this.task = this.ref.put(file);
      this.snapshot = this.task.snapshotChanges();
      this.uploadState = this.task.snapshotChanges().pipe(map((s) => s.state));

      // Progress monitoring
      this.uploadPercent = this.task.percentageChanges();

      // get notified when the download URL is available
      this.task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            this.downloadURL = this.ref.getDownloadURL();
            const UrlString = this.ref
              .getDownloadURL()
              .toPromise()
              .then((data) => {
                this.fileURL = data;
                this.uploadInProgress.emit(false);
                this.uploadComplete.emit({
                  ref: this.refName,
                  url: this.fileURL,
                  name: this.fileName,
                });
              });

            this.uploadState = null; // hides the button
            this.showFeedback = true;
            const newTime = setTimeout(() => {
              this.showFeedback = false;
              this.isUploading = false;
              this.showProgressArea = false;
            }, 3000);
          })
        )
        .subscribe();
    } else {
      window.alert('Error: Problems with the file format and/or the file size!');
    }
  }

/*
  // MOBILE
  get isMobile() {
    return this.platform.is('mobile');
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Please wait...',
      showBackdrop: true,
      // duration: 5000
    });
    await loading.present();
  }

  pickImage(sourceType) {
    try{
      this.presentLoading();

      const options: CameraOptions = {
        quality: 100,
        sourceType: sourceType,
        destinationType: this.camera.DestinationType.DATA_URL,
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: this.camera.MediaType.PICTURE
      }

      this.camera.getPicture(options).then((imageData) => {
        fetch('data:image/jpeg;base64,' + imageData)
        .then(res => res.blob())
        .then(res => this.startUpload(res))
        .then(res => this.loadingController.dismiss());
      }, (err) => {
        console.log('pickImage error', err);
        this.loadingController.dismiss();
        window.alert(
          'Error_1 = ' + err,
        );
      });
    } catch(err) {
      console.log('pickImage error', err);
      this.loadingController.dismiss();
      window.alert(
        'Error_2 = ' + err,
      );
    }
  }

  async selectImage() {
    const actionSheet = await this.actionSheetController.create({
      header: "Select Image source",
      buttons: [{
        text: 'Load from Library',
        handler: () => {
          this.pickImage(this.camera.PictureSourceType.PHOTOLIBRARY);
        }
      },
      {
        text: 'Use Camera',
        handler: () => {
          this.pickImage(this.camera.PictureSourceType.CAMERA);
        }
      },
      {
        text: 'Cancel',
        role: 'cancel'
      }
      ]
    });

    await actionSheet.present();
  }
*/
}
