<div class="signup-container">
  <div class="top-area">
    <a mat-button color="primary" routerLink="/auth/login"
      >{{ 'signup.link1' | translate | uppercase }}</a
    >
  </div>
  <div class="form-container">
    <h2 class="heading-secondary form-container__title">
      {{ 'signup.title' | translate | uppercase }}
    </h2>
    <!-- PASSWORD AND CONFIRM PASSWORD AREA -------------------------->
    <form
      [formGroup]="formGroup1"
      class="mg-lg form"
      [style.fontSize.px]="16"
    >
      <!-- FULL NAME -->
      <mat-form-field class="form__field" color="primary">
        <mat-label>{{ 'signup.nameFormTitle' | translate }}</mat-label>
        <input
          class="form__input"
          matInput
          placeholder=""
          [formControl]="full_name"
          type="text"
          required
        />
        <mat-error *ngIf="full_name.invalid">
          {{ getNameErrorMsg() }}
        </mat-error>
      </mat-form-field>

      <!-- E-MAIL -->
      <!-- 'form__field': labelPos === 'bottom' ? false : true -->
      <mat-form-field
        [ngClass]="{
          form__field: true
        }"
        color="primary"
      >
        <mat-label>{{ 'signup.emailFormTitle' | translate }}</mat-label>
        <input
          class="form__input"
          matInput
          placeholder=""
          [formControl]="email"
          type="email"
          required
        />
        <mat-error *ngIf="email.invalid">
          {{ getEmailErrorMsg() }}
        </mat-error>
      </mat-form-field>
      <!-- PASSWORD -->
      <!-- 'form__field': labelPos === 'bottom' ? false : true -->
      <mat-form-field
        [ngClass]="{
          form__field: true
        }"
        color="primary"
      >
        <mat-label>{{ 'signup.pwdFormTitle' | translate }}</mat-label>
        <input
          class="form__input"
          matInput
          placeholder=""
          [formControl]="userPwd"
          type="password"
          required
        />
        <mat-error *ngIf="userPwd.invalid">
          {{ getPwdErrorMsg() }}
        </mat-error>
      </mat-form-field>

      <!-- 'form__field': labelPos === 'bottom' ? false : true -->
      <mat-form-field
        [ngClass]="{
          form__field: true
        }"
        color="primary"
      >
        <mat-label>{{ 'signup.pwdConfFormTitle' | translate }}</mat-label>
        <input
          class="form__input"
          matInput
          id="userConfPwd"
          placeholder=""
          [formControl]="userConfPwd"
          type="password"
          required
        />
        <mat-error *ngIf="userConfPwd.invalid">
          {{ getUserConfPwdErrorMsg() }}
        </mat-error>
      </mat-form-field>

      <!-- <div id="recaptcha-container"></div> -->
      <div
        class="g-recaptcha"
        [attr.data-sitekey]="gRecaptchaKey"
      ></div>
    </form>

    <section class="last">
      <div class="last__container">
        <div class="last__checkbox">
          <mat-checkbox class="example-margin" *ngIf="getCurrentLanguage() === 'pt'"
            [(ngModel)]="hasAgreed" >Li e concordo com os <a href="https://soya-coin.com/terms/" target="_blank">Termos de Uso</a> e a <a href="https://soya-coin.com/privacy/" target="_blank">Política de Privacidade</a>.
          </mat-checkbox>
          <mat-checkbox class="example-margin" *ngIf="getCurrentLanguage() === 'en'"
            [(ngModel)]="hasAgreed" >I´ve read and agree to the <a href="https://soya-coin.com/terms/" target="_blank">Terms of Use</a> and <a href="https://soya-coin.com/privacy/" target="_blank">Privacy Policy</a>.
          </mat-checkbox>
        </div>
        <!--(click)="onSubmit()"-->
        <button
          class="btn"
          id="signup-button"
          mat-flat-button
          (click)="register()"
          color="primary"
          [disabled]="
            !full_name.valid ||
            !email.valid ||
            !userPwd.valid ||
            !userConfPwd.valid ||
            !hasAgreed
          "
        >
          {{ 'signup.button1' | translate | uppercase }}
        </button>
      </div>
    </section>

    <p
      class="error-msg"
      [style.visibility]="showErrMsg ? 'visible' : 'hidden'"
    >
      {{ 'signup.mainError' | translate }}
    </p>
  </div>
  <div class="spacer">-----</div>
</div>
