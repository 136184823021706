<div class="deposit">
  <div class="deposit__coinSelector">
    <p class="deposit__title">{{ 'deposit.selectCoinTxt1' | translate }}</p>
    <mat-form-field
      class="actions-coin notranslate"
      appearance="outline"
    >
      <mat-label>{{ 'deposit.selectCoinTxt2' | translate }}</mat-label>
      <mat-select
        (selectionChange)="coinWasSelected($event)"
        [(value)]="selectedCoin"
      >
        <mat-option value="SOY" class="notranslate"
          >SOYA COIN</mat-option
        >
        <mat-option value="BTC" class="notranslate"
          >BITCOIN</mat-option
        >
        <mat-option value="ETH" class="notranslate">ETHER</mat-option>
        <!-- <mat-option value="BRL">REAIS</mat-option> -->
      </mat-select>
    </mat-form-field>
  </div>

  <div class="deposit__address" *ngIf="selectedCoin">
    <p class="deposit__title" translate [translateParams]="{coinID: getCoinName(this.selectedCoin)}">
      deposit.addressTitle
    </p>
    <div class="deposit__address--area">
      <p class="deposit__address--number notranslate">
        {{ address }}
      </p>
      <div class="deposit__address--icons">
        <ion-icon
          (click)="copyToService(this.selectedCoin)"
          name="copy-outline"
          class="deposit__address--icon"
        ></ion-icon>
        <ion-icon
          (click)="openWhatsAppLink(whatsAppLink)"
          name="logo-whatsapp"
          class="deposit__address--icon"
        ></ion-icon>
      </div>
    </div>
  </div>

  <div class="deposit__error" *ngIf="failedToGenAddress">
    <p>{{ 'deposit.errorAddress' | translate }}</p>
    <!-- <button
      class="deposit__error--btn"
      mat-flat-button
      color="primary"
      (click)="createAddress()"
      [disabled]="this.address === 'Creating address ...'"
    >
      Try Again
    </button> -->
  </div>

  <div class="deposit__main" *ngIf="selectedCoin">
    <div class="deposit__visual">
      <div class="qrcode-container">
        <mat-spinner
          [diameter]="40"
          *ngIf="!hasAddress"
        ></mat-spinner>
        <img
          [src]="getQRCode()"
          alt="qrcode"
          class="qrcode"
          *ngIf="hasAddress"
        />
      </div>
      <p class="deposit__visual--warning notranslate" translate [translateParams]="{coinID: getCoinName(this.selectedCoin)}">
        deposit.QRCodeWarning
      </p>
    </div>
    <div class="deposit__main--info">
      <p class="deposit__title">{{ 'deposit.obs1Title' | translate }}</p>
      <p class="deposit__main--text notranslate" translate [translateParams]="{coinID: getCoinName(this.selectedCoin)}">
        deposit.obs1Text
      </p>
      <p class="deposit__title">
        {{ 'deposit.obs2Title' | translate }}
      </p>

      <p class="deposit__main--text notranslate" translate [translateParams]="{coinID: getCoinName(this.selectedCoin)}">
        deposit.obs2Text
      </p>
    </div>
  </div>
</div>
