import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';

import { FormGroup, FormControl, Validators } from '@angular/forms';

import { CustomUser } from 'src/app/models/CustomUser.model';
import { AddressInfo } from 'src/app/models/AddressInfo.model';

import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss'],
})
export class KycComponent implements OnInit, OnDestroy {
  @Input() userData: CustomUser;
  editingNotAllowed = false;
  isUploading = false;
  tempUserData: CustomUser;
  screenHeight: number;
  screenWidth: number;
  labelPos = 'bottom'; // end or bottom

  formGroup1: FormGroup;
  formGroup2: FormGroup;
  showErrMsg = false;
  currentUserID: string;
  currentPath: string;

  noUserPhotoURL =
    'https://firebasestorage.googleapis.com/v0/b/soya-coin.appspot.com/o/system%2Fdefault-user-profile-image-png.png?alt=media&token=d27683f6-55ce-4809-b113-b2b32e588a9a';
  noImageURL =
    'https://firebasestorage.googleapis.com/v0/b/soya-coin.appspot.com/o/system%2FnoImageAvaiable.png?alt=media&token=a16bf44e-4397-401b-aebe-996725f96891';
  photoURL = this.noUserPhotoURL;
  poiURL = this.noImageURL;
  poi2URL = this.noImageURL;
  porURL = this.noImageURL;
  selfieURL = this.noImageURL;
  tempDate = new Date(1911, 11, 11);
  saveDataSubscription: any;
  countryNames: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dataService: DataService,
    private overlayService: OverlayService,
    private utilsService: UtilsService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private platform: Platform
  ) {
    this.getScreenSize();
  }

  ngOnInit() {
    if (!this.userData.kyc.personal_info.phone_validated) {
      this.router.navigate(['/validations/phone']);
    }
    this.currentUserID = this.authService.getCurrentUserID();
    this.countryNames = this.utilsService.getCountryNames();
    this.currentPath = `users/${this.currentUserID}/`;

    if (
      // 'PENDING' | 'ANALYZING' | 'REJECTED' | 'APPROVED'
      this.userData.kyc.status === 'ANALYZING' ||
      this.userData.kyc.status === 'APPROVED'
    ) {
      this.editingNotAllowed = true;
    } else {
      this.editingNotAllowed = false;
    }
    this.tempUserData = this.createTempUserData();

    this.setUpForms();
    this.formGroup1.patchValue(this.tempUserData.kyc.personal_info);
    this.formGroup2.patchValue(this.tempUserData.kyc.address);
  }

  ngOnDestroy() {
    if (this.saveDataSubscription) {
      this.saveDataSubscription.unsubscribe();
    }
  }

  // Two methods to change step label (responsive)
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 600) {
      this.labelPos = 'bottom';
    } else {
      this.labelPos = 'end';
    }
  }

  getStepLabel(step: number) {
    let label: string;
    if (step === 1) {
      label = this.screenWidth < 600 ? '1' : this.translate.instant('kyc.tabTitle1');
    } else if (step === 2) {
      label = this.screenWidth < 600 ? '2' : this.translate.instant('kyc.tabTitle2');
    }
    return label;
  }

  // FORM STUFF
  setUpForms() {
    this.formGroup1 = new FormGroup({
      full_name: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      doc_id: new FormControl('', [
        Validators.pattern('.*[^ ].*'),
        Validators.required,
      ]),
      doc_type: new FormControl('', [
        Validators.required
      ]),
      dob: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    });

    this.formGroup2 = new FormGroup({
      line1: new FormControl('', [
        Validators.pattern('.*[^ ].*'),
        Validators.required,
      ]),
      line2: new FormControl(''),
      city: new FormControl('', [
        Validators.pattern('.*[^ ].*'),
        Validators.required,
      ]),
      state: new FormControl('', [
        Validators.pattern('.*[^ ].*'),
        Validators.required,
      ]),
      country: new FormControl('', [
        Validators.required,
      ]),
      zipcode: new FormControl('', [
        Validators.pattern('.*[^ ].*'),
        Validators.required,
      ]),
    });
  }

  getPlaceholder(type: string) {
    let placeHolder: string;
    switch (type) {
      case 'avatar':
        placeHolder = this.noUserPhotoURL;
        break;
      case 'generic':
        placeHolder = this.noImageURL;
        break;
      default:
    }
    return placeHolder;
  }

  createTempUserData(): CustomUser {
    const tempData = {
      account_status: this.userData.account_status, // active, inactive...
      is_admin: this.userData.is_admin,
      kyc: {
        address: this.getAddressData(),
        personal_info: {
          avatar_url: this.userData.kyc.personal_info.avatar_url
            ? this.userData.kyc.personal_info.avatar_url
            : '',
          dob: this.userData.kyc.personal_info.dob
            ? this.userData.kyc.personal_info.dob
            : null,
          doc_id: this.userData.kyc.personal_info.doc_id
            ? this.userData.kyc.personal_info.doc_id
            : '',
          doc_type: this.userData.kyc.personal_info.doc_type
            ? this.userData.kyc.personal_info.doc_type
            : 'ID',
          doc_proof: this.userData.kyc.personal_info.doc_proof
            ? this.userData.kyc.personal_info.doc_proof
            : '',
          doc_back_proof: this.userData.kyc.personal_info
            .doc_back_proof
            ? this.userData.kyc.personal_info.doc_back_proof
            : '',
          email: this.userData.kyc.personal_info.email,
          email_conf: this.userData.kyc.personal_info.email_conf,
          full_name: this.userData.kyc.personal_info.full_name,
          phone: this.userData.kyc.personal_info.phone
            ? this.userData.kyc.personal_info.phone
            : '',
          selfie_proof: this.userData.kyc.personal_info.selfie_proof
            ? this.userData.kyc.personal_info.selfie_proof
            : this.getPlaceholder('generic'),
        },
        status: this.userData.kyc.status, // PENDING, PROCESSING (filled up all docs), APPROVED
      },
    };
    // console.log('createEmptyUserData:', userData);
    return tempData;
  }

  getAddressData() {
    let userAddressData: AddressInfo = {};
    if (!this.userData.kyc.address) {
      userAddressData = {
        line1: '',
        line2: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
        doc_url: '',
      };
    } else {
      userAddressData = {
        line1: this.userData.kyc.address.line1,
        line2: this.userData.kyc.address.line2,
        city: this.userData.kyc.address.city,
        state: this.userData.kyc.address.state,
        country: this.userData.kyc.address.country,
        zipcode: this.userData.kyc.address.zipcode,
        doc_url: this.userData.kyc.address.doc_url,
      };
    }
    return userAddressData;
  }

  submit() {
    this.tempUserData = this.updateUserData();
    this.saveUserDataInDB(this.tempUserData);
  }

  updateUserData(): CustomUser {
    const userData = {
      account_status: this.userData.account_status,
      is_admin: false,
      kyc: {
        address: {
          line1: this.line1.value,
          line2: this.line2.value,
          city: this.city.value,
          state: this.state.value,
          country: this.country.value,
          zipcode: this.zipcode.value,
          doc_url: this.tempUserData.kyc.address.doc_url,
        },
        personal_info: {
          avatar_url: this.tempUserData.kyc.personal_info.avatar_url,
          dob: this.dob.value,
          doc_id: this.doc_id.value,
          doc_proof: this.tempUserData.kyc.personal_info.doc_proof,
          doc_back_proof: this.tempUserData.kyc.personal_info
            .doc_back_proof,
          doc_type: this.doc_type.value,
          email: this.email.value,
          email_conf: this.tempUserData.kyc.personal_info.email_conf, // keeps how it was in DB
          full_name: this.full_name.value,
          phone: this.phone.value,
          selfie_proof: this.tempUserData.kyc.personal_info
            .selfie_proof, // keeps how it was in DB
        },
        status: this.tempUserData.kyc.status,
      },
    };
    return userData;
  }

  uploadIsInProgress(status) {
    // console.log(`Upload in progress? ${status}`);
    this.isUploading = status;
  }

  uploadIsComplete(evt) {
    switch (evt.ref) {
      case 'photo':
        this.photoURL = evt.url;
        // console.log(`this.photoURL: ${this.photoURL}`);
        this.tempUserData.kyc.personal_info.avatar_url = this.photoURL;
        // TO-DO: use SDK to save URL immediately after saving in Firebase Storage
        break;
      case 'poi':
        this.poiURL = evt.url;
        // console.log(`this.poiURL: ${this.poiURL}`);
        this.tempUserData.kyc.personal_info.doc_proof = this.poiURL;
        // TO-DO: use SDK to save URL immediately after saving in Firebase Storage
        break;
      case 'poi2':
        this.poi2URL = evt.url;
//        console.log(`this.poi2URL: ${this.poi2URL}`);
        this.tempUserData.kyc.personal_info.doc_back_proof = this.poi2URL;
        // TO-DO: use SDK to save URL immediately after saving in Firebase Storage
        break;
      case 'por':
        this.porURL = evt.url;
        // console.log(`this.porURL: ${this.porURL}`);
        this.tempUserData.kyc.address.doc_url = this.porURL;
        // TO-DO: use SDK to save URL immediately after saving in Firebase Storage
        break;
      default:
        console.error('error defining upload ref.');
    }
  }

  async saveUserDataInDB(data) {
    console.log(`saveUserDataInDB`, data);
    this.setProgressBar('show');

    this.saveDataSubscription = (
      await this.dataService.saveKYCDataViaAPI(data)
    ).subscribe(
      (res) => {
        console.log(res);

        // Notification stuff
        this.notificationsService.updateNotifStatus(
          'ANALYZING',
          true,
        );
        this.notificationsService.updateNotifStatus('REJECTED', true);

        this.router.navigate(['/main/dashboard']);
        this.setProgressBar('hide');
        this.showSnackBar('Data saved successfully');
      }, // success path
      (reject) => {
        console.log(reject);
        this.setProgressBar('hide');
        for (const e of reject.error.error) {
          this.showSnackBar(e);
        }
        // this.showSnackBar('An error ocurred while saving the data');
      },
    ); // error path);
  }

  // METHODS FOR USER FEEDBACK
  setProgressBar(status: string) {
    const action = {
      type: 'setProgressBar',
      msg: status,
    };
    this.overlayService.setAction(action);
  }

  showSnackBar(message: string) {
    const action = {
      type: 'openSnackBar',
      msg: message,
    };
    this.overlayService.setAction(action);
  }

  // METHODS THAT RETURN FORM ERROR MSGS
  getNameErrorMsg() {
    return this.full_name.hasError('required')
      ? this.translate.instant('formErrors.nameErrorMsg1')
      : this.full_name.hasError('minLength')
      ? this.translate.instant('formErrors.nameErrorMsg2')
      : '';
  }

  getRequiredMsg1(fieldId: string) {
    const field = this.formGroup1.get(fieldId);
    return field.hasError('required')
      ? this.translate.instant('formErrors.fieldRequired')
      : field.hasError('pattern')
      ? this.translate.instant('formErrors.onlySpaces')
      : '';
  }

  getRequiredMsg2(fieldId: string) {
    const field = this.formGroup2.get(fieldId);
    return field.hasError('required')
      ? this.translate.instant('formErrors.fieldRequired')
      : field.hasError('pattern')
      ? this.translate.instant('formErrors.onlySpaces')
      : '';
  }

  getEmailErrorMsg() {
    return this.email.hasError('required')
      ? this.translate.instant('formErrors.emailErrorMsg1')
      : this.email.hasError('email')
      ? this.translate.instant('formErrors.emailErrorMsg2')
      : '';
  }

  getPhoneErrorMsg() {
    return this.phone.hasError('required')
      ? this.translate.instant('formErrors.phoneReq')
      : this.phone.hasError('minlength')
      ? this.translate.instant('formErrors.minCarac')
      : '';
  }

  // Getters for the form fields
  get full_name() {
    return this.formGroup1.get('full_name');
  }

  get email() {
    return this.formGroup1.get('email');
  }

  get phone() {
    return this.formGroup1.get('phone');
  }

  get dob() {
    return this.formGroup1.get('dob');
  }

  get doc_id() {
    return this.formGroup1.get('doc_id');
  }

  get doc_type() {
    return this.formGroup1.get('doc_type');
  }

  get line1() {
    return this.formGroup2.get('line1');
  }

  get line2() {
    return this.formGroup2.get('line2');
  }

  get city() {
    return this.formGroup2.get('city');
  }

  get state() {
    return this.formGroup2.get('state');
  }

  get country() {
    return this.formGroup2.get('country');
  }

  get zipcode() {
    return this.formGroup2.get('zipcode');
  }

  // MOBILE
  get isMobile() {
    return this.platform.is('mobile');
  }
}
