<div class="content-container">
  <h3 class="heading-tertiary">{{ 'forgotPwdPage.title' | translate }}</h3>
  <form class="form-container" [style.fontSize.px]="14">
    <mat-form-field
      class="form__field"
      color="primary"
    >
      <mat-label>E-mail</mat-label>
      <input
        class="form__input"
        matInput
        placeholder="Type your e-mail"
        [formControl]="userEmail"
        type="email"
        required
      />
      <mat-error *ngIf="userEmail.invalid">
        {{ getEmailErrorMsg() }}
      </mat-error>
    </mat-form-field>
  </form>

  <div class="buttons-container">
    <button mat-button color="primary" (click)="goBackToLogin()">
      {{ 'forgotPwdPage.backButton' | translate | uppercase }}
    </button>
    <button
      mat-flat-button
      (click)="onSubmit()"
      color="primary"
      [disabled]="!userEmail.valid"
    >
    {{ 'forgotPwdPage.sendButton' | translate | uppercase }}
    </button>
  </div>

  <p class="error-msg" *ngIf="showErrMsg">
    {{ 'forgotPwdPage.bottomError' | translate }}
  </p>
</div>
