import { environment } from '../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';
import { CustomUser } from 'src/app/models/CustomUser.model';
import { ValidatePassword } from 'src/app/shared/validate-password';
import { OverlayService } from 'src/app/services/overlay.service';
import firebase from 'firebase/app';
import 'firebase/auth';
import { TranslateService } from '@ngx-translate/core';

declare global {
  interface Window {
    signupRecaptcha: any;
  }
}
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  formGroup1: FormGroup;
  formGroup2: FormGroup;
  showErrMsg = false;
  labelPos: string;
  gRecaptchaKey: string = environment.recaptchaKey;
  hasAgreed: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private overlayService: OverlayService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.hasAgreed = false;
    this.setUpForms();
    // setTimeout(() => this.renderRecaptcha(), 300);
  }

  renderRecaptcha() {
    const self = this;
    window.signupRecaptcha = new firebase.auth.RecaptchaVerifier(
      'signup-button',
      {
        size: 'invisible',
        callback: function (response) {
          self.showErrMsg = false;
          self.register().then(
            (response) => {
              self.setProgressBar('hide');
              if (response && response.message) {
                self.showErrMsg = true;
                self.showSnackBar(response.message);
                window.signupRecaptcha.reset();
              }
            },
            (reject) => {
              console.log('Rejected');
              self.setProgressBar('hide');
              if (reject && reject.message) {
                self.showErrMsg = true;
                self.showSnackBar(reject.message);
                window.signupRecaptcha.reset();
              }
            },
          );
        },
        'error-callback': (response) => {
          this.setProgressBar('hide');
          // this.showErrMsg = true;
        },
      },
    );
    window.signupRecaptcha.render();
  }

  // FORM STUFF
  setUpForms() {
    this.formGroup1 = new FormGroup(
      {
        full_name: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
        ]),
        email: new FormControl('', [
          Validators.required,
          Validators.email,
        ]),
        userPwd: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
        ]),
        userConfPwd: new FormControl('', [Validators.required]),
      },
      {
        validators: ValidatePassword.MatchPassword,
      },
    );
  }

  onSubmit() {
    this.showErrMsg = false;
    this.setProgressBar('show');
    window.signupRecaptcha.verify();
  }

  // Firebase Register (Create User)
  register() {
    const pwd = this.userPwd.value; // this wont be saved in DB, so it is sent separately
    const user = this.createNewUserData();
    // Removes two lines below if recatpcha is reactivated
    this.showErrMsg = false;
    this.setProgressBar('show');

    return this.authService.register(user, pwd).then(
      (res) => {
        console.log('User has been registered| result:', res);
        this.setProgressBar('hide');
        this.showSnackBar('You have registered successfully');
        this.router.navigate(['/main']);
      },
      (err) => {
        this.setProgressBar('hide');
        this.showSnackBar(err.message);
        return err;
      },
    );
  }

  createNewUserData(): CustomUser {
    const userData = {
      kyc: {
        personal_info: {
          email: this.email.value,
          full_name: this.full_name.value,
        },
      },
    };
    return userData;
  }

  getCurrentLanguage() {
    if (localStorage.getItem('language') != null) {
      return localStorage.getItem('language');
    } else {
      return this.translate.getDefaultLang();
    }
  }

  setProgressBar(status: string) {
    const action = {
      type: 'setProgressBar',
      msg: status,
    };
    this.overlayService.setAction(action);
  }

  showSnackBar(msg: string) {
    const action = {
      type: 'openSnackBar',
      msg: msg,
    };
    this.overlayService.setAction(action);
  }

  // METHODS THAT RETURN FORM ERROR MSGS
  getNameErrorMsg() {
    return this.full_name.hasError('required')
      ? this.translate.instant('formErrors.nameErrorMsg1')
      : this.full_name.hasError('minlength')
      ? this.translate.instant('formErrors.nameErrorMsg2')
      : '';
  }

  getRequiredMsg() {
    return this.full_name.hasError('required')
      ? this.translate.instant('formErrors.fieldRequired')
      : '';
  }

  getEmailErrorMsg() {
    return this.email.hasError('required')
      ? this.translate.instant('formErrors.emailErrorMsg1')
      : this.email.hasError('email')
      ? this.translate.instant('formErrors.emailErrorMsg2')
      : '';
  }

  getPwdErrorMsg() {
    return this.userPwd.hasError('required')
      ? this.translate.instant('formErrors.pwdErrorMsg1')
      : this.userPwd.hasError('minlength')
      ? this.translate.instant('formErrors.pwdErrorMsg2')
      : '';
  }

  getUserConfPwdErrorMsg() {
    return this.translate.instant('formErrors.pwdConfErrorMsg1');
  }

  // Getters for the form fields
  get full_name() {
    return this.formGroup1.get('full_name');
  }

  get email() {
    return this.formGroup1.get('email');
  }

  get userPwd() {
    return this.formGroup1.get('userPwd');
  }

  get userConfPwd() {
    return this.formGroup1.get('userConfPwd');
  }
}
