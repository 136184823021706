import { Component, OnInit, OnDestroy } from '@angular/core';
import { jsPDF } from 'jspdf';

import { AuthService } from 'src/app/services/auth.service';
import { CustomUser } from 'src/app/models/CustomUser.model';
import { Logger } from 'src/app/models/Logger.model';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { OverlayService } from 'src/app/services/overlay.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})

// export interface Fee {
//   bucket_fee?: any;
//   fixed_withdrawal_fee?: any;
//   invoice_fee?: any;
//   variable_withdrawal_fee?: any;
// }
export class ProfileComponent implements OnInit, OnDestroy {
  currentUserID: string;
  userData: CustomUser;
  userDataSubscription: any;
  log: Logger[];
  userFees = {
    BTC: null,
    ETH: null,
    SOY: null,
  };
  userFeesBTC = [];
  userFeesETH = [];
  userFeesSOY = [];
  isLoading: boolean;

  // PDF Stuff
  doc: any;
  numOfLines = 1;
  marginLeft = 10;
  marginTop = 15;
  lineVertSpace = 8;
  defaultFontSize = 10;

  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.currentUserID = this.authService.getCurrentUserID();

    this.isLoading = true;
    this.userDataSubscription = this.authService
      .getUserFromDbAsObs(this.currentUserID)
      .subscribe((userdata) => {
        this.userData = userdata;
        this.getLog(this.userData.uid);

        this.getAllUserFees();
      });
  }

  ngOnDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }

  getLog(userID: string) {
    this.isLoading = true;
    this.dataService.getUserLog(userID).subscribe((data) => {
      this.isLoading = false;
      this.log = data;
      this.log.forEach((item) => {
        if (item.created_at) {
          item.created_at = item.created_at.toDate();
        }
      });
      this.log.sort(this.sortByCreatedAt);
      // console.log('this.log: ', this.log);
    });
  }

  async getAllUserFees(): Promise<any> {
    this.userFeesBTC = await this.getUserFees('BTC').then((data) => {
      // console.log('userFeesBTC: ', data);
      return data;
    });
    this.userFeesETH = await this.getUserFees('ETH').then((data) => {
      // console.log('userFeesETH: ', data);
      return data;
    });
    this.userFeesSOY = await this.getUserFees('SOY').then((data) => {
      // console.log('userFeesSOY: ', data);
      return data;
    });
  }

  async getUserFees(coinID: string) {
    this.isLoading = true;
    const notDefinedTxt = this.translate.instant('kyc.PDFNotDefined');
    const result = await this.dataService
      .getUserSpecificFees(coinID, this.userData.uid)
      .then((data) => {
        this.isLoading = false;
        if (data) {
          data.bucket_fee
            ? (data.bucket_fee = data.bucket_fee)
            : (data.bucket_fee = notDefinedTxt);
          data.fixed_withdrawal_fee
            ? (data.fixed_withdrawal_fee = data.fixed_withdrawal_fee)
            : (data.fixed_withdrawal_fee = notDefinedTxt);
          data.invoice_fee
            ? (data.invoice_fee = data.invoice_fee)
            : (data.invoice_fee = notDefinedTxt);
          data.variable_withdrawal_fee
            ? (data.variable_withdrawal_fee =
                data.variable_withdrawal_fee)
            : (data.variable_withdrawal_fee = notDefinedTxt);
          this.userFees[coinID] = data;
          // console.log('this.userFees: ', this.userFees);
          return data;
        } else {
          // console.log('no custom Fees for this User');
          const noData = {
            bucket_fee: notDefinedTxt,
            fixed_withdrawal_fee: notDefinedTxt,
            invoice_fee: notDefinedTxt,
            variable_withdrawal_fee: notDefinedTxt,
          };
          this.userFees[coinID] = noData;
          return noData;
        }
      });
    return result;
  }

  sortByCreatedAt(a, b) {
    return b.created_at - a.created_at;
  }

  // PDF STUFF
  addLine(
    text: string,
    fontSize: number = 10,
    marginAdj: number = 0,
  ) {
    this.doc.setFontSize(fontSize);
    this.doc.text(
      text,
      this.marginLeft,
      this.marginTop +
        this.lineVertSpace * this.numOfLines +
        marginAdj,
    );
    this.numOfLines++;
    if (this.numOfLines > 33) {
      this.setNewPage();
    }
  }

  setNewPage() {
    this.doc.addPage();
    this.numOfLines = 1;
  }

  openDialog(content: string) {
    if (content === '2fa' && this.userData.has_2fa) {
      const message = this.translate.instant('two_factor.TwoFactorAlreadyActive');
      this.showSnackBar(message);
    } else {
      const action = {
        type: 'openDialog',
        msg: content,
      };
      this.overlayService.setAction(action);
    }
  }

  showSnackBar(message: string) {
    const action = {
      type: 'openSnackBar',
      msg: message,
    };
    this.overlayService.setAction(action);
  }

  public downloadAsPDF() {
    this.doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });
    // did this to solve a layout problem when created first page ;()
    this.setNewPage();
    this.doc.deletePage(1);

    // HEADER
    this.addLine(`${this.translate.instant('kyc.PDFTitle')}`, 14);
    this.addLine(
      `-------------------------------------------------------------------`,
      10,
      -5,
    );
    this.addLine(
      `${this.translate.instant('kyc.PDFDate')} ${new Date()}`,
      8,
      -10,
    );
    this.numOfLines = 3;

    // TITLE WITH USER NAME
    this.numOfLines++;
    this.addLine(
      `${this.translate.instant('kyc.PDFUser')} ${
        this.userData.kyc.personal_info.full_name
      }`,
      14,
    );

    // PERSONAL DATA
    this.numOfLines++;
    this.addLine(
      `${this.translate.instant('kyc.PDFSectionTitle1')}`,
      12,
    );
    this.addLine(`E-mail: ${this.userData.kyc.personal_info.email}`);
    this.addLine(
      `${this.translate.instant('kyc.phoneFormLabel')}: ${
        this.userData.kyc.personal_info.phone
      }`,
    );
    this.addLine(
      `${this.translate.instant('kyc.birthFormLabel')}: ${
        this.userData.kyc.personal_info.dob
      }`,
    );
    this.addLine(
      `${this.translate.instant('kyc.docIDFormLabel')}: ${
        this.userData.kyc.personal_info.doc_id
      }`,
    );
    this.addLine(
      `${this.translate.instant('kyc.docTypeFormLabel')}: ${
        this.userData.kyc.personal_info.doc_type
      }`,
    );

    // ADDRESS DATA
    this.numOfLines++;
    this.addLine(
      `${this.translate.instant('kyc.PDFSectionTitle2')}`,
      12,
    );
    this.addLine(
      `${this.userData.kyc.address.line1}. ${this.userData.kyc.address.line2}`,
    );
    this.addLine(
      `${this.userData.kyc.address.city}, ${this.userData.kyc.address.state}, ${this.userData.kyc.address.country}`,
    );
    this.addLine(
      `${this.translate.instant('kyc.zipFormLabel')}: ${
        this.userData.kyc.address.zipcode
      }`,
    );

    // CUSTOM FEES
    this.numOfLines++;
    this.addLine(
      `${this.translate.instant('kyc.PDFSectionTitle3')}`,
      12,
    );
    this.showReportOnCustomFees();

    // HISTORY / TRANSACTIONS
    this.numOfLines++;
    this.addLine(
      `${this.translate.instant('kyc.PDFSectionTitle4')}`,
      12,
    );

    this.log.forEach((item) => {
      // sometimes message has txid and its too big for the line...
      const textAray = this.splitText(item.Message);
      textAray.forEach((text, index) => {
        if (index === 0) {
          this.addLine(`Item: ${text}`);
        } else {
          this.addLine(`${text}`);
        }
      });

      this.addLine(
        `${this.translate.instant('admin-table.createAt')}: ${
          item.created_at
        }`,
      );
      this.addLine(`ID: ${item.id}`);
      this.numOfLines++;
    });

    // SAVE DATA AS PDF
    this.doc.save('userData.pdf');
  }

  showReportOnCustomFees() {
    Object.entries(this.userFees).forEach((item) => {
      this.addLine(`${item[0]}`);
      this.addLine(
        `${this.translate.instant('kyc.PDFBucketFee')}: ${
          item[1].bucket_fee
        }`,
      );
      this.addLine(
        `${this.translate.instant('kyc.PDFInvoiceFee')}: ${
          item[1].invoice_fee
        }`,
      );
      this.addLine(
        `${this.translate.instant('kyc.PDFFixWithdFee')}: ${
          item[1].fixed_withdrawal_fee
        }`,
      );
      this.addLine(
        `${this.translate.instant('kyc.PDFVarWithdFee')}: ${
          item[1].variable_withdrawal_fee
        }`,
      );
      this.numOfLines++;
    });
  }

  splitText(text: string) {
    if (text.length > 100) {
      const textChunks = text.match(/.{1,100}(\s|$)/g);
      return textChunks;
    } else {
      return [text];
    }
  }

  get isMobile() {
    return this.platform.is('mobile');
  }
}
