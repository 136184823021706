import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  showErrMsg = false;
  viewInitiated = false;
  userEmail = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  constructor(
    private router: Router,
    private authService: AuthService,
    private overlayService: OverlayService,
    private translate: TranslateService
  ) {}

  ngOnInit() {}

  goBackToLogin() {
    this.router.navigate(['/auth/login']);
  }

  onSubmit() {
    this.setProgressBar('show');
    this.authService
      .sendResetPwdEmail(this.userEmail.value)
      .then((result) => {
        this.setProgressBar('hide');
        this.showSnackBar('If this address is registered on our platform, you will receive an e-mail with password reset instructions');
      })
      .catch((error) => {
        console.log(error);
        this.setProgressBar('hide');
        this.showSnackBar(
          'Sorry, an error occured, please try again later',
        );
      });
  }

  getEmailErrorMsg() {
    return this.userEmail.hasError('required')
      ? this.translate.instant('formErrors.emailErrorMsg1')
      : this.userEmail.hasError('email')
      ? this.translate.instant('formErrors.emailErrorMsg2')
      : '';
  }

  setProgressBar(status: string) {
    const action = {
      type: 'setProgressBar',
      msg: status,
    };
    this.overlayService.setAction(action);
  }

  showSnackBar(message: string) {
    const action = {
      type: 'openSnackBar',
      msg: message,
    };
    this.overlayService.setAction(action);
  }
}
