<div class="invests">
  <div class="content">

    <div class="content__text">

      <h2 style='color:rgb(241, 205, 0);font-size:1.6em'>FORTUNE 36:</h2> 
      <br>
        <div class="investmentsData">{{ 'investments.investmentsValue' | translate }}: <div class='rgt'><b>{{ userData.fortune }}</b> SOYA</div></div>
        <div class="investmentsData">{{ 'investments.redemptionDate' | translate }}: <div class='rgt'>{{ userData.fortune_redemptionDate }}</div></div>
    </div>

  </div>
</div>