<div class="sell">
  <div class="sell__main">
    <div class="sell__step sell__howmuch">
      <p class="sell__title">
        {{ 'sell.title1' | translate }} <span class="notranslate">(Soya Coins)</span>
      </p>
      <form [formGroup]="sellFormGroup" class="notranslate">
        <mat-form-field class="sell__ff" appearance="fill">
          <input
            class="text-right-align"
            matInput
            #spendInput
            [formControl]="spending"
            inputmode="decimal"
            [step]="stepIncrement"
            class="text-right-align"
            (keyup.enter)="evaluateInput(spendInput.value)"
            (blur)="evaluateInput(spendInput.value)"
          />
          <mat-hint align="end" class="notranslate"
            >{{ 'sell.minValueHint' | translate }}  {{ minAmount }} SOY</mat-hint
          >
        </mat-form-field>
      </form>
    </div>

    <div class="sell__step sell__payWith">
      <p class="sell__title">{{ 'sell.title2' | translate }}</p>
      <mat-form-field class="sell__ff" appearance="fill">
        <mat-select
          (selectionChange)="buyCoinWasSelected($event)"
          [(value)]="purchasedCoin"
        >
          <mat-option value="BTC" class="notranslate"
            >BITCOIN</mat-option
          >
          <mat-option value="ETH" class="notranslate"
            >ETHER</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div class="sell__step">
      <p class="sell__title">{{ 'sell.title3' | translate }}</p>
      <p class="sell__total" [hidden]="!displayTotal">
        {{
          floor10(
            sub(purchaseAmount, multiply(pctFee, purchaseAmount)),
            -6
          )
        }}
        {{ purchasedCoin }}
      </p>
      <mat-hint [hidden]="!displayTotal" translate [translateParams]="{amount: floor10(multiply(pctFee, 100), -2)}"
        >sell.feeHint</mat-hint
      >
    </div>
  </div>

  <div class="result mb-lg mt-lg">
    <div class="balance mr-lg">
      <div class="balance__buttons">
        <div class="balance__btn" (click)="inputPreset(0.25)">
          25%
        </div>
        <div class="balance__btn" (click)="inputPreset(0.5)">50%</div>
        <div class="balance__btn" (click)="inputPreset(0.75)">
          75%
        </div>
        <div class="balance__btn" (click)="inputPreset(1)">100%</div>
      </div>
      <div class="balance__info">
        <p class="balance__info--label">{{ 'sell.balanceTxt' | translate }}</p>
        <p
          [ngClass]="{
            'balance__info--value': true,
            'balance__info--blink': blinkBalance
          }"
        >
          {{ floor10(getBalance('SOY'), -6) }}
          SOY
        </p>
      </div>
    </div>
  </div>

  <div class="sell__final">
    <button
      class="btn"
      mat-flat-button
      color="primary"
      (click)="consumeBucket()"
      [disabled]="
        getBalance('SOY') <= minAmount ||
        spending.value > getBalance('SOY')
      "
    >
    {{ 'sell.button1' | translate | uppercase }}
    </button>
  </div>
  <div
    class="blocked"
    [style.opacity]="spending.value > getBalance('SOY') ? 1 : 0"
  >
    <p class="blocked__msg">{{ 'sell.mainError' | translate | uppercase }}</p>
  </div>
</div>
