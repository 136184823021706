<div class="container">
  <!-- CHAT -->
  <app-chat [showChat]="showChat" (toggleChat)="hideChat()"></app-chat>

  <header class="header">
    <a class="pointer" (click)="navigateTo('dashboard')">
      <img class="header__logo" src="../../../assets/soyacoin_logo_h_white.png" alt="company logo" />
    </a>
    <div class="userbar userbar__btn--light">
      <div class="options-left">
        <app-lang-switch class="mr-lg"></app-lang-switch>

        <ion-icon class="icon-lg mr-lg littleBig" name="help-circle-outline" matTooltip="FAQ" (click)="redirectToSoya()"></ion-icon>

        <ion-icon class="icon-lg mr-lg" name="cash-outline" matTooltip="Coin Converter"
          (click)="openDialog('coinconv')"></ion-icon>

        <img class="icon-talk mr-lg" src="../../../assets/img/fortune.svg" onmouseover="this.src='../../../assets/img/fortune_over.svg'" onmouseout="this.src='../../../assets/img/fortune.svg'" width=22 alt="talk icon"
          (click)="openDialog('invest')"
          matTooltip="Investments"
          *ngIf="userData.fortune_redemptionDate"/>

        <ion-icon class="icon-lg mr-lg" name="notifications-outline" matTooltip="Notifications"
          (click)="openDialog('notifs')">
        </ion-icon>

        <img src="../../../assets/img/talk_icon.png" class="icon-talk mr-lg" alt="talk icon" (click)="toggleChat()" />

      </div>
      <div class="options-right">
        <p class="userbar__text">
          {{ 'userMenu.greeting' | translate }} &nbsp;<span *ngIf="this.currentUserName"
            class="userbar__name">{{ currentUserName }}</span>
        </p>
        <button class="userbar__btn userbar__btn--light" mat-icon-button aria-label="user icon"
          [matMenuTriggerFor]="menu">
          <ion-icon class="icon-lg" name="person-circle-outline"></ion-icon>
        </button>
        <mat-menu backdropClass="userMenu" #menu="matMenu">
          <mat-list>
            <mat-list-item class="userbar__item">
              <button class="userbar__btn userbar__btn--dark" (click)="navigateTo('dashboard')" mat-icon-button
                aria-label="settings button">
                <ion-icon class="icon-sm" name="home-outline"></ion-icon>
                {{ 'userMenu.link1' | translate }}
              </button>
            </mat-list-item>
            <mat-list-item class="userbar__item">
              <button class="userbar__btn userbar__btn--dark" (click)="navigateTo('profile')" mat-icon-button
                aria-label="settings button">
                <ion-icon class="icon-sm" name="file-tray-full-outline"></ion-icon>
                {{ 'userMenu.link2' | translate }}
              </button>
            </mat-list-item>
            <mat-list-item class="userbar__item">
              <button (click)="logout()" mat-icon-button class="userbar__btn" aria-label="logout button">
                <ion-icon class="icon-sm" name="log-out-outline"></ion-icon>
                {{ 'userMenu.link3' | translate }}
              </button>
            </mat-list-item>
          </mat-list>
        </mat-menu>
      </div>
    </div>
  </header>

  <div class="main">
    <router-outlet></router-outlet>
  </div>

  <footer class="footer">
    <div class="footer__links">
      <a href="https://soya-coin.com/privacy" target="_blank">{{
        'footer.link1' | translate
      }}</a>
      <a href="https://soya-coin.com/terms" target="_blank">{{
        'footer.link2' | translate
      }}</a>
      <a href="https://soya-coin.com/contact" target="_blank">{{
        'footer.link3' | translate
      }}</a>
    </div>
    <p class="footer__company">
      <span class="footer__company--name">{{
        'footer.company' | translate
      }}</span>{{ 'footer.copyright' | translate }}
    </p>
  </footer>
</div>
