import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  notifications: any[];
  userID: string;

  constructor(private translate: TranslateService) {}

  intializeNotifStatus() {
    this.userID = localStorage.getItem('currentUserID');
    // console.log(this.userID);
    this.setNotifications();

    if (localStorage.getItem(`${this.userID}_notifStatus`) === null) {
      const notifStatus = [
        { id: 'PENDING', status: true },
        { id: 'ANALYZING', status: true },
        { id: 'REJECTED', status: true },
        { id: 'APPROVED', status: true },
      ];
      this.saveNotifStatus(notifStatus);
    }
  }

  async updateNotifStatus(id: string, status: boolean) {
    const notifStatus = await JSON.parse(
      localStorage.getItem(`${this.userID}_notifStatus`),
    );
    if (notifStatus) {
      for (let i = 0; i < notifStatus.length; i++) {
        if (notifStatus[i].id === id) {
          notifStatus[i].status = status;
          break;
        }
      }
      this.saveNotifStatus(notifStatus);
    }
  }

  getNotifStatus(id: string) {
    const notifStatus = JSON.parse(
      localStorage.getItem(`${this.userID}_notifStatus`),
    );
    const foundItem = notifStatus.find((item) => item.id === id);
    return foundItem.status;
  }

  saveNotifStatus(data: any) {
    localStorage.setItem(
      `${this.userID}_notifStatus`,
      JSON.stringify(data),
    );
  }

  loadNotifStatus() {
    return JSON.parse(
      localStorage.getItem(`${this.userID}_notifStatus`),
    );
  }

  getNotification(id: string) {
    // this.setNotifications();
    const notif = this.notifications.find((item) => item.id === id);
    return notif;
  }

  setNotifications() {
    this.notifications = [
      {
        id: 'PENDING',
        msgTitle: this.translate.instant(
          'notifications.kyc-pending-title',
        ),
        msgDescr: this.translate.instant('notifications.kyc-pending'),
        msgLink: '/main/profile',
        msgAdditionalInfo: '',
      },
      {
        id: 'ANALYZING',
        msgTitle: this.translate.instant(
          'notifications.kyc-analyzing-title',
        ),
        msgDescr: this.translate.instant(
          'notifications.kyc-analyzing',
        ),
        msgLink: '/main/profile',
        msgAdditionalInfo: '',
      },
      {
        id: 'REJECTED',
        msgTitle: this.translate.instant(
          'notifications.kyc-rejected-title',
        ),
        msgDescr: this.translate.instant(
          'notifications.kyc-rejected',
        ),
        msgLink: '/main/profile',
        msgAdditionalInfo: '',
      },
      {
        id: 'APPROVED',
        msgTitle: this.translate.instant(
          'notifications.kyc-approved-title',
        ),
        msgDescr: this.translate.instant(
          'notifications.kyc-approved',
        ),
        msgLink: '/main/profile',
        msgAdditionalInfo: '',
      },
    ];
  }
}
