<!-- CONTENT FOR ACTIONS (BUY, DEPOSIT, ETC.) -->
<div 
  class="action"
  *ngIf="this.data.type != 'userSheet' && this.data.type != 'userLog' && this.data.type != 'campaign'"
>
  <div class="action__header">
    <p>{{ this.actionModalTitle | uppercase }}</p>
    <button
      mat-icon-button
      mat-dialog-close
      aria-label="close icon - button"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div *ngIf="this.data.type == 'welcome'" class="videoWrapper">
    <iframe
      src="https://www.youtube.com/embed/FM_pRDl-p4g"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
  <div class="action__content" *ngIf="this.data.type != 'welcome' && this.data.type != 'campaign'">
    <app-deposit *ngIf="this.data.type === 'deposit'"></app-deposit>
    <app-buy
      (close)="onClose()"
      *ngIf="this.data.type === 'buy'"
    ></app-buy>
    <app-sell
      (close)="onClose()"
      *ngIf="this.data.type === 'sell'"
    ></app-sell>
    <app-withdraw
      (close)="onClose()"
      *ngIf="this.data.type === 'withdraw'"
      [qrCode]="data.props"
    ></app-withdraw>
    <app-pay
      (close)="onClose()"
      *ngIf="this.data.type === 'pay'"
      [qrCode]="data.props"
    ></app-pay>
    <app-receive
      *ngIf="this.data.type === 'receive'"
      [invoice]="data.props"
    ></app-receive>

    <!-- COIN CONVERTER -->
    <app-coin-converter
      *ngIf="this.data.type === 'coinconv'"
    ></app-coin-converter>

    <!-- INVESTMENTS -->
    <app-investments
      *ngIf="this.data.type === 'invest'"
    ></app-investments>

    <!-- NOTIFICATIONS -->
    <app-notifications
      *ngIf="this.data.type === 'notifs'"
    ></app-notifications>

    <app-link-two-fa
      (close)="onClose()"
      *ngIf="this.data.type === '2fa'"
    ></app-link-two-fa>

    <!-- EXCLUDE USER DATA INFO -->
    <div *ngIf="this.data.type === 'dataexclusion'">
      <h2 class="mb-md">Information on Userdata Exclusion</h2>
      <p>{{ 'kyc.excludeTextFull' | translate }}</p>
    </div>
  </div>
</div>

<div *ngIf="this.data.type == 'campaign'" class="actionCampaign__content">
  <div class="campaign" style="background:radial-gradient(circle, #1c68b6, #0e3157);background-size:cover;color:white;font-size:11pt;padding:5%;padding-bottom:0px">
    <button style='float:right;z-index:10000;position:relative;top:-25px;margin:5px'
      mat-icon-button
      mat-dialog-close
      aria-label="close icon - button">
      <mat-icon>close</mat-icon>
    </button>
    <div class="campContent">
      <div id='case' class='uk-height-1-1' uk-grid>
        <div class='uk-width-expand' style="color:white;font-size:12pt;margin-left:15px;padding:0px">
          <p style='margin-left:10px'><span style='color:#F6C443;font-size:24pt'><b>{{ 'campaign.title' | translate }} {{ 'campaign.text1' | translate }} SoyaCoins.</b></span></p>
          <ul class="uk-list uk-list-disc uk-list-collapse">
            <li>{{ 'campaign.content1a' | translate }}</li>
            <li>{{ 'campaign.content1b' | translate }}</li>
            <li>{{ 'campaign.content1c' | translate }}</li>
            <li>{{ 'campaign.content1d' | translate }}</li>
            <li>{{ 'campaign.content1e' | translate }}</li>
            <li>{{ 'campaign.content1f' | translate }}</li>
          </ul>
          <div style='text-align:center'>
            <br>
            <button uk-toggle='target: #case; animation: uk-animation-slide-left, uk-animation-slide-right' style='font-size:13pt;border:1px solid #ffffff;padding:5px 15px;background:#F6C443;color:#000000;border-radius:4px;width:240px'>{{ 'campaign.content2' | translate }}</button>
            <br><br>
            <mat-icon style="font-size:18pt">calendar_today</mat-icon> &nbsp; {{ 'campaign.content3' | translate }} 05.03.2023
          </div>
        </div>
        <div class='uk-width-2-5' style="background:URL('../../../assets/img/back_camp.png') right no-repeat;background-size:contain;"></div>
      </div>

      <div id='case' class='uk-width-1-1 uk-height-1-1 uk-padding-remove uk-margin-remove' style="color:white;font-size:11pt;overflow-y:hidden" hidden>
        <button style='z-index:10000;position:relative;top:-45px;margin:45px' uk-toggle='target: #case; animation: uk-animation-slide-left, uk-animation-slide-right'
          mat-icon-button
          mat-dialog-replay
          aria-label="replay icon - button">
          <mat-icon>replay</mat-icon>
        </button>
        <p class='sTit'>{{ 'campaign.contTitle1a' | translate }} <b>{{ 'campaign.contTitle1b' | translate }}</b>.</p>
          <br>
        <p class='sTit'>{{ 'campaign.contTitle2' | translate }}</p> 
          {{ 'campaign.contText2a' | translate }}<br> 
          {{ 'campaign.contText2b' | translate }}<br>
          <br>
        <p class='sTit'>{{ 'campaign.contTitle3' | translate }}</p> 
          1. &nbsp; {{ 'campaign.contText3a' | translate }}<br> 
          2. &nbsp; {{ 'campaign.contText3b' | translate }}<br>
          3. &nbsp; {{ 'campaign.contText3c' | translate }}<br>
          4. &nbsp; {{ 'campaign.contText3d' | translate }}<br>
          5. &nbsp; {{ 'campaign.contText3e' | translate }}<br>
          <br>
        <p class='sTit'>{{ 'campaign.contTitle4' | translate }}</p> 
          1. &nbsp; {{ 'campaign.contText4a' | translate }}<br> 
          2. &nbsp; {{ 'campaign.contText4b' | translate }}<br>
          <br>
        <p class='sTit'>{{ 'campaign.contTitle5' | translate }}</p> 
          {{ 'campaign.contText5a' | translate }}<br> 
          {{ 'campaign.contText5b' | translate }}<br>
          {{ 'campaign.contText5c' | translate }}<br>
          <br>
        <p class='sTit'>{{ 'campaign.contTitle6' | translate }}</p> 
          {{ 'campaign.contText6' | translate }}<br> 
        <br>
          <div style="text-align:center">
            <button class="myAgree" uk-toggle="target: .myAgree; animation: uk-animation-fade"
              style="padding:15px 30px;margin-top:5px;border-radius:5px;font-size:15pt;background:rgb(241,205,0);color:black"
              (click)="onAgree()">
              {{ 'campaign.textAgree' | translate }}
            </button>
            <span class="myAgree" uk-icon="icon: check; ratio: 3" hidden></span>
            <br><br><br>
          </div>
      </div>
    </div>
  </div>
</div>
