import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CustomUser } from '@models/CustomUser.model';

@Component({
  selector: 'app-investments',
  templateUrl: 'investments.component.html',
  styleUrls: ['./investments.component.scss']
})

export class InvestmentsComponent implements OnInit {
  currentUserID: string
  userData: CustomUser
  userDataSubscription: any

  constructor(
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.currentUserID = this.authService.getCurrentUserID()
    this.userDataSubscription = this.authService
      .getUserFromDbAsObs(this.currentUserID)
      .subscribe((userdata) => {
        this.userData = userdata
      });
  }

}
