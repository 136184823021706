<main class="welcome">
  <div class="welcome-content">
    <div class="welcome-intro">
      <h1>{{ 'confEmail.greeting' | translate }}</h1>
      <h2 class="name">
        {{
          userData && userData.kyc
            ? userData.kyc.personal_info.full_name
            : '...'
        }}
      </h2>
      <p class="email">
        {{
          userData && userData.kyc
            ? userData.kyc.personal_info.email
            : '...'
        }}
      </p>
      <section class="welcome-block">
        <h4>{{ 'confEmail.title' | translate }}</h4>
        <p>
          {{ 'confEmail.text1' | translate }}
          <span class="welcome-important"
            >{{ 'confEmail.text2' | translate }}</span
          >
        </p>
      </section>
    </div>
    <div class="welcome-action">
      <h4 class="welcome-action__title">
        {{ 'confEmail.question' | translate }}
      </h4>
      <button
        class="welcome-action__btn"
        mat-flat-button
        (click)="reloadPage()"
        color="primary"
      >
      {{ 'confEmail.checkNowButton' | translate }}
      </button>
    </div>
    <div class="welcome-instructions">
      <section class="welcome-block">
        <h6>{{ 'confEmail.howToTitle' | translate }}</h6>
        <p class="welcome-instructions__description">
          {{ 'confEmail.howToText1' | translate }}
        </p>
      </section>
      <section class="welcome-block">
        <h6>{{ 'confEmail.howToQuestion' | translate }}</h6>
        <p class="welcome-instructions__description">
          {{ 'confEmail.howToText2' | translate }}
        </p>
      </section>
      <div class="welcome-resend mt-lg">
        <button
          mat-stroked-button
          (click)="sendEmailVerification()"
          color="secondary"
        >
        {{ 'confEmail.resendButton' | translate }}
        </button>
        <p class="feedback-txt" *ngIf="sendingEmail">
          {{ 'confEmail.waitSend' | translate }}
        </p>
      </div>
    </div>
  </div>
</main>
