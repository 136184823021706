import { Component, OnInit, AfterViewInit, Inject, ViewChild, EventEmitter, } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { DialogData } from 'src/app/models/DialogData.model';
import { AuthService } from 'src/app/services/auth.service';
import { CustomUser } from 'src/app/models/CustomUser.model';
import { BucketTrade } from 'src/app/models/BucketTrade.model';
import { TranslateService } from '@ngx-translate/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, AfterViewInit {
  closeWindowString = 'close';
  userData: CustomUser;
  bucketTrades: BucketTrade[];
  actionModalTitle: string;
  currentUserID: string;

  constructor(
    overlayContainer: OverlayContainer,
    private afs: AngularFirestore,
    private authService: AuthService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.dialogRef.disableClose = true;
    overlayContainer
      .getContainerElement()
      .classList.add('sc-dark-theme');
  }

  ngOnInit() {
    this.actionModalTitle = this.translate.instant(`actionModalsHeader.${this.data.type}`)
    this.currentUserID = this.authService.getCurrentUserID()
  }

  ngAfterViewInit() {
    this.dialogRef.afterClosed().subscribe((result) => {
      // console.log(`Dialog closed`);
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  onAgree() {
    this.afs.doc('users/'+this.currentUserID).update({ campaign_agree: true })
  }

}
