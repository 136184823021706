<main class="welcome">
  <div class="welcome-content">
    <div class="welcome-intro">
      <h1>Hello,</h1>
      <h2 class="name">
        {{
          userData && userData.kyc
            ? userData.kyc.personal_info.full_name
            : '...'
        }}
      </h2>
      <section class="welcome-block">
        <h4>
          {{ 'validatePhone.text1' | translate }}
          <span class="welcome-important"
            >{{ 'validatePhone.text2' | translate }}</span
          >
        </h4>
      </section>
    </div>
    <div class="welcome-action">
      <form [formGroup]="phoneFormGroup" class="form mr-md">
        <mat-form-field class="form__country mr-lg">
          <mat-select
            placeholder="{{ 'validatePhone.countryFormLabel' | translate }}"
            [formControl]="country"
            (selectionChange)="countryWasSelected($event.value)"
          >
            <mat-option
              *ngFor="let countryName of countryNames"
              [value]="countryName"
            >
              {{ countryName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="form__code mr-sm">
          <input
            matInput
            placeholder="XX"
            [formControl]="countryCode"
            type="number"
            required
          />
          <span matPrefix>+</span>
          <mat-error *ngIf="countryCode.invalid">
            {{ getCountryCodeErrorMsg() }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="form__phone">
          <mat-label>{{ 'validatePhone.phoneFormLabel' | translate }}</mat-label>
          <input
            matInput
            placeholder="XX XXXXX XXXX"
            [formControl]="userPhoneNumber"
          />
          <mat-error *ngIf="userPhoneNumber.invalid">
            {{ getPhoneErrorMsg() }}
          </mat-error>
        </mat-form-field>
      </form>

      <!-- (click)="sendValidationCode()" -->
      <button
        mat-flat-button
        id="send-sms-button"
        color="primary"
        class="validation-button mb-lg"
        type="submit"
        [disabled]="!userPhoneNumber.valid || !countryCode.valid"
      >
      {{ 'validatePhone.button1' | translate | uppercase }}
      </button>
      <form [formGroup]="SMSCodeFormGroup" *ngIf="displayCodeForm">
        <mat-form-field>
          <mat-label>{{ 'validatePhone.codeFormLabel' | translate }}</mat-label>
          <input
            matInput
            placeholder="XXXXXX"
            [formControl]="SMSCode"
          />
        </mat-form-field>
        <button
          mat-stroked-button
          color="secondary"
          class="validation-button"
          type="button"
          (click)="submitValidationCode()"
        >
        {{ 'validatePhone.button2' | translate | uppercase }}
        </button>
        <div class="g-recaptcha" *ngIf="gRecaptchaKey" [attr.data-sitekey]="gRecaptchaKey"></div>
      </form>
    </div>
    <div class="welcome-instructions">
      <section class="welcome-block">
        <h6>{{ 'validatePhone.instructionsTitle' | translate }}</h6>
        <p class="welcome-instructions__description">
          {{ 'validatePhone.instructionsText' | translate }}
        </p>
      </section>
    </div>
  </div>
</main>
<!-- <div id="dashboard-recaptcha-container"></div> -->
<!-- <div class="g-recaptcha" [attr.data-sitekey]="gRecaptchaKey"></div> -->
