import { Component, OnInit } from '@angular/core';

export interface DialogData {
  youtubeVideo: string;
}
import { OverlayService } from 'src/app/services/overlay.service';

@Component({
  selector: 'app-tutorial',
  templateUrl: 'tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
})
export class TutorialComponent implements OnInit {
  constructor(private overlayService: OverlayService) {}

  ngOnInit() {}

  openDialog() {
    const action = {
      type: 'openDialog',
      msg: 'welcome',
    };
    this.overlayService.setAction(action);
  }
}
