import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';

import { environment } from '../../environments/environment';

import { InvoiceChannel } from '../models/InvoiceChannel.model';
import { PaidInvoice } from '../models/PaidInvoice.model';
import { InvoiceRevenue } from '../models/InvoiceRevenue.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  baseURL = environment.baseURL;

  constructor(
    private afs: AngularFirestore,
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  async createInvoice(
    invoiceName: string,
    assetID: string,
    amt: number,
    pub: boolean,
  ): Promise<Observable<any>> {
    const invoiceURL = `${this.baseURL}/Invoice/create_invoice`;

    const invoiceData = {
      name: invoiceName,
      asset: assetID,
      amount: amt,
      public: pub,
    };
    const httpOptions = await this.authService.getAuthHeaders();

    const strJSON = JSON.stringify(invoiceData);
    return this.http.post<any>(invoiceURL, strJSON, httpOptions);
  }

  async payInvoice(
    code: string,
    amt: number,
  ): Promise<Observable<any>> {
    const invoiceURL = `${this.baseURL}/Invoice/pay_invoice`;
    const invoiceData = {
      invoice_hash: code,
      amount: amt,
    };
    const httpOptions = await this.authService.getAuthHeaders();

    return this.http.post<any>(
      invoiceURL,
      JSON.stringify(invoiceData),
      httpOptions,
    );
  }

  getInvoices(userID: string): Observable<InvoiceChannel[]> {
    const invoicesRef = this.afs.collection<any>('invoices/', (ref) =>
      ref.where('payee_id', '==', userID),
    );

    const invoices = invoicesRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as InvoiceChannel;
          data.id = action.payload.doc.id;
          data.is_invoice = true;
          if (data.created_at) {
            data.created_at = (data.created_at as any).toDate();
          }
          return data;
        });
      }),
    );
    return invoices;
  }

  // this gets the user as an Observable
  getInvoice(id: string): Observable<InvoiceChannel> {
    const invoiceDoc = this.afs.doc<any>(`invoices/${id}`);
    const invoiceObs = invoiceDoc.snapshotChanges().pipe(
      map((action) => {
        if (action.payload.exists === false) {
          throw 'The invoice you are trying to pay does not exist';
        } else {
          const data = action.payload.data() as InvoiceChannel;
          data.id = action.payload.id;
          return data;
        }
      }),
    );
    return invoiceObs;
  }

  getInvoicesRevenue(userID: string): Observable<InvoiceRevenue[]> {
    const revenueRef = this.afs.collection(
      `/users/${userID}/invoices_revenue`,
    );
    const receivedFromInvoices = revenueRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as InvoiceRevenue;
          data.created_at = (data.created_at as any).toDate();
          data.side = 'RECEIVED';
          return data;
        });
      }),
    );
    return receivedFromInvoices;
  }

  getInvoicesPayments(userID: string): Observable<PaidInvoice[]> {
    const paidInvoicesRef = this.afs.collection(
      `/users/${userID}/invoices_paid`,
    );
    const paidInvoices = paidInvoicesRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as PaidInvoice;
          data.created_at = (data.created_at as any).toDate();
          data.side = 'SPENT';
          return data;
        });
      }),
    );
    return paidInvoices;
  }
}
