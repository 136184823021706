import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'soyacoin';
  curLanguage: string;
  constructor(
    private translate: TranslateService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
  ) 
  {
    if (localStorage.getItem('language') != null) {
      // there is a language prev defined, set to it
      this.curLanguage = localStorage.getItem('language');
      this.translate.setDefaultLang(this.curLanguage);
      this.translate.use(this.curLanguage);
    } else {
      // no language prev defined, set to english
      this.translate.setDefaultLang('en');
      this.translate.use('en');
      localStorage.setItem('language', 'en');
    }
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then((res) => {
      this.statusBar.styleBlackTranslucent();
      setTimeout(() => {
        this.splashScreen.hide();
      }, 1000);
    });
  }
}
