import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lang-switch',
  templateUrl: './lang-switch.component.html',
  styleUrls: ['./lang-switch.component.scss']
})
export class LangSwitchComponent implements OnInit {
  activeLang: string;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    if (localStorage.getItem('language') != null) {
      // there is a language in the cookie, get from it
      this.activeLang = localStorage.getItem('language');

    } else {
      // no language prev defined, get from app
      this.activeLang = this.translate.getDefaultLang();
      localStorage.setItem(
        'language',
        this.activeLang,
      );
    }
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.translate.setDefaultLang(language);
    localStorage.setItem(
      'language',
      language,
    );
    this.activeLang = language;
  }

}
