import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { DataService } from 'src/app/services/data.service';
import { floor10 } from '../../custom/Utils';
import _ from 'underscore';

@Component({
  selector: 'app-coin-converter',
  templateUrl: './coin-converter.component.html',
  styleUrls: ['./coin-converter.component.scss'],
})
export class CoinConverterComponent implements OnInit {
  floor10 = floor10;
  form: FormGroup;
  stepIncrement = 1;
  quotesSubscription: any;
  quotes: any;
  curRate: any;
  convertedAmount: number;

  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.setUpForms({
      inputCoin: 'BTC',
      inputAmount: 1,
      outputCoin: 'USD',
    });
    this.getQuotesAsObs();
  }

  getQuotesAsObs() {
    this.quotesSubscription = this.dataService
      .getQuotesAsObs()
      .subscribe((quotes) => {
        this.quotes = _.filter(quotes, (quote) => { return quote.base === 'BTC' || quote.base === 'ETH' || quote.base === 'SOY' ; });
        // this.quotes = quotes;
        this.convertedAmount = this.calcConversionRate(
          this.inputCoin.value,
          1,
          this.outputCoin.value,
        );
      });
  }

  calcConversionRate(
    inputCoinID: string,
    inputAmount: number,
    outputCoinID: string,
  ) {
    if (inputCoinID === outputCoinID) {
      return 1;
    } else {
      const coinData = this.quotes.find(
        (item) => item.base === inputCoinID,
      );
      const rates = coinData.rates;
      this.curRate = rates[outputCoinID];
      const outputAmount = this.curRate * inputAmount;
      return outputAmount;
    }
  }

  // FORM STUFF
  setUpForms(defaultValue?: any) {
    this.form = new FormGroup({
      inputCoin: new FormControl('', [Validators.required]),
      inputAmount: new FormControl('', [Validators.required]),
      outputCoin: new FormControl('', [Validators.required]),
    });
    this.form.patchValue(defaultValue);
    this.onChanges();
  }

  get inputCoin() {
    return this.form.get('inputCoin');
  }

  get inputAmount() {
    return this.form.get('inputAmount');
  }

  get outputCoin() {
    return this.form.get('outputCoin');
  }

  // get outputAmount() {
  //   return this.form.get('outputAmount');
  // }

  onChanges(): void {
    this.form.valueChanges.subscribe((val) => {
      this.convertedAmount = this.calcConversionRate(
        val.inputCoin,
        val.inputAmount,
        val.outputCoin,
      );
    });
  }
}
