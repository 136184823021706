<div [ngClass]="{ container: true, show: showChat }">
  <header class="header">
    <div class="header--actions">
      <div class="left">
        <p class="heading-secondary text-light mr-lg">TALK</p>
        <button
          *ngIf="UImode === 'standby'"
          class="btn"
          mat-stroked-button
          color="primary"
          (click)="setUI('create')"
        >
          {{ 'chat.createBtn' | translate }}
        </button>
        <button
          *ngIf="UImode === 'standby'"
          class="btn"
          mat-stroked-button
          color="primary"
          (click)="setUI('join')"
        >
          {{ 'chat.joinBtn' | translate }}
        </button>
        <button
          *ngIf="UImode !== 'standby'"
          class="btn"
          mat-button
          color="primary"
          (click)="setUI('standby')"
        >
          {{ 'chat.backBtn' | translate }}
        </button>
      </div>
      <button
        mat-icon-button
        aria-label="close button"
        color="primary"
      >
        <mat-icon (click)="closeChat()">close</mat-icon>
      </button>
    </div>
    <div class="header--form" *ngIf="UImode !== 'standby'">
      <form #actionsForm="ngForm">
        <mat-form-field
          class="roomName-form"
          color="primary"
          appearance="fill"
        >
          <mat-label *ngIf="UImode === 'create'">{{
            'chat.formCreateLabel' | translate
          }}</mat-label>
          <mat-label *ngIf="UImode === 'join'">{{
            'chat.formJoinLabel' | translate
          }}</mat-label>
          <input
            [(ngModel)]="roomInput"
            class="form-input"
            matInput
            type="text"
            id="roomInput"
            name="roomInput"
          />
        </mat-form-field>
        <button
          *ngIf="UImode === 'create'"
          class="btn--form"
          mat-flat-button
          color="primary"
          (click)="createRoom(); $event.preventDefault()"
        >
          {{ 'chat.createBtn' | translate }}
        </button>
        <button
          *ngIf="UImode === 'join'"
          class="btn--form"
          mat-flat-button
          color="primary"
          (click)="joinRoom(); $event.preventDefault()"
        >
          {{ 'chat.joinBtn' | translate }}
        </button>
      </form>
      <p *ngIf="showError" class="error-msg">{{ errorMsg }}</p>
    </div>
  </header>
  <main class="room">
    <div class="select-room">
      <mat-form-field class="select-room--form" appearance="fill">
        <mat-label>Room:</mat-label>
        <mat-select
          [value]="currentRoom"
          (selectionChange)="roomWasSeleted($event.value)"
          placeholder="Select..."
        >
          <mat-option
            *ngFor="let room of userRooms"
            [value]="room.id"
          >
            {{ room.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        *ngIf="this.currentRoom"
        class="select-room--btn"
        matTooltip="Copy Room ID to Clipboard"
        mat-icon-button
        aria-label="copy to clipboard button"
        color="primary"
      >
        <ion-icon
          name="clipboard-outline"
          class="icon-lg ml-lg"
          (click)="copyChatIDToClipboard()"
        ></ion-icon>
      </button>
      <button
        *ngIf="this.currentRoom"
        class="select-room--btn"
        matTooltip="Erase chat history"
        mat-icon-button
        aria-label="del history messages"
        color="primary"
      >
        <ion-icon
          name="trash-outline"
          class="icon-lg ml-lg"
          (click)="delChatMessages()"
        ></ion-icon>
      </button>
      <button
        *ngIf="this.currentRoom"
        class="select-room--btn"
        matTooltip="Exit room"
        mat-icon-button
        aria-label="del history messages"
        color="primary"
      >
        <ion-icon
          name="exit-outline"
          class="icon-lg ml-lg"
          (click)="removeFromMyRoomsOnDB()"
        ></ion-icon>
      </button>
    </div>

    <div class="msgboard" *ngIf="currentRoom">
      <div class="msgs">
        <div
          [ngClass]="{
            msg: true,
            'msg--right':
              message.user_id === userID && !message.system,
            'msg--left':
              message.user_id !== userID && !message.system,
            'msg--center': message.system
          }"
          class="msg"
          *ngFor="let message of messages"
        >
          <p class="msg--name">
            {{
              message.user_id === userID ? 'You' : message.user_name
            }}
          </p>
          <p class="msg--content">{{ message.content }}</p>
        </div>
      </div>
    </div>
    <div class="input-area" *ngIf="currentRoom">
      <form #chatForm="ngForm">
        <mat-form-field
          class="send-form"
          color="primary"
          appearance="fill"
        >
          <input
            [(ngModel)]="inputMsg"
            (keydown.enter)="sendMessage(inputMsg)"
            class="form-input"
            matInput
            type="text"
            id="createRoom"
            name="createRoom"
          />
        </mat-form-field>
        <button
          class="btn--form"
          mat-flat-button
          color="primary"
          (click)="sendMessage(inputMsg)"
        >
          {{ 'chat.sendBtn' | translate | uppercase }}
        </button>
      </form>
    </div>
    <div class="nochat" *ngIf="!currentRoom">
      <p>
        {{ 'chat.noRoomsMsg' | translate }}
      </p>
    </div>
  </main>
</div>
