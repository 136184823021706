import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { floor10 } from '../../custom/Utils';
import { multiply, sub } from '../../custom/customMath';

import { MatSelectChange } from '@angular/material/select';

import { Currency } from 'src/app/models/Currency.model';

import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { BucketService } from 'src/app/services/bucket.service';

@Component({
  selector: 'app-sell',
  templateUrl: './sell.component.html',
  styleUrls: ['./sell.component.scss'],
})
export class SellComponent implements OnInit, AfterViewInit {
  @Output() close = new EventEmitter();
  balances: any[];
  assets: Currency[] = [];
  quotes: any;
  currentUserID: string;

  sellFormGroup: FormGroup;
  purchasedCoin = 'BTC';
  curRate: number;
  minAmount: number;
  pctFee: number;
  stepIncrement = 1;
  spendingAmount: number;
  purchaseAmount: number;
  saveDataSubscription: any;
  displayTotal: boolean = false;

  blinkBalance = false;
  floor10 = floor10;
  multiply = multiply;
  sub = sub;

  constructor(
    private authService: AuthService,
    private overlayService: OverlayService,
    private dataService: DataService,
    private bucketService: BucketService,
  ) {}

  ngOnInit() {
    this.currentUserID = this.authService.getCurrentUserID();
    this.assets = this.dataService.getAssets();
    this.quotes = this.dataService.getQuotes();
    this.balances = this.dataService.getBalances();
    this.setUpForms({ spending: this.minAmount });

    // fixed for now (SOY), no place for this data in DB as of now ...
    this.minAmount = 10;

    this.sellFormGroup.patchValue({ spending: this.minAmount });
    this.getFees().then((res) => {
      this.calcPurchaseRate(this.purchasedCoin);
      this.updateSellAmount();
    });
  }

  ngAfterViewInit() {
    // Subscribes in the data.service Subject...
    this.dataService.currentQuotesMsg.subscribe((data) => {
      this.quotes = data;
      this.calcPurchaseRate(this.purchasedCoin);
      this.updateSellAmount();
    });
  }

  // FORM STUFF
  setUpForms(defaultValue?: any) {
    this.sellFormGroup = new FormGroup({
      spending: new FormControl(''),
    });

    this.sellFormGroup.patchValue(defaultValue);
    this.onBuyChanges();
  }

  // Getters for the form fields
  get spending() {
    return this.sellFormGroup.get('spending');
  }

  onBuyChanges(): void {
    this.sellFormGroup.valueChanges.subscribe(({ spending }) => {
      this.updateSellAmount();
    });
  }

  buyCoinWasSelected(evt: MatSelectChange) {
    this.sellFormGroup.patchValue({ spending: this.minAmount });
    this.calcPurchaseRate(evt.value);
    this.getFees();
    this.updateSellAmount();
  }

  getMktSettings(source: string, target: string = 'SOY'): any {
    return new Promise((resolve, reject) => {
      this.dataService.getMarketSettings(source, target).subscribe(
        (data) => {
          resolve(data[0]);
        },
        (err) => reject(err),
      );
    });
  }

  evaluateInput(value: string) {
    const newValue = Number(value.replace(/,/g, ''));
    const balance = this.getBalance('SOY');
    if (newValue < this.minAmount) {
      this.sellFormGroup.patchValue({ spending: this.minAmount });
    } else if (newValue > balance) {
      this.sellFormGroup.patchValue({ spending: balance });
      this.blinkBalance = true;
      setTimeout(() => (this.blinkBalance = false), 1000);
    }
  }

  // OLD! It´s using consumeBucket() ..................
  async purchase() {
    this.setProgressBar('show');
    this.saveDataSubscription = (
      await this.dataService.saveBuySOY(
        this.purchasedCoin,
        this.sellFormGroup.value.spending,
      )
    ).subscribe(
      (res) => {
        console.log(res);
        this.setProgressBar('hide');
        this.showSnackBar('Data saved successfully');
        this.close.emit();
      }, // success path
      (reject) => {
        console.log(reject);
        this.setProgressBar('hide');
        for (const e of reject.error.error) {
          this.showSnackBar(e);
        }
      },
    ); // error path);
  }

  async consumeBucket() {
    this.setProgressBar('show');
    this.saveDataSubscription = (
      await this.bucketService.consumeBucket(
        this.purchasedCoin,
        'SOY',
        Number(this.sellFormGroup.value.spending),
      )
    ).subscribe(
      (res) => {
        console.log(res);
        this.setProgressBar('hide');
        this.showSnackBar('Data saved successfully');
        this.close.emit();
      }, // success path
      (reject) => {
        console.log(reject);
        this.setProgressBar('hide');
        for (const e of reject.error.error) {
          this.showSnackBar(e);
        }
      },
    ); // error path);
  }

  // METHODS FOR USER FEEDBACK
  setProgressBar(status: string) {
    const action = {
      type: 'setProgressBar',
      msg: status,
    };
    this.overlayService.setAction(action);
  }

  showSnackBar(message: string) {
    const action = {
      type: 'openSnackBar',
      msg: message,
    };
    this.overlayService.setAction(action);
  }

  updateSellAmount() {
    this.purchaseAmount = floor10(
      multiply(this.sellFormGroup.value.spending, this.curRate),
      -6,
    );
  }

  calcPurchaseRate(coin: string) {
    const rates = this.getQuote().rates;
    this.curRate = rates[coin];
    // console.log(`calcPurchaseRate|curRate: ${this.curRate}`);
  }

  getCoin(coinID: string) {
    const foundCoin = this.assets.find(
      (coin) => coin.symbol === coinID,
    );
    return foundCoin;
  }

  getQuote() {
    const quote = this.quotes.find((el) => el.base === 'SOY');
    return quote;
  }

  async getFees() {
    await this.dataService
      .getUserSpecificFees('SOY', this.currentUserID)
      .then((userFees) => {
        if (userFees && userFees.bucket_fee !== undefined) {
          this.pctFee = floor10(userFees.bucket_fee, -6);
          console.log(`user specific bucket fee: ${this.pctFee}`);
        } else {
          this.pctFee = floor10(
            this.getCoin('SOY').bucket_fee,
            -6,
          );
          console.log(
            `user specific bucket fee NOT FOUND, use standard fee: ${this.pctFee}`,
          );
        }
        this.displayTotal = true;
      });
  }

  getBalance(currencyID: string) {
    const curBalance = this.balances.find((item) => {
      return item.id === currencyID;
    });
    const balance = curBalance.free;
    return +multiply(balance, 1000) / 1000;
  }

  inputPreset(pct: number) {
    let newAmount = +multiply(this.getBalance('SOY'), pct);
    newAmount = floor10(newAmount, -6);
    this.sellFormGroup.patchValue({ spending: newAmount });
  }

  getNetBalance() {
    const curBalance = this.balances.find((item) => {
      return item.id === 'SOY';
    });
    const netBalance = curBalance.free - this.spendingAmount;
    return +floor10(netBalance, -6);
  }
}
