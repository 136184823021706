<div class="notAllowed" *ngIf="!isAllowed">
  <div class="notAllowed__main">
    <h4 class="notAllowed__title mb-md">{{ 'pay.notAllowedTitle' | translate }}</h4>
    <p class="notAllowed__text">
      {{ 'pay.notAllowedText1' | translate }}
    </p>
    <p class="notAllowed__text mb-md">
      {{ 'pay.notAllowedText2' | translate }}
    </p>
    <button
      class="notAllowed__btn mb-md"
      mat-flat-button
      color="primary"
      routerLink="/main/profile"
      mat-dialog-close
    >
    {{ 'pay.notAllowedButton' | translate }}
    </button>
  </div>
</div>

<div class="pay__main" *ngIf="isAllowed">
  <div class="pay section" *ngIf="paymentStep === 'enterCode'">
    <form class="pay__form">
      <mat-form-field
        class="pay__formfield"
        color="primary"
        appearance="fill"
      >
        <mat-label>{{
          'pay.requestCodePlaceholder' | translate
        }}</mat-label>
        <input
          [(ngModel)]="invoiceCode"
          name="invoiceCode"
          matInput
          type="text"
          required
          (keyup.enter)="getInvoiceInfo()"
        />
      </mat-form-field>
      <button
        class="pay__btn"
        mat-flat-button
        color="primary"
        (click)="getInvoiceInfo()"
        [disabled]="!invoiceCode || invoiceCode === ''"
      >
        {{ 'pay.button1' | translate }}
      </button>
    </form>
    <figure class="pay__webcam">
      <div class="pay__icon-qrcode" *ngIf="!showCam">
        <ion-icon
          *ngIf="!isMobile"
          (click)="startScan()"
          name="camera-outline"
        ></ion-icon>

        <ion-icon
          *ngIf="isMobile"
          (click)="openQrScanner()"
          name="camera-outline"
        ></ion-icon>
        <h2>QR Code</h2>
        <p>webcam scanning</p>
      </div>

      <zxing-scanner
        *ngIf="showCam && !isMobile"
        #scanner
        [(device)]="currentDevice"
        (camerasFound)="camerasFoundHandler($event)"
        (camerasNotFound)="camerasNotFoundHandler($event)"
        (scanSuccess)="scanSuccessHandler($event)"
        (scanError)="scanErrorHandler($event)"
      ></zxing-scanner>
    </figure>
  </div>

  <div class="pay__confirm" *ngIf="paymentStep === 'confirmPayment'">
    <div class="nutshell">
      <h4 class="nutshell--header mb-lg">
        {{ 'pay.confirmTitle' | translate }}
      </h4>
      <div class="nutshell--step">
        <p class="nutshell--title">
          {{ 'pay.confirmText1' | translate }}
        </p>
        <p class="nutshell--value">{{ invoicePayee }}</p>
      </div>
      <div class="nutshell--step">
        <p class="nutshell--title">
          {{ 'pay.confirmText2' | translate }}
        </p>
        <p class="nutshell--value">{{ invoiceName }}</p>
      </div>
      <div class="nutshell--step nutshell--total">
        <p class="nutshell--title">
          {{ 'pay.confirmText3' | translate }}
        </p>
        <p class="nutshell--value" class="notranslate">
          {{
            remainingAmount + pctFee * remainingAmount
              | currency: 'USD':'':'1.2-2'
          }}
          SOY
        </p>
      </div>
      <div class="nutshell--step nutshell--total">
        <p
          class="nutshell--title"
          translate
          [translateParams]="{ person: invoicePayee }"
        >
          pay.confirmText4
        </p>
        <p class="nutshell--value" class="notranslate">
          {{ remainingAmount | currency: 'USD':'':'1.2-2' }}
          SOY
        </p>
      </div>
      <div class="nutshell--step nutshell--fee">
        <p class="nutshell--title" class="notranslate">
          {{ 'pay.feeTextBegin' | translate }} {{ pctFee * 100 }} ({{
            pctFee * remainingAmount | currency: 'USD':'':'1.2-2'
          }}
          SOY) {{ 'pay.feeTextEnd' | translate }}
        </p>
      </div>
    </div>
    <div class="button-container">
      <button
        class="pay__confirm--btn"
        mat-flat-button
        color="primary"
        (click)="payInvoice()"
      >
        {{ 'pay.button2' | translate | uppercase }}
      </button>
    </div>
  </div>

  <div class="pay__confirm" *ngIf="paymentStep === 'receipt'">
    <div>
      <p class="mb-lg">{{ receiptMsg }}</p>
      <div class="icons">
        <ion-icon
          name="copy-outline"
          (click)="copyToService(receiptMsg)"
        ></ion-icon>
        <ion-icon
          name="logo-whatsapp"
          (click)="openWhatsAppLink(whatsAppLink)"
        ></ion-icon>
      </div>
      <button
        class="pay__confirm--btn"
        mat-flat-button
        color="primary"
        (click)="reset()"
      >
        New payment
      </button>
    </div>
  </div>
</div>
