import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button-loading-spinner',
  templateUrl: './button-loading-spinner.component.html',
  styleUrls: ['./button-loading-spinner.component.scss'],
})
export class ButtonLoadingSpinnerComponent implements OnInit {

  constructor() { }

  @Input() loading = false;
  @Input() buttonContainer = false
  @Input() strokeWidth = 4;
  @Input() diameter = 40;

  ngOnInit(): void {
  }

}