import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OverlayService } from 'src/app/services/overlay.service';

import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { CustomUser } from 'src/app/models/CustomUser.model';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import 'firebase/auth';
import * as Sentry from '@sentry/browser';
import { Platform } from '@ionic/angular';

import { UtilsService } from 'src/app/services/utils.service';
import { AngularFirestore } from '@angular/fire/firestore';


function getCookie(cname) {
  let name = cname + "="
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

let refUser = getCookie("refid")

declare global {
  interface Window {
    recaptchaVerifier: any;
  }
}
@Component({
  selector: 'app-phone-validate',
  templateUrl: './phone-validate.component.html',
  styleUrls: ['./phone-validate.component.scss'],
})
export class PhoneValidateComponent implements OnInit, OnDestroy {
  gRecaptchaKey: string = environment.recaptchaKey;
  loading: boolean = false;

  currentUserID: string;
  currentUserName: string;
  userData: CustomUser;
  userDataSubscription: any;
  emailConfirmed: boolean;
  phoneFormGroup: FormGroup;
  phoneAuthProvider: any;
  SMSCodeFormGroup: FormGroup;
  displayCodeForm: boolean = false;
  verificationId: string;
  phoneAuthCred: any;
  countryNames: any;
  curCountry: string;
  curCountryCode: number;

  constructor(
  private authService: AuthService,
  private afs: AngularFirestore,
  private dataService: DataService,
  private snackBar: MatSnackBar,
  private router: Router,
//  private overlayService: OverlayService,
  private utilsService: UtilsService,
  public platform: Platform,
) {}

async ngOnInit() {
  this.setUpForms();
  //this.emailConfirmed = !!this.authService.getUserEmailConfStatus();
  //  this.isLoading = true;
    this.currentUserName = this.authService.getUserDisplayName();
    this.currentUserID = this.authService.getCurrentUserID();
    this.countryNames = this.utilsService.getCountryNames();
    
    this.renderRecaptcha();

    this.userDataSubscription = this.authService.getUserFromDbAsObs(this.currentUserID).subscribe(async (userdata) => {
//        if (userdata && userdata.kyc) {
          this.userData = userdata;
//        }
//        if (!this.emailConfirmed) {
//          this.router.navigate(['/validations/email']);
//        }
    if (this.userData.referral_user=='' && refUser!='') {
      this.afs.doc('users/'+this.currentUserID).update({ referral_user: refUser })
    }
    if (this.userData.kyc.personal_info.phone_validated) {
          this.router.navigate(['/main/dashboard']);
        }
      });
  }

  ngOnDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }

//  setOverlay(status: string) {
//    const action = {
//      type: 'setProgressBar',
//      msg: status,
//    };
//    this.overlayService.setAction(action);
//  }

  setUpForms() {
    this.phoneFormGroup = new FormGroup({
      userPhoneNumber: new FormControl('', [Validators.required, Validators.minLength(6)]),
      country: new FormControl(''),
      countryCode: new FormControl('', [Validators.required]),
    });

    this.SMSCodeFormGroup = new FormGroup({
      SMSCode: new FormControl('', [Validators.required]),
    });

    this.countryHasChanged();
  }

  countryHasChanged(): void {
    this.phoneFormGroup.get('country').valueChanges.subscribe((val) => {
      this.phoneFormGroup.patchValue({
        countryCode: this.utilsService.getCountryCode(val),
      });
    });
  }

  get userPhoneNumber() {
    return this.phoneFormGroup.get('userPhoneNumber');
  }

  get country() {
    return this.phoneFormGroup.get('country');
  }

  get countryCode() {
    return this.phoneFormGroup.get('countryCode');
  }

  get SMSCode() {
    return this.SMSCodeFormGroup.get('SMSCode');
  }

  getPhoneErrorMsg() {
    return this.userPhoneNumber.hasError('required')
      ? 'This field is required.'
      : this.userPhoneNumber.hasError('minlength')
      ? `At least 6 numbers required`
      : '';
  }

  getCountryCodeErrorMsg() {
    return this.countryCode.hasError('required') ? 'Required' : '';
  }

  countryWasSelected(country: string) {
    this.curCountryCode = this.utilsService.getCountryCode(country);
  }

  renderRecaptcha() {
    const self = this;
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('send-sms-button', {
        size: 'invisible',
        theme: 'dark',
        callback: async (response) => {
          if (response.error) {
            self.loading = false;
            self.snackBar.open(response.message, null, {
              duration: 3000,
            });
          } else {
            self.startPhoneValidation();
          }
        },
        'error-callback': (response) => {
          this.loading = false;
        },
      });
    window.recaptchaVerifier.render();
  }

  async sendValidationCode() {
    this.loading = true;
    const completePhoneNum = `+${this.curCountryCode}${this.userPhoneNumber.value.replace(/[- )(]/g, '')}`;

    (await this.dataService.isPhoneNumberAlreadyRegistered(completePhoneNum)).toPromise().then(
        (_response) => {
          this.loading = false;
          window.recaptchaVerifier.verify();
        },
        (reject) => {
          for (const e of reject.error.error) {
            this.snackBar.open(e, null, {
              duration: 3000,
            });
          }
        },
      );
  }

  async submitValidationCode() {
    const self = this;
    const user = await this.authService.getUser();
    this.loading = true;
    this.phoneAuthCred = firebase.auth.PhoneAuthProvider.credential(
      this.verificationId,
      this.SMSCode.value.replace(/[- )(]/g, '')
    );
    user.updatePhoneNumber(this.phoneAuthCred).then(
      async (updateResponse) => {
        (await self.dataService.updateUserPhoneNumber()).subscribe(
          async (_response) => {
            this.loading = false;
            this.router.navigate(['/main/profile']);
          },
          (reject) => {
            this.loading = false;
            this.snackBar.open(reject.message, null, {
              duration: 3000,
            });
          }
        );
      },
      (reject) => {
        this.loading = false;
        self.snackBar.open(reject.message, null, {
          duration: 3000,
        });
      }
    );
  }


  startPhoneValidation() {
    this.loading = true;
    this.phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
    const completePhoneNum = `+${this.curCountryCode}${this.userPhoneNumber.value.replace(/[- )(]/g, '')}`;

    const phoneInfoOptions = {
      phoneNumber: completePhoneNum,
    };
    // Send SMS verification code.
    this.phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier).then(
      (verificationId) => {
        this.displayCodeForm = true;
        this.verificationId = verificationId;
        this.loading = false;
      },
      (reject) => {
        this.loading = false;
        this.snackBar.open(reject.message, null, {
          duration: 3000,
        });
      }
    ),
      (reject) => {
        // verifyPhoneNumber rejection
        this.loading = false;
        this.snackBar.open(reject.message, null, {
          duration: 3000,
        });
      };
  }
}
