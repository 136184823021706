import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { CustomUser } from '@models/CustomUser.model';

import { AuthService } from 'src/app/services/auth.service';
import { OverlayService } from 'src/app/services/overlay.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent implements OnInit {
  currentUserName: string;
  currentUserIsAdmin: boolean;
  showChat = false;
  currentUserID: string
  userData: CustomUser
  userDataSubscription: any

  constructor(
    private authService: AuthService,
    private router: Router,
    private overlayService: OverlayService,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.currentUserName = this.authService.getUserDisplayName()
    this.currentUserIsAdmin = this.authService.userIsAdmin()
    this.currentUserID = this.authService.getCurrentUserID()
    this.userDataSubscription = this.authService
      .getUserFromDbAsObs(this.currentUserID)
      .subscribe((userdata) => {
        this.userData = userdata
      });
  }

  logout() {
    this.authService.logOut().then(() => {
      // this.showSnackBar();
      this.router.navigate(['/auth/login']);
      // console.log('User has logged out from dashboard');
    });
  }

  navigateTo(page: string) {
    this.router.navigate([`/main/${page}`]);
  }

  openDialog(content: string) {
    const action = {
      type: 'openDialog',
      msg: content,
    };
    this.overlayService.setAction(action);
  }

  toggleChat() {
    this.showChat = !this.showChat;
    this.renderer.setStyle(
      document.querySelector('.main-root'),
      'overflow-y',
      'hidden',
    );
  }

  hideChat() {
    this.showChat = false;
    this.renderer.setStyle(
      document.querySelector('.main-root'),
      'overflow-y',
      'auto',
    );
  }

  showSnackBar() {
    const action = {
      type: 'openSnackBar',
      msg: 'You have logged out',
    };
    this.overlayService.setAction(action);
  }

  redirectToSoya() {
    window.open('https://soya-coin.com/faq/', '_system');
  }
}
