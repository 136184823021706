<div class="cconv">
  <form [formGroup]="form" class="form" *ngIf="quotes">
    <div class="form__area">
      <mat-form-field
        class="form__field form__select"
        appearance="fill"
      >
        <mat-select [formControl]="inputCoin">
          <mat-option
            *ngFor="let coin of quotes"
            [value]="coin.base"
            class="notranslate"
            >{{ coin.base }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field
        class="form__field form__input"
        appearance="fill"
      >
        <input
          class="text-right-align"
          matInput
          [formControl]="inputAmount"
          inputmode="decimal"
          [step]="stepIncrement"
          class="text-right-align"
        />
      </mat-form-field>
    </div>
    <div class="form__area">
      <mat-form-field
        class="form__field form__select"
        appearance="fill"
      >
        <mat-select [formControl]="outputCoin">
          <mat-option
            *ngFor="let coin of quotes"
            [value]="coin.base"
            class="notranslate"
            >{{ coin.base }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <div class="result">
        <p>{{ floor10(convertedAmount, -6) }}</p>
      </div>
      <!-- <mat-form-field class="form__field" appearance="fill">
        <input
          class="text-right-align"
          matInput
          [formControl]="outputAmount"
          inputmode="decimal"
          [step]="stepIncrement"
          class="text-right-align"
        />
      </mat-form-field> -->
    </div>
  </form>
</div>
