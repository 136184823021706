<div class="container">
  <div class="banner">
    <div class="banner__header mb-md">
      <img
        src="../../../assets/soyacoin_logo_h_green.png"
        alt="company logo"
      />
    </div>
    <div class="banner__main mb-md">
      <h1 class="banner__main--title mb-md">
        {{ 'welcome.title' | translate }}
      </h1>
      <br />
      <h6 class="banner__main--subtitle mb-md">
        {{ 'welcome.text' | translate }}
      </h6>
    </div>
    <div></div>
  </div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
  <div class="footer">
    <div class="footer-links mb-md">
      <a href="https://soya-coin.com/privacy" target="_blank">{{
        'footer.link1' | translate
      }}</a>
      <a href="https://soya-coin.com/terms" target="_blank">{{
        'footer.link2' | translate
      }}</a>
      <a href="https://soya-coin.com/contact" target="_blank">{{
        'footer.link3' | translate
      }}</a>
    </div>

    <p class="footer__company">
      <span class="footer__company--name">{{
        'footer.company' | translate
      }}</span
      >{{ 'footer.copyright' | translate }}
    </p>
  </div>
</div>
