<section class="wrapper">
  <div *ngIf="!isUploading" class="before-container">
    <div>
      <p class="text-primary instructions">
        {{ 'fileUpload.uploadInstructions' | translate }}
      </p>
    </div>
    <div>
      <label class="button-file">
        <span class="button-file__icon">
          <mat-icon aria-label="ícone upload">cloud_upload</mat-icon>
        </span>

        <p class="button-file__label">{{ 'fileUpload.uploadMainButton' | translate }}</p>
        <input
          type="file"
          style="display: none;"
          (change)="startUpload($event.target.files)"
          accept=".png,.jpg,.jpeg,.pdf"
        />
        <br/>
      </label>
    </div>
  </div>

  <div
    *ngIf="showProgressArea && !showFeedback"
    class="progress-container"
  >
    <div class="percentage">
      <mat-progress-bar
        class="percentage__bar"
        color="primary"
        mode="determinate"
        [value]="uploadPercent | async"
      ></mat-progress-bar>

      <div *ngIf="snapshot | async as snap" class="percentage__text">
        {{ snap.bytesTransferred | fileSize }} of
        {{ snap.totalBytes | fileSize }}
      </div>
    </div>

    <div class="controls" *ngIf="uploadState | async; let state">
      <button
        class="controls__btn"
        mat-stroked-button
        (click)="task.pause()"
        [disabled]="state === 'paused'"
      >
        <mat-icon aria-label="upload pause icon">pause</mat-icon>
        {{ 'fileUpload.uploadPauseButton' | translate }}
      </button>

      <button
        class="controls__btn"
        mat-stroked-button
        color="warn"
        (click)="task.cancel()"
        [disabled]="!(state === 'paused' || state === 'running')"
      >
        <mat-icon aria-label="upload stop icon">stop</mat-icon>
        {{ 'fileUpload.uploadCancelButton' | translate }}
      </button>

      <button
        class="controls__btn"
        mat-stroked-button
        color="accent"
        (click)="task.resume()"
        [disabled]="state === 'running'"
      >
        <mat-icon aria-label="resume upload icon"
          >play_arrow</mat-icon
        >
        {{ 'fileUpload.uploadResumeButton' | translate }}
      </button>
    </div>
  </div>

  <div
    *ngIf="showProgressArea && showFeedback"
    class="finished-container"
  >
    <div
      *ngIf="downloadURL | async; let downloadSrc"
      class="upload-feedback"
    >
    {{ 'fileUpload.uploadSuccess' | translate }}
    </div>
  </div>
</section>
