<!-- PAGE FOR BLOCKED USERS -->
<main
  class="blocked"
  *ngIf="userData && userData.account_status === 'BLOCKED'"
>
  <div class="blocked-content">
    <div class="blocked-intro">
      <h1>{{ 'blockedUser.greeting' | translate }}</h1>
      <h2 class="name">
        {{ userData.kyc.personal_info.full_name }}
      </h2>
      <section class="blocked-block">
        <h4>{{ 'blockedUser.title' | translate }}</h4>
        <p>
          {{ 'blockedUser.text1' | translate }}
          <span class="blocked-important"
            >{{ 'blockedUser.text2' | translate }}</span
          >
        </p>
      </section>
    </div>
  </div>
</main>

<!-- MAIN DASHBOARD FOR NON BLOCKED USERS -->
<main class="db" *ngIf="displayUI">
  <app-overview
    class="db__overview"
    [balances]="balances"
    [assets]="assets"
    [quotes]="quotes">
  </app-overview>

  <div class="db__right">

    <app-notification
      class="db__notif"
      *ngIf="showNotifications"
      (closeNotif)="closeNotification()"
      [notification]="notification">
    </app-notification>

    <!--<app-campaign class="db__campaign"></app-campaign>-->

    <div class="db__rightlow">
      <app-tutorial class="db__tutorial"></app-tutorial>
      <app-history
        class="db__history"
        [style.visibility]="isLoading ? 'hidden' : 'visible'">
      </app-history>
    
    </div>
  </div>
</main>
