import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-link-two-fa',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.scss'],
})
export class Link2FAComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Output() close = new EventEmitter();
  currentUserID: string;
  failedToGenTOTP: boolean = false;
  twoFAFormGroup: FormGroup;

  private _totpSecret: string = null;
  private _totpURL: string = null;

  get totpSecret() {
    return this._totpSecret;
  }

  get totpURL() {
    return this._totpURL;
  }

  get code() {
    return this.twoFAFormGroup.get('code');
  }

  constructor(
    private authService: AuthService,
    private overlayService: OverlayService,
    private clipboardService: ClipboardService,
  ) {}

  ngOnInit() {
    this.currentUserID = this.authService.getCurrentUserID();
    this.twoFAFormGroup = new FormGroup({
      code: new FormControl('', [Validators.required]),
    });
  }

  async ngAfterViewInit() {
    (await this.authService.getTOTPSecret()).toPromise().then(
      (response) => {
        this.failedToGenTOTP = false;
        console.log('Response', response);
        this._totpSecret = response.body.secret;
        this._totpURL = response.body.url;
      },
      (reject) => {
        this.failedToGenTOTP = true;
      },
    );
  }

  async submitCode() {
    const code = String(this.code.value);
    const secret = this.totpSecret;
    this.setProgressBar('show');
    (await this.authService.link2FA(secret, code)).toPromise().then(
      (reponse) => {
        this.setProgressBar('hide');
        this.showSnackBar('Two Factor Auth Linked!');
        this.close.emit();
      },
      (reject) => {
        this.setProgressBar('hide');
        console.log(reject);
        this.showSnackBar(reject.error.error[0]);
      },
    );
  }

  copyToService() {
    this.clipboardService.copyFromContent(this.totpSecret);
    this.showSnackBar('Copied to Clipboard!');
  }

  ngOnDestroy() {}

  // METHODS FOR USER FEEDBACK
  setProgressBar(status: string) {
    const action = {
      type: 'setProgressBar',
      msg: status,
    };
    this.overlayService.setAction(action);
  }

  showSnackBar(message: string) {
    const action = {
      type: 'openSnackBar',
      msg: message,
    };
    this.overlayService.setAction(action);
  }
}
