import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { MatSelectChange } from '@angular/material/select';

import { Currency } from 'src/app/models/Currency.model';
import { multiply, sub } from '../../custom/customMath';
import { floor10 } from '../../custom/Utils';

import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { BucketService } from 'src/app/services/bucket.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.scss'],
})
export class BuyComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Output() close = new EventEmitter();
  selectedCoin: string;
  balances: any[];
  assets: Currency[] = [];
  quotes: any;
  quotesSubscription: any;
  currentUserID: string;

  buyFormGroup: FormGroup;
  payWithCoin = 'BTC';
  curRate: number;
  minAmount = 0.000001;
  pctFee: number;
  stepIncrement = 0.001;
  spendingAmount: number;
  purchaseAmount: number;
  saveDataSubscription: any;
  displayTotal: boolean = false;

  blinkBalance = false;
  floor10 = floor10;
  multiply = multiply;
  sub = sub;

  constructor(
    private authService: AuthService,
    private overlayService: OverlayService,
    private dataService: DataService,
    private bucketService: BucketService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.currentUserID = this.authService.getCurrentUserID();
    this.selectedCoin = 'BTC';
    this.assets = this.dataService.getAssets();
    this.balances = this.dataService.getBalances();

    // For some reason, Observable here wasnt working,
    this.getQuotesAsObs();
    // So to update quotes in realtime I decided to use a Subject in data.service
    // ONLY WORKS in AfterViewInit(), see below!

    this.setUpForms({ spending: this.minAmount });

    this.getMktSettings(this.payWithCoin, 'SOY')
      .then((settings) => {
        console.log(settings);
        this.minAmount = settings.min_amount;
        this.buyFormGroup.patchValue({ spending: this.minAmount });
        this.calcBuyRate(this.payWithCoin);
        this.getFees();

        this.updatePurchaseAmount(); // show in UI, since the purchaseAmount by default is already 1
      })
      .catch((reject) => {
        console.log(reject);
        this.setProgressBar('hide');
        for (const e of reject.error.error) {
          this.showSnackBar(e);
        }
      });
  }

  ngAfterViewInit() {
    // Subscribes in the data.service Subject...
    this.dataService.currentQuotesMsg.subscribe((data) => {
      this.quotes = data;
      this.calcBuyRate(this.payWithCoin);
      this.updatePurchaseAmount();
    });
  }

  ngOnDestroy() {
    if (this.quotesSubscription) {
      this.quotesSubscription.unsubscribe();
    }
  }

  // FORM STUFF
  setUpForms(defaultValue?: any) {
    this.buyFormGroup = new FormGroup({
      spending: new FormControl('', [
        Validators.required,
        Validators.min(this.minAmount),
      ]),
    });

    this.buyFormGroup.patchValue(defaultValue);
    this.onBuyChanges();
  }

  getSpendingErrorMsg() {
    return this.spending.hasError('required')
      ? this.translate.instant('formErrors.fieldRequired')
      : this.spending.hasError('min')
      ? `${this.translate.instant('buy.minValueError')} ${this.minAmount}`
      : '';
  }

  // Getters for the form fields
  get spending() {
    return this.buyFormGroup.get('spending');
  }

  onBuyChanges(): void {
    this.buyFormGroup.valueChanges.subscribe(({ spending }) => {
      this.updatePurchaseAmount();
    });
  }

  buyCoinWasSelected(evt: MatSelectChange) {
    this.selectedCoin = evt.value;
    evt.value === 'BTC'
      ? (this.stepIncrement = 0.001)
      : (this.stepIncrement = 0.01);
    this.getMktSettings(evt.value, 'SOY')
      .then((settings) => {
        this.minAmount = settings.min_amount;
        this.buyFormGroup.patchValue({ spending: this.minAmount });
        this.getFees().then((res) => {
          this.calcBuyRate(evt.value);
          this.updatePurchaseAmount();
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getQuotesAsObs() {
    this.quotesSubscription = this.dataService
      .getQuotesAsObs()
      .subscribe((quotes) => {
        // console.log('getQuotesAsObs result:', quotes);
        this.quotes = quotes;
        this.calcBuyRate(this.selectedCoin);
        this.updatePurchaseAmount();
      });
  }

  // This is the "not observable" version, not being used
  getQuotes() {
    this.quotes = this.dataService.getQuotes();
    this.calcBuyRate(this.selectedCoin);
    this.updatePurchaseAmount();
  }

  getMktSettings(source: string, target: string = 'SOY'): any {
    return new Promise((resolve, reject) => {
      this.dataService.getMarketSettings(source, target).subscribe(
        (data) => {
          resolve(data[0]);
        },
        (err) => reject(err),
      );
    });
  }

  evaluateInput(value: string) {
    const newValue = Number(value.replace(/,/g, ''));
    const balance = this.getBalance(this.payWithCoin);
    if (newValue < this.minAmount) {
      this.buyFormGroup.patchValue({ spending: this.minAmount });
    } else if (newValue > balance) {
      this.buyFormGroup.patchValue({ spending: balance });
      this.blinkBalance = true;
      setTimeout(() => (this.blinkBalance = false), 1000);
    }
  }

  async purchase() {
    this.setProgressBar('show');
    this.saveDataSubscription = (
      await this.dataService.saveBuySOY(
        this.payWithCoin,
        this.buyFormGroup.value.spending,
      )
    ).subscribe(
      (res) => {
        console.log(res);
        this.setProgressBar('hide');
        this.showSnackBar('Data saved successfully');
        this.close.emit();
      }, // success path
      (reject) => {
        this.setProgressBar('hide');
        for (const e of reject.error.error) {
          this.showSnackBar(e);
        }
      },
    ); // error path);
  }

  async consumeBucket() {
    this.setProgressBar('show');
    this.saveDataSubscription = (
      await this.bucketService.consumeBucket(
        'SOY',
        this.payWithCoin,
        this.buyFormGroup.value.spending,
      )
    ).subscribe(
      (res) => {
        console.log(res);
        this.setProgressBar('hide');
        this.showSnackBar('Data saved successfully');
        this.close.emit();
      }, // success path
      (reject) => {
        this.setProgressBar('hide');
        for (const e of reject.error.error) {
          this.showSnackBar(e);
        }
      },
    ); // error path);
  }

  // METHODS FOR USER FEEDBACK
  setProgressBar(status: string) {
    const action = {
      type: 'setProgressBar',
      msg: status,
    };
    this.overlayService.setAction(action);
  }

  showSnackBar(message: string) {
    const action = {
      type: 'openSnackBar',
      msg: message,
    };
    this.overlayService.setAction(action);
  }

  updatePurchaseAmount() {
    this.purchaseAmount = floor10(
      multiply(this.buyFormGroup.value.spending, this.curRate),
      -6,
    );
    // console.log(`updatePurchaseAmount: ${this.purchaseAmount}`);
  }

  calcBuyRate(coinID: string) {
    if (coinID === 'SOY') {
      this.curRate = 1;
    } else {
      const coinData = this.quotes.find(
        (item) => item.base === coinID,
      );
      const rates = coinData.rates;
      this.curRate = rates.SOY;
      // console.log(`calcBuyRate|curRate: ${this.curRate}`);
    }
  }

  getCoin(coinID: string) {
    const foundCoin = this.assets.find(
      (coin) => coin.symbol === coinID,
    );
    return foundCoin;
  }

  async getFees() {
    console.log('Getting fee', this.selectedCoin);
    await this.dataService
      .getUserSpecificFees(this.selectedCoin, this.currentUserID)
      .then((userFees) => {
        if (userFees && userFees.bucket_fee !== undefined) {
          this.pctFee = floor10(userFees.bucket_fee, -6);
          console.log(`user specific bucket fee: ${this.pctFee}`);
        } else {
          this.pctFee = floor10(this.getCoin(this.selectedCoin).bucket_fee, -6);
          console.log(
            `user specific bucket fee NOT FOUND, use standard fee: ${this.pctFee}`,
          );
        }
        this.displayTotal = true;
      });
  }

  getBalance(currencyID: string) {
    const curBalance = this.balances.find((item) => {
      return item.id === currencyID;
    });
    const balance = curBalance.free;
    return +balance;
  }

  inputPreset(pct: number) {
    let newAmount = +multiply(this.getBalance(this.payWithCoin), pct);
    newAmount = floor10(newAmount, -6);
    this.buyFormGroup.patchValue({ spending: newAmount });
  }
}
