<div class="overlay-container">
  <div class="progress" *ngIf="isProcessing">
    <div class="progress__card">
      <mat-spinner
        strokeWidth="4"
        [diameter]="40"
        color="accent"
        class="progress__spinner"
      ></mat-spinner>
      <p class="progress__msg">{{ 'system.loaderTxt' | translate }}</p>
    </div>
  </div>
</div>
