import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';

import { environment } from '../../environments/environment';

import { Withdrawal } from '../models/Withdrawal.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class WithdrawalService {
  baseURL = environment.baseURL;

  constructor(
    private afs: AngularFirestore,
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  getWithdrawals(
    userID: string,
    assetID: string,
  ): Observable<Withdrawal[]> {
    const withdrawalsRef = this.afs.collection(
      `/transactions/${userID}/${assetID}/Operations/Withdrawals`,
    );
    const withdrawals = withdrawalsRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as Withdrawal;
          data.id = action.payload.doc.id;
          data.txid = data.id;
          if (
            data.created_at &&
            typeof data.created_at !== 'string'
          ) {
            data.created_at = data.created_at.toDate();
          }
          // console.log('getWithdrawals|data: ', data);
          return data;
        });
      }),
    );
    return withdrawals;
  }

  getToBeApprovedWithdrawals(
    userID: string,
    assetID: string,
  ): Observable<Withdrawal[]> {
    const withdrawalsRef = this.afs.collection(
      `/transactions/${userID}/${assetID}/Operations/Withdrawal_Requests`,
    );
    const withdrawals = withdrawalsRef.snapshotChanges().pipe(
      map((changes) => {
        return changes
          .map((action) => {
            const data = action.payload.doc.data() as Withdrawal;
            if (!data.txid || data.txid === '') {
              data.id = action.payload.doc.id;
              if (
                data.created_at &&
                typeof data.created_at !== 'string'
              ) {
                data.created_at = data.created_at.toDate();
              }
              return data;
            }
          })
          .filter((notUndefined) => notUndefined !== undefined);
      }),
    );
    return withdrawals;
  }

  getRequestedWithdrawals(assetID: string): Observable<Withdrawal[]> {
    const withdrawalsRef = this.afs.collection(
      `/manager/${assetID}/Withdrawal_Requests`,
    );
    const withdrawals = withdrawalsRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as Withdrawal;
          data.id = action.payload.doc.id;
          if (
            data.created_at &&
            typeof data.created_at !== 'string'
          ) {
            data.created_at = data.created_at.toDate();
          }
          // console.log('getToBeApprovedWithdrawals|data: ', data);
          return data;
        });
      }),
    );
    return withdrawals;
  }

  getApprovedWithdrawals(assetID: string): Observable<Withdrawal[]> {
    const withdrawalsRef = this.afs.collection(
      `/manager/${assetID}/Withdrawal_Requests`,
      (ref) => ref.where('status', '==', 'APPROVED'),
    );
    const withdrawals = withdrawalsRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as Withdrawal;
          data.id = action.payload.doc.id;
          if (
            data.created_at &&
            typeof data.created_at !== 'string'
          ) {
            data.created_at = data.created_at.toDate();
          }
          // console.log('getToBeApprovedWithdrawals|data: ', data);
          return data;
        });
      }),
    );
    return withdrawals;
  }

  getApprovedWithdrawalsAsPromise(assetID: string): Promise<any> {
    const withdrawalsRef = this.afs.collection(
      `/manager/${assetID}/Withdrawal_Requests`,
      (ref) => ref.where('status', '==', 'APPROVED'),
    );
    const withdrawals = withdrawalsRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as Withdrawal;
          data.id = action.payload.doc.id;
          if (
            data.created_at &&
            typeof data.created_at !== 'string'
          ) {
            data.created_at = data.created_at.toDate();
          }
          // console.log('getToBeApprovedWithdrawals|data: ', data);
          return data;
        });
      }),
    );
    return withdrawals.pipe(first()).toPromise();
  }

  async setWithdrawalRequestOperation(
    asset: string,
    id: string,
    operation: string,
    txID: string,
    reason: string,
  ): Promise<Observable<any>> {
    const operationURL = `${this.baseURL}/Manager/withdraws/${asset}/${id}/${operation}`;
    const operationData = {
      txid: txID,
      reject_reason: reason,
    };
    const httpOptions = await this.authService.getAuthHeaders();
    return this.http.put<any>(
      operationURL,
      JSON.stringify(operationData),
      httpOptions,
    );
  }

  async saveWithdrawViaAPI(
    assetID: string,
    wdAmount: number,
    to: string,
    two_factor_code?: number,
  ): Promise<Observable<any>> {
    const withdrawURL = `${this.baseURL}/Financial/create_withdraw`;
    const withdrawData = {
      asset: assetID,
      amount: wdAmount,
      to,
    } as any;
    if (two_factor_code) {
      withdrawData.two_factor_code = two_factor_code;
    }
    const httpOptions = await this.authService.getAuthHeaders();

    return this.http.post<any>(
      withdrawURL,
      JSON.stringify(withdrawData),
      httpOptions,
    );
  }

  async assignWithdrawalToOperator(
    asset: string,
    withdID: string,
  ): Promise<Observable<any>> {
    const operationURL = `${this.baseURL}/Manager/withdrawal/${asset}/${withdID}/operation/assign`;
    const operationData = {};
    const httpOptions = await this.authService.getAuthHeaders();
    return this.http.put<any>(
      operationURL,
      JSON.stringify(operationData),
      httpOptions,
    );
  }

  async releaseWithdrawalFromOperator(
    asset: string,
    withdID: string,
  ): Promise<Observable<any>> {
    const operationURL = `${this.baseURL}/Manager/withdrawal/${asset}/${withdID}/operation/release`;
    const operationData = {};
    const httpOptions = await this.authService.getAuthHeaders();
    return this.http.put<any>(
      operationURL,
      JSON.stringify(operationData),
      httpOptions,
    );
  }
}
